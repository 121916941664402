
// This constants file is a representation of how data for Page Layouts will be recieved by the ProHelper database once linked.

// App
import ScheduleViewData from './views/schedule/ScheduleViewData';




// Add (concact) all the individual page layouts arrays into one big array
const ViewData = [].concat(
    ScheduleViewData
);

export default ViewData;

// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';


const HomePageLayoutsData = [
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'The default view of the Home screen.',
        status: 'published',
        revisions: 1,
        route: ROUTES.HOME,
        type: ScreenTypes.APP_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.HOME,
        template: null,
        object: null,
        object_identifier: null,
        object_type: null,
        group: {
            label: 'Home',
            identifier: 'home',
            order: 1
        },
        sub_group: {
            label: 'Home Overview',
            identifier: 'home_overview',
            order: 1
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Recommended Actions',
                object_identifier: null,
                connection_type: 'user',
                type: 'recommended_actions',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_2: {
                id: "component_2",
                label: 'My Tasks',
                object_identifier: null,
                connection_type: 'user',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_3: {
                id: "component_3",
                label: 'My Jobs',
                object_identifier: null,
                connection_type: 'user',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    activities: [
                        {
                            // Visits
                            label: 'Visits',
                            identifier: 'visits_2',
                            object_identifier: 'visits',
                            display_field: 'visits.subject',
                            // show_records_container_on_first_load: {
                            //     mobile: false,
                            //     tablet: true,
                            //     desktop: true
                            // },
                            // record_cells_first_visible_amount: {
                            //     mobile: 3,
                            //     tablet: 2,
                            //     desktop: 2
                            // },
                            filters: [
                                {
                                    fields: ["visits.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "visits.start_date",
                            sort_by: ["visits.start_date"],
                            sort_method: "chronological",
                            sort_direction: "ASC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "visits.edit",
                                "visits.mark_completed",
                                "visits.mark_closed",
                                "visits.convert_to_invoice",
                                "visits.archive",
                                "visits.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'visits.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'visits.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'visits.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'visits.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'visits.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.start"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'End Date',
                                    identifier: 'visits.end_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.end"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Duration',
                                    identifier: 'visits.duration',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.duration"],
                                    display_format: null
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'visits.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Address',
                                    identifier: 'visits.address',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.address", "properties.address", "address.display"],
                                    display_format: null
                                },
                            ]
                        }
                    ]
                }
            },
            component_4: {
                id: "component_4",
                label: 'My Visits',
                object_identifier: null,
                connection_type: 'user',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_5: {
                id: "component_5",
                label: 'My Schedule',
                object_identifier: null,
                connection_type: 'user',
                type: 'schedule',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    views: ["all_visits"]
                }
            },
            component_6: {
                id: "component_6",
                label: 'Total Sales',
                object_identifier: null,
                connection_type: 'user',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_7: {
                id: "component_7",
                label: 'Projected Revenue',
                object_identifier: null,
                connection_type: 'user',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_8: {
                id: "component_8",
                label: 'New Customers',
                object_identifier: null,
                connection_type: 'user',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_9: {
                id: "component_9",
                label: 'Past-Due Balances',
                object_identifier: null,
                connection_type: 'user',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'recommended_actions_assignements',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [ { id: '1', flexSize: 1, components: ['component_1', ['component_2', 'component_3', 'component_4']] } ],
                    tablet: [ { id: '1', flexSize: 1, components: ['component_1'] }, { id: '2', flexSize: 1, components: [['component_2', 'component_3', 'component_4']] } ],
                    desktop: [ { id: '1', flexSize: 1, components: ['component_1'] }, { id: '2', flexSize: 1, components: [['component_2', 'component_3', 'component_4']] } ],
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'schedule',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: ['component_5'] }],
                    tablet: [{ id: '3', flexSize: 1, components: ['component_5'] }],
                    desktop: [{ id: '3', flexSize: 1, components: ['component_5'] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'reports',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '4', flexSize: 1, components: [["component_6", "component_7", "component_8", "component_9"]] }],
                    tablet: [{ id: '4', flexSize: 1, components: ["component_6", "component_7"] }, { id: '5', flexSize: 1, components: ["component_8", "component_9"] } ],
                    desktop: [{ id: '4', flexSize: 1, components: ["component_6"] }, { id: '5', flexSize: 1, components: ["component_7"] }, { id: '6', flexSize: 1, components: ["component_8"] }, { id: '7', flexSize: 1, components: ["component_9"] } ]
                }
            },
        ],
        item_actions: [
            "requests.new",
            "jobs.new",
            "tasks.new",
            "accounts.new",
            "contacts.new",
            "properties.new",
            "estimates.new",
            "invoices.new"
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default HomePageLayoutsData;
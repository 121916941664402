
// Import NPM Modules
import _ from 'lodash';
import uniqid from 'uniqid';
import fakerator from 'fakerator';
import parseFullName from 'parse-full-name';
import moment from 'moment-timezone';
import aes from 'crypto-js/aes';
import bigInt from 'big-integer';

// Import Custom Modules
import * as ObjectIdentifiers from '../../constants/static_data/ObjectIdentifiers';
import * as FakeSecondaryData from '../../constants/fake_data/FakeSecondaryData';
import PrecreatedLedgersData from '../../constants/precreated_data/PrecreatedLedgersData';
import PrecreatedReportsData from '../../constants/precreated_data/PrecreatedReportsData';
import PermissionsHelper from '../program/PermissionsHelper';

// Init external variables
const permissionsHelper = new PermissionsHelper();

/**
 * ~~ Class Description ~~
 * 
 */
export default class FakeDataHelper {

    /**
     * Creates an array of a specified amount of data based on the input values given.
     * 
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} ProHelperPermissions All the available ProHelper Permissions
     * @param {Object} Permissions A valid user permissions object
     * @param {Object} CustomizationsData The entire customizations redux object containing all available customizations to the user
     * 
     * @returns {Object[]} An array of records
     */
    CreateFakeDatabaseData(Auth, ProHelperPermissions, UserPermissions, CustomizationsData) {

        // Basic input validation, check is not empty
        if (
            _.isEmpty(Auth) ||
            _.isEmpty(UserPermissions) ||
            _.isEmpty(CustomizationsData)
        ) {
            return null;
        }

        // Initalize new fake database object 
        let FakeDatabase = {
            // Work
            [ObjectIdentifiers.REQUESTS]: [],
            [ObjectIdentifiers.ESTIMATES]: [],
            [ObjectIdentifiers.VISITS]: [],
            [ObjectIdentifiers.TASKS]: [],
            [ObjectIdentifiers.JOBS]: [],
            [ObjectIdentifiers.ROUTES]: [],
            // Clinets
            [ObjectIdentifiers.ACCOUNTS]: [],
            [ObjectIdentifiers.CONTACTS]: [],
            [ObjectIdentifiers.PROPERTIES]: [],
            // Team
            [ObjectIdentifiers.USERS]: [],
            [ObjectIdentifiers.VEHICLES]: [],
            // Security
            [ObjectIdentifiers.PERMISSION_SETS]: [],
            [ObjectIdentifiers.ROLES]: [],
            // Billing
            [ObjectIdentifiers.INVOICES]: [],
            [ObjectIdentifiers.PAYMENTS]: [],
            [ObjectIdentifiers.SUBSCRIPTIONS]: [],
            [ObjectIdentifiers.ITEMS]: [],
            [ObjectIdentifiers.PURCHASE_ORDERS]: [],
            // Billing
            [ObjectIdentifiers.LEDGERS]: [],
            [ObjectIdentifiers.REPORTS]: [],
            // Other
            [ObjectIdentifiers.NOTES]: [],
            [ObjectIdentifiers.ATTACHMENTS]: [],
            [ObjectIdentifiers.EVENTS]: [],
            [ObjectIdentifiers.FORM_DATA]: [],
            sync_token: 1
        };

        const USERS_RECORD_AMOUNT = 15;
        const ACCOUNTS_RECORD_AMOUNT = 500;
        const CONTACTS_RECORD_AMOUNT = 1000;
        const PROPERTIES_RECORD_AMOUNT = 1000;
        const TASKS_RECORD_AMOUNT = 500;
        const REQUESTS_RECORD_AMOUNT = 300;
        const ESTIMATES_RECORD_AMOUNT = 300;
        const INVOICES_RECORD_AMOUNT = 500;
        const PAYMENTS_RECORD_AMOUNT = 420;
        const ITEMS_RECORD_AMOUNT = 50;
        const SUBSCRIPTIONS_RECORD_AMOUNT = 300;
        const PURCHASE_ORDERS_RECORD_AMOUNT = 500;
        const JOBS_RECORD_AMOUNT = 500;
        const ROUTES_RECORD_AMOUNT = 10;
        const VEHICLES_RECORD_AMOUNT = 20;
        const VISITS_RECORD_AMOUNT = 500;
        const LEDGERS_RECORD_AMOUNT = 150;
        const REPORTS_RECORD_AMOUNT = 75;
        const NOTES_RECORD_AMOUNT = 500;


        const standard_permission_sets = [
            'admin_permission_set',
            'manager_permission_set',
            'dispatcher_permission_set',
            'field_technician_permission_set',
            'service_employee_permission_set',
            'accountant_permission_set',
            'viewer_permission_set'
        ];

        const standard_roles = [
            'admin',
            'manager',
            'dispatcher',
            'field_technician',
            'service_employee',
            'accountant',
            'viewer'
        ];

        // Create Permission Sets & Roles
        for (let i = 0; i < standard_permission_sets.length; i++) {
            const permission_set_identifier = standard_permission_sets[i];
            const role_identifier = standard_roles[i];
            // Create Permission Set data
            const newPermissionSetData = permissionsHelper.CreatePermissionSet(
                permission_set_identifier,
                Auth,
                ProHelperPermissions,
                CustomizationsData
            );
            // Create Related Role Data
            const newRoleData = permissionsHelper.CreateRole(
                role_identifier,
                Auth,
                ProHelperPermissions,
                CustomizationsData,
                newPermissionSetData
            );
            // Append the newly created Permission Set data
            FakeDatabase[ObjectIdentifiers.PERMISSION_SETS].push(newPermissionSetData);
            // Append the newly created Role data
            FakeDatabase[ObjectIdentifiers.ROLES].push(newRoleData);
        }
        // 3. Create Users
        for (let i = 0; i < USERS_RECORD_AMOUNT; i++) {
            // Create fake User data
            const newUserData = this.CreateFakeUserData(
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.ROLES],
                FakeDatabase[ObjectIdentifiers.PERMISSION_SETS],
                FakeDatabase[ObjectIdentifiers.USERS]

            );
            // Append the newly created User data
            FakeDatabase[ObjectIdentifiers.USERS].push(newUserData);
        }
        // Update Roles w/ user data
        FakeDatabase[ObjectIdentifiers.ROLES] = this._UpdateRolesData(
            FakeDatabase[ObjectIdentifiers.ROLES],
            Auth,
            CustomizationsData,
            FakeDatabase[ObjectIdentifiers.USERS]
        );

        // Update Permission Sets w/ user data
        FakeDatabase[ObjectIdentifiers.PERMISSION_SETS] = this._UpdatePermissionSetsData(
            FakeDatabase[ObjectIdentifiers.PERMISSION_SETS],
            Auth,
            CustomizationsData,
            FakeDatabase[ObjectIdentifiers.USERS]
        );

        // Update Users w/ role & permission set data
        FakeDatabase[ObjectIdentifiers.USERS] = this._UpdateUsersData(
            FakeDatabase[ObjectIdentifiers.USERS],
            Auth,
            CustomizationsData,
            FakeDatabase[ObjectIdentifiers.ROLES],
            FakeDatabase[ObjectIdentifiers.PERMISSION_SETS]
        );

        // 4. Create Contacts
        for (let i = 0; i < CONTACTS_RECORD_AMOUNT; i++) {
            // Create fake account data
            const newContactData = this.CreateFakeContactData(
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.CONTACTS],
                FakeDatabase[ObjectIdentifiers.USERS]
            );
            // Append the newly created Contact data
            FakeDatabase[ObjectIdentifiers.CONTACTS].push(newContactData);
        }

        // 5. Create Properties
        for (let i = 0; i < PROPERTIES_RECORD_AMOUNT; i++) {
            // Create fake account data
            let newPropertyData = this.CreateFakePropertyData(
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.PROPERTIES],
                FakeDatabase[ObjectIdentifiers.CONTACTS],
                FakeDatabase[ObjectIdentifiers.USERS]
            );
            // Append the newly created Property data
            FakeDatabase[ObjectIdentifiers.PROPERTIES].push(newPropertyData);
        }

        // Update Contacts w/ properties data

        // 4. Create Accounts
        for (let i = 0; i < ACCOUNTS_RECORD_AMOUNT; i++) {
            // Create fake account data
            const newAccountData = this._CreateFakeAccountData(
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.CONTACTS],
                FakeDatabase[ObjectIdentifiers.PROPERTIES]
            );
            // Append the newly created Account data
            FakeDatabase[ObjectIdentifiers.ACCOUNTS].push(newAccountData);
        }
        // Update Contacts w/ accounts data
        FakeDatabase[ObjectIdentifiers.CONTACTS] = this._UpdateContactsData(
            FakeDatabase[ObjectIdentifiers.CONTACTS],
            Auth,
            CustomizationsData,
            FakeDatabase[ObjectIdentifiers.ACCOUNTS],
            FakeDatabase[ObjectIdentifiers.PROPERTIES]
        );
        // Update Properties w/ accounts data
        FakeDatabase[ObjectIdentifiers.PROPERTIES] = this._UpdatePropertiesData(
            FakeDatabase[ObjectIdentifiers.PROPERTIES],
            Auth,
            CustomizationsData,
            FakeDatabase[ObjectIdentifiers.ACCOUNTS]
        );

        // Create Vehicles
        for (let i = 0; i < VEHICLES_RECORD_AMOUNT; i++) {
            // Create fake vehicle data
            const newVehicleData = this._CreateFakeVehicleData(
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.CONTACTS],
                FakeDatabase[ObjectIdentifiers.PROPERTIES]
            );

            // Append the newly created Vehicle data
            FakeDatabase[ObjectIdentifiers.VEHICLES].push(newVehicleData);
        }

        // Create Ledgers
        for (let i = 0; i < PrecreatedLedgersData.length; i++) {
            // Create fake Ledger data
            const newLedgerData = this._CreateFakeLedgerData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                PrecreatedLedgersData[i],
                FakeDatabase[ObjectIdentifiers.USERS]
            );

            // Append the newly created Ledger data
            FakeDatabase[ObjectIdentifiers.LEDGERS].push(newLedgerData);
        }
        // Create map for ledger (faster for later)
        const ledgerMap = {};
        for (let i = 0; i < FakeDatabase[ObjectIdentifiers.LEDGERS].length; i++) {
            ledgerMap[FakeDatabase[ObjectIdentifiers.LEDGERS][i].identifier] = FakeDatabase[ObjectIdentifiers.LEDGERS][i];
        }

        // Create Reports
        for (let i = 0; i < PrecreatedReportsData.length; i++) {
            // Create fake Report data
            const newReportData = this._CreateFakeReportData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                PrecreatedReportsData[i],
                FakeDatabase[ObjectIdentifiers.LEDGERS],
                FakeDatabase[ObjectIdentifiers.USERS]
            );

            // Append the newly created Report data
            FakeDatabase[ObjectIdentifiers.REPORTS].push(newReportData);
        }

        // Create Items
        for (let i = 0; i < ITEMS_RECORD_AMOUNT; i++) {
            // Create fake item data
            const newItemData = this._CreateFakeItemData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.ITEMS],
                ledgerMap
            );

            // Append the newly created Item data
            FakeDatabase[ObjectIdentifiers.ITEMS].push(newItemData);
        }

        // Create Requests (loop over fake requests list a few times)
        for (let h = 0; h < 5; h++) {
            for (let i = 0; i < FakeSecondaryData.requestsList.length; i++) {
                // Create fake request data
                const newRequestData = this._CreateFakeRequestData(
                    i,
                    Auth,
                    UserPermissions,
                    CustomizationsData,
                    FakeSecondaryData.requestsList[i],
                    FakeDatabase[ObjectIdentifiers.USERS],
                    FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                    FakeDatabase[ObjectIdentifiers.REQUESTS],
                    ledgerMap
                );
                // Append the newly created Request data
                FakeDatabase[ObjectIdentifiers.REQUESTS].push(newRequestData);
            }
        }

        // Create Estimates (loop over fake estimates list a few times)
        for (let h = 0; h < 5; h++) {
            for (let i = 0; i < FakeSecondaryData.estimatesList.length; i++) {
                // Create fake estimate record
                const newEstimateData = this._CreateFakeEstimateData(
                    i,
                    Auth,
                    UserPermissions,
                    CustomizationsData,
                    FakeSecondaryData.estimatesList[i],
                    FakeDatabase[ObjectIdentifiers.USERS],
                    FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                    FakeDatabase[ObjectIdentifiers.CONTACTS],
                    FakeDatabase[ObjectIdentifiers.PROPERTIES],
                    FakeDatabase[ObjectIdentifiers.REQUESTS],
                    FakeDatabase[ObjectIdentifiers.ESTIMATES],
                    FakeDatabase[ObjectIdentifiers.ITEMS],
                    ledgerMap
                );
                // Append the newly created Estimate record to fake database records list for this object
                FakeDatabase[ObjectIdentifiers.ESTIMATES].push(newEstimateData);
            }
        }

        // Create Tasks
        for (let i = 0; i < TASKS_RECORD_AMOUNT; i++) {
            // Create fake Task data
            const newTaskData = this._CreateFakeTaskData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.TASKS]
            );
            // Append the newly created Task data
            FakeDatabase[ObjectIdentifiers.TASKS].push(newTaskData);
        }

        // Create Routes
        for (let i = 0; i < ROUTES_RECORD_AMOUNT; i++) {
            // Create fake Route data
            const newRouteData = this._CreateFakeRouteData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.CONTACTS],
                FakeDatabase[ObjectIdentifiers.PROPERTIES]
            );
            // Append the newly created Route data
            FakeDatabase[ObjectIdentifiers.ROUTES].push(newRouteData);
        }

        //  Create Jobs
        for (let i = 0; i < JOBS_RECORD_AMOUNT; i++) {
            // Create fake Job data
            const newJobData = this._CreateFakeJobData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.CONTACTS],
                FakeDatabase[ObjectIdentifiers.PROPERTIES],
                FakeDatabase[ObjectIdentifiers.REQUESTS],
                FakeDatabase[ObjectIdentifiers.JOBS],
                FakeDatabase[ObjectIdentifiers.ITEMS],
                ledgerMap,
            );
            // Append the newly created Job data
            FakeDatabase[ObjectIdentifiers.JOBS].push(newJobData);
        }

        // Create Invoices
        for (let i = 0; i < INVOICES_RECORD_AMOUNT; i++) {
            // Create fake estimate data
            const newInvoiceData = this._CreateFakeInvoiceData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.REQUESTS],
                FakeDatabase[ObjectIdentifiers.ESTIMATES],
                FakeDatabase[ObjectIdentifiers.JOBS],
                FakeDatabase[ObjectIdentifiers.INVOICES],
                FakeDatabase[ObjectIdentifiers.ITEMS],
                ledgerMap
            );
            // Append the newly created Invoice data
            FakeDatabase[ObjectIdentifiers.INVOICES].push(newInvoiceData);
        }

        // Create Purchase Orders
        for (let i = 0; i < PURCHASE_ORDERS_RECORD_AMOUNT; i++) {
            // Create fake purchase order data
            const newPurchaseOrderData = this._CreateFakePurchaseOrderData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.PURCHASE_ORDERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.REQUESTS],
                FakeDatabase[ObjectIdentifiers.ESTIMATES],
                FakeDatabase[ObjectIdentifiers.INVOICES],
                FakeDatabase[ObjectIdentifiers.ITEMS],
                FakeDatabase[ObjectIdentifiers.JOBS],
                ledgerMap
            );
            // Append the newly created Purchase Order data
            FakeDatabase[ObjectIdentifiers.PURCHASE_ORDERS].push(newPurchaseOrderData);
        }
        console.log('FakeDatabase[ObjectIdentifiers.PURCHASE_ORDERS]', FakeDatabase[ObjectIdentifiers.PURCHASE_ORDERS]);

        // Create Payments
        for (let i = 0; i < PAYMENTS_RECORD_AMOUNT; i++) {
            // Create fake estimate data
            const newPaymentData = this._CreateFakePaymentData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.INVOICES],
                ledgerMap
            );

            // Append the newly created Payment data
            FakeDatabase[ObjectIdentifiers.PAYMENTS].push(newPaymentData);
        }

        // Create Subscriptions
        for (let i = 0; i < SUBSCRIPTIONS_RECORD_AMOUNT; i++) {
            // Create fake subscription data
            const newSubscription = this._CreateFakeSubscriptionData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.INVOICES],
                FakeDatabase[ObjectIdentifiers.PAYMENTS],
                FakeDatabase[ObjectIdentifiers.ITEMS],
                ledgerMap
            );

            // Append the newly created Subscription data
            FakeDatabase[ObjectIdentifiers.SUBSCRIPTIONS].push(newSubscription);
        }

        // Create Visits
        for (let i = 0; i < 150/*VISITS_RECORD_AMOUNT*/; i++) {
            // Create fake Visit data
            const newVisitData = this._CreateFakeVisitData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS],
                FakeDatabase[ObjectIdentifiers.CONTACTS],
                FakeDatabase[ObjectIdentifiers.PROPERTIES]
            );
            // Append the newly created Visit data
            FakeDatabase[ObjectIdentifiers.VISITS].push(newVisitData);
        }

        // Create Notes
        for (let i = 0; i < NOTES_RECORD_AMOUNT; i++) {
            // Create fake Notes data
            const newNoteData = this._CreateFakeNoteData(
                i,
                Auth,
                UserPermissions,
                CustomizationsData,
                FakeDatabase[ObjectIdentifiers.USERS],
                FakeDatabase[ObjectIdentifiers.ACCOUNTS]
            );
            // Append the newly created Visit data
            FakeDatabase[ObjectIdentifiers.NOTES].push(newNoteData);
        }

        console.log('FakeDatabase[ObjectIdentifiers.NOTES]', FakeDatabase[ObjectIdentifiers.NOTES]);

        return FakeDatabase;
    }

    // Team 
    /**
     * Creates a fake User based on the inputs given.
     * 
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * 
     * @returns {Object} A single randomly generated ProHelper User object given the inputs provided.
     */
    CreateFakeUserData(Auth, PermissionsData, CustomizationsData, RolesData, PermissionSetsData, UsersData) {

        // Initalize local variables
        let FakeUserObject = {};
        let Fakerator = fakerator();

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const userObjectFieldsMap = CustomizationsData.object_fields.users;
        const userTypesMap = CustomizationsData.object_types.users;
        const userTypesList = Object.values(userTypesMap);
        const userStatusesObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.status.dropdown_set_options.object_identifier][userObjectFieldsMap.status.dropdown_set].dropdowns;
        const userStatusesList = Object.values(userStatusesObjMap);
        const userDepartmentsObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.department.dropdown_set_options.object_identifier][userObjectFieldsMap.department.dropdown_set].dropdowns;
        const userDepartmentsList = Object.values(userDepartmentsObjMap);
        const userSkillsObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.skills.dropdown_set_options.object_identifier][userObjectFieldsMap.skills.dropdown_set].dropdowns;
        const userSkillsList = Object.values(userSkillsObjMap);
        const userTagsObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.tags.dropdown_set_options.object_identifier][userObjectFieldsMap.tags.dropdown_set].dropdowns;
        const userTagsList = Object.values(userTagsObjMap);
        const userOnlineStatusesObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.online_status.dropdown_set_options.object_identifier][userObjectFieldsMap.online_status.dropdown_set].dropdowns;
        const userOnlineStatusesList = Object.values(userOnlineStatusesObjMap);
        const userWorkingStatusesObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.working_status.dropdown_set_options.object_identifier][userObjectFieldsMap.working_status.dropdown_set].dropdowns;
        const userWorkingStatusesList = Object.values(userWorkingStatusesObjMap);
        const communicationMethodsObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.communication_method.dropdown_set_options.object_identifier][userObjectFieldsMap.communication_method.dropdown_set].dropdowns;
        const communicationMethodsList = Object.values(communicationMethodsObjMap);
        const payrollMethodsObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.payroll_method.dropdown_set_options.object_identifier][userObjectFieldsMap.payroll_method.dropdown_set].dropdowns;
        const payrollMethodsList = Object.values(payrollMethodsObjMap);
        const deviceTypesObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.device_type.dropdown_set_options.object_identifier][userObjectFieldsMap.device_type.dropdown_set].dropdowns;
        const deviceTypesList = Object.values(deviceTypesObjMap);
        const currenciesObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.currency.dropdown_set_options.object_identifier][userObjectFieldsMap.currency.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.language.dropdown_set_options.object_identifier][userObjectFieldsMap.language.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[userObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][userObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const userItemActionsObjMap = CustomizationsData.actions.users.item_actions;
        const userItemActionsList = Object.values(userItemActionsObjMap);

        // 
        // Initalize Variables
        //
        const fakeName = Fakerator.names.name();
        const parsedFakeName = parseFullName.parseFullName(fakeName);
        const primaryPhoneNumber = (Math.random() < 0.85) ? FakeSecondaryData.phoneList[_.random(0, (FakeSecondaryData.phoneList.length - 1))] : null;
        const primaryEmailAddress = (Math.random() < 0.75) ? FakeSecondaryData.emailList[_.random(0, (FakeSecondaryData.emailList.length - 1))] : null;
        const primaryAddress = _.cloneDeep(FakeSecondaryData.addressList[_.random(0, (FakeSecondaryData.addressList.length - 1))]);
        const name = {
            raw: fakeName,
            display: fakeName,
            legal: fakeName,
            long: fakeName,
            short: parsedFakeName.first,
            first: parsedFakeName.first,
            middle: parsedFakeName.middle,
            last: parsedFakeName.last,
            suffix: parsedFakeName.suffix,
            title: parsedFakeName.title,
            qualified: fakeName,
            is_person: true
        };
        // const fakeInternalUser = this.CreateFakeUserData(Auth, PermissionsData, CustomizationsData); accidental recurrence neverending loop

        // Set all the object fields for the contact

        // Database ID
        FakeUserObject.id = uniqid(`db_`);
        // Company ID
        FakeUserObject.company = Auth.company.id;
        // Cognito ID
        FakeUserObject.cognito_id = uniqid(`cognito_`);
        // Name
        FakeUserObject.name = name;
        // Type
        if ((Math.random() < 0.60)) {
            FakeUserObject.type = userTypesMap.employee;
        } else {
            FakeUserObject.type = (Math.random() < 0.60) ? (userTypesList[_.random(0, (userTypesList.length - 1))]) : null;
        }
        // Online Status
        if ((Math.random() < 0.70)) {
            FakeUserObject.online_status = userOnlineStatusesObjMap.online;
        } else if ((Math.random() < 0.50)) {
            FakeUserObject.online_status = userOnlineStatusesObjMap.offline;
        } else {
            FakeUserObject.online_status = (userOnlineStatusesList[_.random(0, (userOnlineStatusesList.length - 1))]);
        }
        // Profile Image
        FakeUserObject.profile_image = (Math.random() < 0.38) ? FakeSecondaryData.imageList[_.random(0, (FakeSecondaryData.imageList.length - 1))] : undefined;
        // About
        FakeUserObject.about = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Phone Number
        FakeUserObject.phone = primaryPhoneNumber;
        // Email Address
        FakeUserObject.email = primaryEmailAddress;
        // Home Address
        FakeUserObject.address = primaryAddress;
        // Department
        FakeUserObject.department = (Math.random() < 0.79) ? (userDepartmentsList[_.random(0, (userDepartmentsList.length - 1))]) : undefined;
        // Skills
        const randomNumOfFakeSkills = (_.random(0, 5));
        if (randomNumOfFakeSkills > 0 && !_.isEmpty(userSkillsList)) {
            FakeUserObject.skills = [];
            for (let i = 0; i < randomNumOfFakeSkills; i++) {
                FakeUserObject.skills.push(userSkillsList[_.random(0, (userSkillsList.length - 1))]);
            }
        }
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = userTagsList[_.random(0, (userTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeUserObject.tags = Object.values(tagsObjectMapping);
        }
        // Role
        FakeUserObject.role = !_.isEmpty(RolesData) ? RolesData[_.random(0, (RolesData.length - 1))] : null; // Need to create fake Role generator
        // Permission Sets
        FakeUserObject.permisson_sets = null; // Need to create fake Permission Sets generator
        // Owner
        FakeUserObject.manager = !_.isEmpty(UsersData) ? ((Math.random() < 0.69) ? UsersData[_.random(0, (UsersData.length - 1))] : null) : null;
        // User ID
        FakeUserObject.user_id = uniqid(`user_`);
        // Secondary Phone
        FakeUserObject.secondary_phone = (Math.random() < 0.58) ? FakeSecondaryData.phoneList[_.random(0, (FakeSecondaryData.phoneList.length - 1))] : null;
        // Secondary Email
        FakeUserObject.secondary_email = (Math.random() < 0.59) ? FakeSecondaryData.emailList[_.random(0, (FakeSecondaryData.emailList.length - 1))] : null;
        // Birthday
        FakeUserObject.birthday = (Math.random() < 0.56) ? new Date('1982-08-12') : undefined;
        // Billing Address
        FakeUserObject.billing_address = null;
        // Work Address
        FakeUserObject.work_address = null;
        // Last Working Address 
        FakeUserObject.last_working_address = null;
        // Manager Notes
        FakeUserObject.manager_notes = (Math.random() < 0.28) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Status
        FakeUserObject.status = (userStatusesList[_.random(0, (userStatusesList.length - 1))]);
        // Working Status
        FakeUserObject.working_status = (userWorkingStatusesList[_.random(0, (userWorkingStatusesList.length - 1))]);
        // Communication Method
        FakeUserObject.communication_method = (communicationMethodsList[_.random(0, (communicationMethodsList.length - 1))]);
        // Is Active
        FakeUserObject.is_active = (Math.random() < 0.92) ? true : false;
        // Is Employee
        FakeUserObject.is_employee = (Math.random() < 0.92) ? true : false;
        // Is Driver
        FakeUserObject.is_driver = (Math.random() < 0.89) ? true : false;
        // Is Archived
        FakeUserObject.is_archived = false;
        // Is Deleted
        FakeUserObject.is_deleted = false;
        // Is Draft
        FakeUserObject.is_draft = false;
        // Is Template
        FakeUserObject.is_template = false;
        // Do Not Email
        FakeUserObject.do_not_email = (Math.random() < 0.12) ? true : false;
        // Do Not Call
        FakeUserObject.do_not_call = (Math.random() < 0.14) ? true : false;
        // Do Not Mail
        FakeUserObject.do_not_mail = (Math.random() < 0.25) ? true : false;
        // Do Not Track Location
        FakeUserObject.do_not_track_location = (Math.random() < 0.35) ? true : false;
        // Is Left Handed
        FakeUserObject.is_left_handed = (Math.random() < 0.07) ? true : false;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeUserObject.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Currency
        FakeUserObject.currency = currenciesObjMap.usd;
        // Language
        FakeUserObject.language = languagesObjMap.en;
        // Payroll Method
        FakeUserObject.payroll_method = (payrollMethodsList[_.random(0, (payrollMethodsList.length - 1))]);
        // Is Billable Time
        FakeUserObject.is_time_billable = (Math.random() < 0.25) ? true : false;
        // Cost Rate
        FakeUserObject.cost_rate = (Math.random() < 0.45) ? (bigInt(_.random(0, 50) * 100).toString()) : null;
        // Is Taxable
        FakeUserObject.is_taxable = (Math.random() < 0.97) ? true : false;
        // Tax ID
        FakeUserObject.tax_id = (Math.random() < 0.69) ? aes.encrypt(FakeSecondaryData.taxIDs[_.random(0, (FakeSecondaryData.taxIDs.length - 1))], Auth.company.secret_key).toString() : null;
        // Popup Alert
        FakeUserObject.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Created At
        FakeUserObject.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeUserObject.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, (UsersData.length - 1))] : null;
        // Last Updated At
        FakeUserObject.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeUserObject.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, (UsersData.length - 1))] : null;
        // Last Activity At
        FakeUserObject.last_activity_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 24), 'days')).toDate();
        // Last Login
        FakeUserObject.last_login = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 24), 'days')).toDate();
        // Start Date
        FakeUserObject.start_date = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 5), 'months')).toDate();
        // End Date
        FakeUserObject.end_date = null;
        // Device Type
        FakeUserObject.device_type = (Math.random() < 0.21) ? (deviceTypesList[_.random(1, (deviceTypesList.length - 1))]) : '';
        // Archived Alert
        FakeUserObject.archived_alert = null;
        // Archived At
        FakeUserObject.archived_at = null;
        // Archived By
        FakeUserObject.archived_by = null;
        // Sync Token
        FakeUserObject.sync_token = 1;
        // Suggested Action
        FakeUserObject.suggested_action = (Math.random() < 0.21) ? (userItemActionsList[_.random(1, (userItemActionsList.length - 1))]) : null;
        // Metadata
        FakeUserObject.metadata = {};
        // Settings
        FakeUserObject.settings = {};
        // Custom Fields
        FakeUserObject.custom_fields = {};

        // Return User Object
        return FakeUserObject;
    }

    /**
     * Creates a fake Vehicle based on the inputs given.
     * 
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the Vehicle has
     * 
     * @returns {Object} A single randomly generated ProHelper Vehicle object given the inputs provided.
     */
    _CreateFakeVehicleData(Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, ContactsData, PropertiesData) {

        // Initalize local variables
        let FakeVehicleObject = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const vehicleObjectFieldsMap = CustomizationsData.object_fields.vehicles;
        const vehicleTypesMap = CustomizationsData.object_types.vehicles;
        const vehicleTypesList = Object.values(vehicleTypesMap);
        const vehicleStatusesObjMap = CustomizationsData.dropdown_sets[vehicleObjectFieldsMap.status.dropdown_set_options.object_identifier][vehicleObjectFieldsMap.status.dropdown_set].dropdowns;
        const vehicleStatusesList = Object.values(vehicleStatusesObjMap);
        const vehicleDepartmentsObjMap = CustomizationsData.dropdown_sets[vehicleObjectFieldsMap.department.dropdown_set_options.object_identifier][vehicleObjectFieldsMap.department.dropdown_set].dropdowns;
        const vehicleDepartmentsList = Object.values(vehicleDepartmentsObjMap);
        const vehicleToolsObjMap = CustomizationsData.dropdown_sets[vehicleObjectFieldsMap.tools.dropdown_set_options.object_identifier][vehicleObjectFieldsMap.tools.dropdown_set].dropdowns;
        const vehicleToolsList = Object.values(vehicleToolsObjMap);
        const vehicleTagsObjMap = CustomizationsData.dropdown_sets[vehicleObjectFieldsMap.tags.dropdown_set_options.object_identifier][vehicleObjectFieldsMap.tags.dropdown_set].dropdowns;
        const vehicleTagsList = Object.values(vehicleTagsObjMap);

        const vehicleItemActionsObjMap = CustomizationsData.actions.vehicles.item_actions;
        const vehicleItemActionsList = Object.values(vehicleItemActionsObjMap);

        const fakeVehicle = FakeSecondaryData.vehiclesList[_.random(0, (FakeSecondaryData.vehiclesList.length - 1))];
        // Database ID
        FakeVehicleObject.id = uniqid(`db_`);
        // Company ID
        FakeVehicleObject.company = Auth.company.id;
        // Title
        FakeVehicleObject.title = fakeVehicle.title;
        // Type
        FakeVehicleObject.type = (vehicleTypesList[_.random(0, (vehicleTypesList.length - 1))]);
        // Status
        FakeVehicleObject.status = (vehicleStatusesList[_.random(0, (vehicleStatusesList.length - 1))]);
        // Image
        FakeVehicleObject.image = (Math.random() < 0.38) ? FakeSecondaryData.imageList[_.random(0, (FakeSecondaryData.imageList.length - 1))] : undefined;
        // Notes
        FakeVehicleObject.notes = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Fleet Number
        FakeVehicleObject.fleet_number = fakeVehicle.fleet_number;
        // License Plate
        FakeVehicleObject.license_plate = fakeVehicle.license_plate;
        // Vin Number
        FakeVehicleObject.vin_number = fakeVehicle.vin_number;
        // Department
        FakeVehicleObject.department = (Math.random() < 0.79) ? (vehicleDepartmentsList[_.random(0, (vehicleDepartmentsList.length - 1))]) : undefined;
        // Tools
        const randomNumOfFakeTools = (_.random(0, 5));
        if (randomNumOfFakeTools > 0 && !_.isEmpty(vehicleToolsList)) {
            FakeVehicleObject.skills = [];
            for (let i = 0; i < randomNumOfFakeTools; i++) {
                FakeVehicleObject.skills.push(vehicleToolsList[_.random(0, (vehicleToolsList.length - 1))]);
            }
        }
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = vehicleTagsList[_.random(0, (vehicleTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeVehicleObject.tags = Object.values(tagsObjectMapping);
        }
        // Hub Address

        // Make
        FakeVehicleObject.make = fakeVehicle.make;
        // Model
        FakeVehicleObject.model = fakeVehicle.model;
        // Trim
        FakeVehicleObject.trim = fakeVehicle.trim;
        // Year
        FakeVehicleObject.year = null;
        // Color
        FakeVehicleObject.color = null;
        // Fuel Type
        FakeVehicleObject.fuel_type = null;
        // Fuel Efficiency Avg.
        FakeVehicleObject.fuel_efficiency_avg = fakeVehicle.fuel_efficiency_avg;
        // Seating Capacity
        FakeVehicleObject.seating_capacity = (Math.random() < 0.5) ? _.random(0, 4) : null;
        // Cargo Capacity
        FakeVehicleObject.cargo_capacity = (Math.random() < 0.5) ? (_.random(0, 20) * 10) : null;
        // Max Load Weight
        FakeVehicleObject.max_load_weight = (Math.random() < 0.5) ? (_.random(0, 20) * 10) : null;
        // Gross Vehicle Weight
        FakeVehicleObject.gross_vehicle_weight = (Math.random() < 0.5) ? (_.random(0, 20) * 10) : null;
        // Registration Expiry
        FakeVehicleObject.created_at = moment(new Date()).add(moment.duration(Math.floor(Math.random() * 1), 'year')).toDate();
        // Insurance Policy Number
        FakeVehicleObject.insurance_policy_number = '123'
        // Insurance Expiry
        FakeVehicleObject.insurance_expiry = moment(new Date()).add(moment.duration(Math.floor(Math.random() * 2), 'year')).toDate();
        // Insurance Provider
        FakeVehicleObject.insurance_policy_number = 'Insurance Provider'
        // Current Mileage
        FakeVehicleObject.current_mileage = fakeVehicle.current_mileage;
        // Last Maintenance Mileage
        FakeVehicleObject.last_maintenance_mileage = fakeVehicle.current_mileage !== '' ? fakeVehicle.current_mileage - 9000 : null;
        // Last Maintenance Date
        FakeVehicleObject.last_maintenance_date =  moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 1), 'year')).toDate();
        // Next Maintenance Mileage
        FakeVehicleObject.next_maintenance_mileage =  fakeVehicle.current_mileage !== '' ? fakeVehicle.current_mileage + 9000 : null;
        // Next Maintenance Date
        FakeVehicleObject.next_maintenance_date = moment(new Date()).add(moment.duration(Math.floor(Math.random() * 2), 'year')).toDate();
        // Preferred Maintenance Vendor
        FakeVehicleObject.preferred_maintenance_vendor = !_.isEmpty(AccountsData) ? ((Math.random() < 0.7) ? AccountsData[_.random(0, (AccountsData.length - 1))] : null) : null;
        // Preferred Tire Vendor
        FakeVehicleObject.preferred_tire_vendor = !_.isEmpty(AccountsData) ? ((Math.random() < 0.7) ? AccountsData[_.random(0, (AccountsData.length - 1))] : null) : null;
        // Preferred Repair Vendor
        FakeVehicleObject.preferred_repair_vendor = !_.isEmpty(AccountsData) ? ((Math.random() < 0.7) ? AccountsData[_.random(0, (AccountsData.length - 1))] : null) : null;
        // Smog Notes
        FakeVehicleObject.smog_notes = fakeVehicle.smog_notes;
        // Driver
        FakeVehicleObject.driver = !_.isEmpty(UsersData) ? ((Math.random() < 0.97) ? UsersData[_.random(0, (UsersData.length - 1))] : null) : null;
        // Purchase Date
        FakeVehicleObject.purchase_date = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Purchase Price
        FakeVehicleObject.purchase_price = null;
        // Current Value
        FakeVehicleObject.current_value = null;
        // Loan Notes
        FakeVehicleObject.loan_notes = fakeVehicle.loan_notes;
        // GPS Device ID
        FakeVehicleObject.gps_device_id = null;
        // Vehicle ID
        FakeVehicleObject.vehicle_id = uniqid(`veh_`);
        // Last Working Address
        FakeVehicleObject.last_working_address = PropertiesData[_.random(0, (PropertiesData.length - 1))].address;
        // Do Not Track Location
        FakeVehicleObject.do_not_track_location = (Math.random() < 0.20) ? true : false;
        // Popup Alert
        FakeVehicleObject.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Is Active
        FakeVehicleObject.is_active = (Math.random() < 0.92) ? true : false;
        // Is Archived
        FakeVehicleObject.is_archived = false;
        // Is Deleted
        FakeVehicleObject.is_deleted = false;
        // Is Draft
        FakeVehicleObject.is_draft = false;
        // Is Template
        FakeVehicleObject.is_template = false;
        // Created At
        FakeVehicleObject.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeVehicleObject.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, (UsersData.length - 1))] : null;
        // Last Updated At
        FakeVehicleObject.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeVehicleObject.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, (UsersData.length - 1))] : null;
        // Archived Alert
        FakeVehicleObject.archived_alert = null;
        // Archived At
        FakeVehicleObject.archived_at = null;
        // Archived By
        FakeVehicleObject.archived_by = null;
        // Sync Token
        FakeVehicleObject.sync_token = 1;
        // Suggested Action
        FakeVehicleObject.suggested_action = (Math.random() < 0.21) ? (vehicleItemActionsList[_.random(1, (vehicleItemActionsList.length - 1))]) : null;
        // Metadata
        FakeVehicleObject.metadata = {};
        // Settings
        FakeVehicleObject.settings = {};
        // Custom Fields
        FakeVehicleObject.custom_fields = {};

        // Return Vehicle Object
        return FakeVehicleObject;
    }


    // Security

    /**
     * Creates a fake Role based on the inputs given.
     * 
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * 
     * @returns {Object} A single randomly generated ProHelper Role object given the inputs provided.
     */
    CreateFakeRoleData(Auth, PermissionsData, CustomizationsData) {

        // Initalize local variables
        let FakeRoleObject = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const roleObjectFieldsMap = CustomizationsData.object_fields.roles;
        const roleTypesMap = CustomizationsData.object_types.roles;
        const roleTypesList = Object.values(roleTypesMap);
        const roleStatusesObjMap = CustomizationsData.dropdown_sets[roleObjectFieldsMap.status.dropdown_set_options.object_identifier][roleObjectFieldsMap.status.dropdown_set].dropdowns;
        const roleStatusesList = Object.values(roleStatusesObjMap);

        const roleTagsObjMap = CustomizationsData.dropdown_sets[roleObjectFieldsMap.tags.dropdown_set_options.object_identifier][roleObjectFieldsMap.tags.dropdown_set].dropdowns;
        const roleTagsList = Object.values(roleTagsObjMap);
        const roleItemActionsObjMap = CustomizationsData.actions.roles.item_actions;
        const roleItemActionsList = Object.values(roleItemActionsObjMap);


        // 
        // Initalize Variables
        //

        // Set all the object fields for the contact

        // Database ID
        FakeRoleObject.id = uniqid(`db_`);
        // Role ID
        FakeRoleObject.role_id = uniqid(`role_`);
        // Company ID
        FakeRoleObject.company = Auth.company.id;
        // Label
        FakeRoleObject.label = FakeSecondaryData.roleLabelList[_.random(0, (FakeSecondaryData.roleLabelList.length - 1))];
        // Description
        FakeRoleObject.description = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Permission Sets
        FakeRoleObject.permission_sets = null;
        // Type
        FakeRoleObject.type = (Math.random() < 0.94) ? (roleTypesList[_.random(0, (roleTypesList.length - 1))]) : undefined;
        // Status
        FakeRoleObject.status = (roleStatusesList[_.random(0, (roleStatusesList.length - 1))]);
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = roleTagsList[_.random(0, (roleTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeRoleObject.tags = Object.values(tagsObjectMapping);
        }
        // Owner
        FakeRoleObject.owner = null;
        // Is Active
        FakeRoleObject.is_active = (Math.random() < 0.92) ? true : false;
        // Is Archived
        FakeRoleObject.is_archived = false;
        // Is Deleted
        FakeRoleObject.is_deleted = false;
        // Is Draft
        FakeRoleObject.is_draft = false;
        // Is Template
        FakeRoleObject.is_template = false;
        // Is Custom
        FakeRoleObject.is_custom = false;
        // Popup Alert
        FakeRoleObject.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Created At
        FakeRoleObject.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeRoleObject.created_by = null;
        // Last Updated At
        FakeRoleObject.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeRoleObject.last_updated_by = null;
        // Archived Alert
        FakeRoleObject.archived_alert = null;
        // Archived At
        FakeRoleObject.archived_at = null;
        // Archived By
        FakeRoleObject.archived_by = null;
        // Sync Token
        FakeRoleObject.sync_token = 1;
        // Suggested Action
        FakeRoleObject.suggested_action = (Math.random() < 0.21) ? (roleItemActionsList[_.random(1, (roleItemActionsList.length - 1))]) : null;
        // Metadata
        FakeRoleObject.metadata = {};
        // Settings
        FakeRoleObject.settings = {};
        // Custom Fields
        FakeRoleObject.custom_fields = {};

        // Return Role Object
        return FakeRoleObject;
    }


    _UpdateRolesData(RolesData, Auth, Customizations, UsersData) {
        // Basic input validation, check is not empty
        if (
            _.isEmpty(Auth) ||
            _.isEmpty(Customizations) ||
            _.isEmpty(RolesData)
        ) {
            return [];
        }

        let newRoles = [];

        for (let i = 0; i < RolesData.length; i++) {
            let newRole = { ...RolesData[i] };

            newRole.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.90 ? UsersData[_.random(0, UsersData.length - 1)] : null) : null;
            newRole.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
            newRole.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
            newRole.last_viewed_by = !_.isEmpty(UsersData) ? (Math.random() < 0.90 ? UsersData[_.random(0, UsersData.length - 1)] : null) : null;

            newRoles.push(newRole)
        }

        return newRoles;
    }

    _UpdatePermissionSetsData(PermissionSetsData, Auth, Customizations, UsersData) {
        // Basic input validation, check is not empty
        if (
            _.isEmpty(Auth) ||
            _.isEmpty(Customizations) ||
            _.isEmpty(PermissionSetsData)
        ) {
            return [];
        }

        let newPermissionSets = [];

        for (let i = 0; i < PermissionSetsData.length; i++) {
            let newPermissionSet = { ...PermissionSetsData[i] };

            newPermissionSet.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.90 ? UsersData[_.random(0, UsersData.length - 1)] : null) : null;
            newPermissionSet.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
            newPermissionSet.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
            newPermissionSet.last_viewed_by = !_.isEmpty(UsersData) ? (Math.random() < 0.85 ? UsersData[_.random(0, UsersData.length - 1)] : null) : null;

            newPermissionSets.push(newPermissionSet)
        }

        return newPermissionSets;
    }

    _UpdateUsersData(UsersData, Auth, Customizations, RolesData, PermissionSetsData) {
        // Basic input validation, check is not empty
        if (
            _.isEmpty(Auth) ||
            _.isEmpty(Customizations) ||
            _.isEmpty(UsersData)
        ) {
            return [];
        }

        let newUsers = [];

        for (let i = 0; i < UsersData.length; i++) {
            let newUser = { ...UsersData[i] };

            newUser.role = !_.isEmpty(RolesData) ? (Math.random() < 0.90 ? RolesData[_.random(0, RolesData.length - 1)] : null) : null;

            newUsers.push(newUser)
        }

        return newUsers;
    }


    /**
     * Creates a fake Permission Set based on the inputs given.
     * 
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * 
     * @returns {Object} A single randomly generated ProHelper Permission Set object given the inputs provided.
     */
    _CreateFakePermissionSetData(Auth, PermissionsData, CustomizationsData) {

        // Initalize local variables
        let FakePermissionSetObject = {};

        // Customizations Object is just created so it is different than when stored in redux

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const permissionSetObjectFieldsMap = CustomizationsData.object_fields.permission_sets;
        const permissionSetTypesMap = CustomizationsData.object_types.permission_sets;
        const permissionSetTypesList = Object.values(permissionSetTypesMap);
        const permissionSetStatusesObjMap = CustomizationsData.dropdown_sets[permissionSetObjectFieldsMap.status.dropdown_set_options.object_identifier][permissionSetObjectFieldsMap.status.dropdown_set].dropdowns;
        const permissionSetStatusesList = Object.values(permissionSetStatusesObjMap);

        const permissionSetTagsObjMap = CustomizationsData.dropdown_sets[permissionSetObjectFieldsMap.tags.dropdown_set_options.object_identifier][permissionSetObjectFieldsMap.tags.dropdown_set].dropdowns;
        const permissionSetTagsList = Object.values(permissionSetTagsObjMap);
        const permissionSetItemActionsObjMap = CustomizationsData.actions.permission_sets.item_actions;
        const permissionSetItemActionsList = Object.values(permissionSetItemActionsObjMap);

        // Create a new blank permissions container object
        // let permissionsContainer = {
        //     platform_permissions: [ ],
        //     admin_permissions: [ ],
        //     user_permissions: [ ],
        //     navigation_item_permissions: [ ],
        //     object_permissions: [ ],
        //     object_type_permissions: [ ],
        //     object_field_permissions: [ ],
        //     list_view_permissions: [ ],
        //     page_layout_permissions: [ ],
        //     search_view_permissions: [ ]
        // };

        // 
        // Initalize Variables
        //

        // Set all the object fields for the contact

        // Database ID
        FakePermissionSetObject.id = uniqid(`db_`);
        // Permission Set ID
        FakePermissionSetObject.permission_set_id = uniqid(`pmset_`);
        // Company ID
        FakePermissionSetObject.company = Auth.company.id;
        // Label
        FakePermissionSetObject.label = FakeSecondaryData.roleLabelList[_.random(0, (FakeSecondaryData.roleLabelList.length - 1))];
        // Description
        FakePermissionSetObject.description = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Permissions
        FakePermissionSetObject.permissions = null;
        // Type
        FakePermissionSetObject.type = (Math.random() < 0.94) ? (permissionSetTypesList[_.random(0, (permissionSetTypesList.length - 1))]) : undefined;
        // Status
        FakePermissionSetObject.status = (permissionSetStatusesList[_.random(0, (permissionSetStatusesList.length - 1))]);
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(permissionSetTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = permissionSetTagsList[_.random(0, (permissionSetTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakePermissionSetObject.tags = Object.values(tagsObjectMapping);
        }
        // Owner
        FakePermissionSetObject.owner = null;
        // Is Active
        FakePermissionSetObject.is_active = (Math.random() < 0.92) ? true : false;
        // Is Archived
        FakePermissionSetObject.is_archived = false;
        // Is Deleted
        FakePermissionSetObject.is_deleted = false;
        // Is Draft
        FakePermissionSetObject.is_draft = false;
        // Is Template
        FakePermissionSetObject.is_template = false;
        // Is Custom
        FakePermissionSetObject.is_custom = false;
        // Popup Alert
        FakePermissionSetObject.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Created At
        FakePermissionSetObject.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakePermissionSetObject.created_by = null;
        // Last Updated At
        FakePermissionSetObject.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakePermissionSetObject.last_updated_by = null;
        // Archived Alert
        FakePermissionSetObject.archived_alert = null;
        // Archived At
        FakePermissionSetObject.archived_at = null;
        // Archived By
        FakePermissionSetObject.archived_by = null;
        // Sync Token
        FakePermissionSetObject.sync_token = 1;
        // Suggested Action
        FakePermissionSetObject.suggested_action = (Math.random() < 0.21) ? (permissionSetItemActionsList[_.random(1, (permissionSetItemActionsList.length - 1))]) : null;
        // Metadata
        FakePermissionSetObject.metadata = {};
        // Settings
        FakePermissionSetObject.settings = {};
        // Custom Fields
        FakePermissionSetObject.custom_fields = {};

        // Return Permission Set Object
        return FakePermissionSetObject;
    }

    // Client 

    /**
     * Creates a fake account based on the inputs given.
     * 
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * 
     * @returns {Object} A single randomly generated ProHelper Account object given the inputs provided.
     */
    _CreateFakeAccountData(Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, ContactsData, PropertiesData) {

        // Initalize local variables
        let FakeAccountObject = {};
        let Fakerator = fakerator();

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const objectTypesMap = CustomizationsData.object_types.accounts;
        const accountTypesList = Object.values(objectTypesMap);
        const accountObjectFieldsMap = CustomizationsData.object_fields.accounts;
        const accountStatusesObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.status.dropdown_set_options.object_identifier][accountObjectFieldsMap.status.dropdown_set].dropdowns;
        const accountStatusesList = Object.values(accountStatusesObjMap);
        const prioritiesObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.priority.dropdown_set_options.object_identifier][accountObjectFieldsMap.priority.dropdown_set].dropdowns;
        const prioritiesList = Object.values(prioritiesObjMap);
        const sourcesObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.source.dropdown_set_options.object_identifier][accountObjectFieldsMap.source.dropdown_set].dropdowns;
        const sourcesList = Object.values(sourcesObjMap);
        const ratingsObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.rating.dropdown_set_options.object_identifier][accountObjectFieldsMap.rating.dropdown_set].dropdowns;
        const ratingsList = Object.values(ratingsObjMap);
        const accountTagsObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.tags.dropdown_set_options.object_identifier][accountObjectFieldsMap.tags.dropdown_set].dropdowns;
        const accountTagsList = Object.values(accountTagsObjMap);
        const paymentStatusesObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.payment_status.dropdown_set_options.object_identifier][accountObjectFieldsMap.payment_status.dropdown_set].dropdowns;
        const paymentStatusesList = Object.values(paymentStatusesObjMap);
        const deliveryMethodsObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.delivery_method.dropdown_set_options.object_identifier][accountObjectFieldsMap.delivery_method.dropdown_set].dropdowns;
        const deliveryMethodsList = Object.values(deliveryMethodsObjMap);
        const globalCommunicationMethodsObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.communication_method.dropdown_set_options.object_identifier][accountObjectFieldsMap.communication_method.dropdown_set].dropdowns;
        const globalCommunicationMethodsList = Object.values(globalCommunicationMethodsObjMap);
        const globalZonesObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.zone.dropdown_set_options.object_identifier][accountObjectFieldsMap.zone.dropdown_set].dropdowns;
        const globalZonesList = Object.values(globalZonesObjMap);
        const accountItemActionsObjMap = CustomizationsData.actions.accounts.item_actions;
        const accountItemActionsList = Object.values(accountItemActionsObjMap);
        const currenciesObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.currency.dropdown_set_options.object_identifier][accountObjectFieldsMap.currency.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.language.dropdown_set_options.object_identifier][accountObjectFieldsMap.language.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[accountObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][accountObjectFieldsMap.time_zone.dropdown_set].dropdowns;


        // Populate known account fields with data
        const fakeName = Fakerator.names.name();
        const parsedFakeName = parseFullName.parseFullName(fakeName);
        const isPersonAccount = (Math.random() < 0.75) ? true : false;
        const companyName = FakeSecondaryData.companyList[_.random(0, (FakeSecondaryData.companyList.length - 1))];
        const accountName = isPersonAccount ? {
            raw: fakeName,
            display: fakeName,
            legal: fakeName,
            first: parsedFakeName.first,
            middle: parsedFakeName.middle,
            last: parsedFakeName.last,
            suffix: parsedFakeName.suffix,
            title: parsedFakeName.title,
            is_person: true
        } : {
            raw: companyName,
            display: companyName,
            legal: companyName,
            first: null,
            middle: null,
            last: null,
            suffix: null,
            title: null,
            is_person: false
        };


        // 
        // Set Account Variables
        //

        // ID
        FakeAccountObject.id = uniqid(`db_`);
        // Company ID
        FakeAccountObject.company = Auth.company.id;
        // Description
        FakeAccountObject.description = (Math.random() < 0.24) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Balance
        FakeAccountObject.balance = (Math.random() < 0.45) ? (bigInt(_.random(-10000, 99999)).toString()) : bigInt(0).toString();
        // Primary Contact
        FakeAccountObject.primary_contact = !_.isEmpty(ContactsData) ? ContactsData[_.random(0, ContactsData.length - 1)] : null;
        // Primary Property
        FakeAccountObject.primary_property = (Math.random() < 0.93) ? !_.isEmpty(PropertiesData) ? PropertiesData[_.random(0, PropertiesData.length - 1)] : null : null;
        // Account Type
        FakeAccountObject.type = (Math.random() < 0.87) ? (accountTypesList[_.random(0, (accountTypesList.length - 1))]) : undefined;
        // Account Status
        FakeAccountObject.status = accountStatusesList[_.random(0, (accountStatusesList.length - 1))];
        // Account Priority
        FakeAccountObject.priority = (Math.random() < 0.87) ? (prioritiesList[_.random(0, (prioritiesList.length - 1))]) : undefined;
        // Account Source
        FakeAccountObject.source = (Math.random() < 0.87) ? (sourcesList[_.random(0, (sourcesList.length - 1))]) : undefined;
        // Account Rating 
        FakeAccountObject.rating = (Math.random() < 0.87) ? (ratingsList[_.random(0, (ratingsList.length - 1))]) : undefined;
        // Account Zone
        FakeAccountObject.zone = (Math.random() < 0.87) ? (globalZonesList[_.random(0, (globalZonesList.length - 1))]) : undefined;
        // Account Tag 
        const randomNumOfFakeTags = (_.random(0, accountTagsList.length));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(accountTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = accountTagsList[_.random(0, (accountTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeAccountObject.tags = Object.values(tagsObjectMapping);
        } else {
            FakeAccountObject.tags = null;
        }
        // Owner
        FakeAccountObject.owner = (Math.random() < 0.78) ? !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Account ID
        FakeAccountObject.account_id = uniqid('acct_');
        // Account Name
        FakeAccountObject.name = !_.isEmpty(FakeAccountObject.primary_contact) ? FakeAccountObject.primary_contact.name : accountName;
        // Phone Number
        FakeAccountObject.phone = !_.isEmpty(FakeAccountObject.primary_contact) ? FakeAccountObject.primary_contact.phone : null;
        // Email Address
        FakeAccountObject.email = !_.isEmpty(FakeAccountObject.primary_contact) ? FakeAccountObject.primary_contact.email : null;
        // Address
        FakeAccountObject.address = !_.isEmpty(FakeAccountObject.primary_property) ? FakeAccountObject.primary_property.address : null;
        // Do Not Call
        FakeAccountObject.do_not_call = (Math.random() < 0.84) ? false : true;
        // Do Not Email
        FakeAccountObject.do_not_email = (Math.random() < 0.91) ? false : true;
        // Do Not Mail
        FakeAccountObject.do_not_mail = (Math.random() < 0.78) ? false : true;
        // Website
        FakeAccountObject.website = (Math.random() < 0.20) ? FakeSecondaryData.websiteList[_.random(0, (FakeSecondaryData.websiteList.length - 1))] : undefined;
        // Secondary Phone
        FakeAccountObject.secondary_phone = (Math.random() < 0.58) ? FakeSecondaryData.phoneList[_.random(0, (FakeSecondaryData.phoneList.length - 1))] : null;
        // Secondary Email
        FakeAccountObject.secondary_email = (Math.random() < 0.59) ? FakeSecondaryData.emailList[_.random(0, (FakeSecondaryData.emailList.length - 1))] : null;
        // Other Phone
        FakeAccountObject.other_phone = (Math.random() < 0.21) ? FakeSecondaryData.phoneList[_.random(0, (FakeSecondaryData.phoneList.length - 1))] : null;
        // Other Email
        FakeAccountObject.other_email = (Math.random() < 0.18) ? FakeSecondaryData.emailList[_.random(0, (FakeSecondaryData.emailList.length - 1))] : null;

        // Billing Information Details Creation Chance
        if (Math.random() < 0.84) {
            // Same billing contact & property as primary contact & property bias chance
            if (Math.random() < 0.50) {
                FakeAccountObject.billing_contact = FakeAccountObject.primary_contact;
                FakeAccountObject.billing_name = FakeAccountObject.name;
                FakeAccountObject.billing_phone = FakeAccountObject.phone;
                FakeAccountObject.billing_email = FakeAccountObject.email;
                FakeAccountObject.billing_property = FakeAccountObject.primary_property
                FakeAccountObject.billing_address = FakeAccountObject.address;
            } else {
                FakeAccountObject.billing_contact = !_.isEmpty(ContactsData) ? ContactsData[_.random(0, ContactsData.length - 1)] : null;;
                FakeAccountObject.billing_property = !_.isEmpty(PropertiesData) ? PropertiesData[_.random(0, PropertiesData.length - 1)] : null;;
                FakeAccountObject.billing_name = !_.isEmpty(FakeAccountObject.billing_contact) ? FakeAccountObject.billing_contact.name : null;
                FakeAccountObject.billing_phone = !_.isEmpty(FakeAccountObject.billing_contact) ? FakeAccountObject.billing_contact.phone : null;
                FakeAccountObject.billing_email = !_.isEmpty(FakeAccountObject.billing_contact) ? FakeAccountObject.billing_contact.email : null;
                FakeAccountObject.billing_address = !_.isEmpty(FakeAccountObject.billing_property) ? FakeAccountObject.billing_property.address : null;
            }
        }
        // Balance With Sub Accounts
        FakeAccountObject.balance_with_sub_accounts = (Math.random() < 0.12) ? _.random(parseInt(FakeAccountObject.balance), 999999) : FakeAccountObject.balance;
        // Payment Method
        FakeAccountObject.default_payment_method = null;
        // Payment Status
        FakeAccountObject.payment_status = paymentStatusesList[_.random(0, (paymentStatusesList.length - 1))];
        // Is Taxable
        FakeAccountObject.is_taxable = (Math.random() < 0.84) ? true : false;
        // Tax ID 
        FakeAccountObject.tax_id = (Math.random() < 0.69) ? aes.encrypt(FakeSecondaryData.taxIDs[_.random(0, (FakeSecondaryData.taxIDs.length - 1))], Auth.company.secret_key).toString() : null;
        // Delivery Method
        FakeAccountObject.delivery_method = (Math.random() < 0.69) ? deliveryMethodsList[_.random(0, (deliveryMethodsList.length - 1))] : null;
        // Communication Method
        FakeAccountObject.communication_method = (Math.random() < 0.75) ? globalCommunicationMethodsList[_.random(0, (globalCommunicationMethodsList.length - 1))] : null;
        // Shipping Information Details Creation Chance
        if (Math.random() < 0.74) {
            // Same shipping contact & property as primary contact & property bias chance
            if (Math.random() < 0.50) {
                FakeAccountObject.shipping_contact = FakeAccountObject.primary_contact;
                FakeAccountObject.shipping_name = FakeAccountObject.name;
                FakeAccountObject.shipping_phone = FakeAccountObject.phone;
                FakeAccountObject.shipping_email = FakeAccountObject.email;
                FakeAccountObject.shipping_property = FakeAccountObject.primary_property
                FakeAccountObject.shipping_address = FakeAccountObject.address;
            } else {
                FakeAccountObject.shipping_contact = !_.isEmpty(ContactsData) ? ContactsData[_.random(0, ContactsData.length - 1)] : null;;
                FakeAccountObject.shipping_property = !_.isEmpty(PropertiesData) ? PropertiesData[_.random(0, PropertiesData.length - 1)] : null;;
                FakeAccountObject.shipping_name = !_.isEmpty(FakeAccountObject.shipping_contact) ? FakeAccountObject.shipping_contact.name : null;
                FakeAccountObject.shipping_phone = !_.isEmpty(FakeAccountObject.shipping_contact) ? FakeAccountObject.shipping_contact.phone : null;
                FakeAccountObject.shipping_email = !_.isEmpty(FakeAccountObject.shipping_contact) ? FakeAccountObject.shipping_contact.email : null;
                FakeAccountObject.shipping_address = !_.isEmpty(FakeAccountObject.shipping_property) ? FakeAccountObject.shipping_property.address : null;
            }

        }
        // Parent
        FakeAccountObject.parent = Math.random < 0.4 ? !_.isEmpty(AccountsData) ? AccountsData[_.random(0, AccountsData.length - 1)] : null : null;
        // Level
        FakeAccountObject.level = !_.isEmpty(FakeAccountObject.parent) ? 2 : 1;
        // Is Sub Account
        FakeAccountObject.is_sub_account = !_.isEmpty(FakeAccountObject.parent);
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeAccountObject.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Currency
        FakeAccountObject.currency = currenciesObjMap.usd;
        // Language
        FakeAccountObject.language = languagesObjMap.en;
        // Account Start Date
        FakeAccountObject.start_date = (Math.random() < 0.89) ? new Date('2020-08-12') : undefined;
        // Account End Date
        FakeAccountObject.end_date = (Math.random() < 0.18) ? new Date('2021-01-04') : undefined;
        // On Hold By
        FakeAccountObject.on_hold_by = undefined;
        // Is Active
        FakeAccountObject.is_active = (Math.random() < 0.89) ? true : false;
        // Is On Hold
        FakeAccountObject.is_on_hold = (Math.random() < 0.05) ? true : false;
        // On Hold Start Date
        FakeAccountObject.on_hold_start_date = FakeAccountObject.is_on_hold ? moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 5), 'months')).toDate() : null;
        // Is Person
        FakeAccountObject.is_person = isPersonAccount;
        // Is Archived
        FakeAccountObject.is_archived = false;
        // Is Deleted
        FakeAccountObject.is_deleted = false;
        // Is Live Mode
        FakeAccountObject.is_live = true;
        // Is Draft
        FakeAccountObject.is_draft = false;
        // Is Template
        FakeAccountObject.is_template = false;
        // Action Alert
        FakeAccountObject.action_alert = (Math.random() < 0.27) ? (FakeSecondaryData.actionAlertList[_.random(1, (FakeSecondaryData.actionAlertList.length - 1))]) : null;
        // Popup Alert
        FakeAccountObject.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // On Hold Alert
        FakeAccountObject.on_hold_alert = null;
        // Created At
        FakeAccountObject.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeAccountObject.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakeAccountObject.last_activity_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 24), 'days')).toDate();
        // Last Activity By
        FakeAccountObject.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeAccountObject.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeAccountObject.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeAccountObject.last_viewed_at = new Date();
        // Last Viewed By
        FakeAccountObject.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Suggested Action
        FakeAccountObject.suggested_action = (Math.random() < 0.39) ? (accountItemActionsList[_.random(1, (accountItemActionsList.length - 1))]) : '';
        // Archived At
        FakeAccountObject.archived_at = null;
        // Archived By
        FakeAccountObject.archived_by = null;
        // Sync Token
        FakeAccountObject.sync_token = 1;
        // Metadata
        FakeAccountObject.metadata = {};
        // Settings
        FakeAccountObject.settings = {};
        // Notifications
        FakeAccountObject.notifications = {};
        // Custom Fields
        FakeAccountObject.custom_fields = {};

        // TESTING FIELDS DATA
        FakeAccountObject.favorite_number = Math.trunc(Math.random() * 10);


        // Make active status if is_active is true
        if (!_.isEmpty(accountStatusesList)) {
            FakeAccountObject.status = FakeAccountObject.is_active ? accountStatusesObjMap.active : accountStatusesObjMap.inactive;
        }

        // Return Fake Account Data Object
        return FakeAccountObject;
    }

    /**
     * Creates a number of fake contacts based on the inputs given.
     * 
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object} AccountData An optional parent Account data object
     * 
     * @returns {Object} A single randomly generated ProHelper Contact object given the inputs provided.
     */
    CreateFakeContactData(Auth, PermissionsData, CustomizationsData, ContactsData, UsersData) {

        // Initalize local variables
        let FakeContactObject = {};
        let Fakerator = fakerator();

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const contactObjectFieldsMap = CustomizationsData.object_fields.contacts;
        const contactTypesMap = CustomizationsData.object_types.contacts;
        const contactTypesList = Object.values(contactTypesMap);
        const contactStagesObjMap = CustomizationsData.dropdown_sets[contactObjectFieldsMap.stage.dropdown_set_options.object_identifier][contactObjectFieldsMap.stage.dropdown_set].dropdowns;
        const contactStagesList = Object.values(contactStagesObjMap);
        const contactPrioritiesObjMap = CustomizationsData.dropdown_sets[contactObjectFieldsMap.priority.dropdown_set_options.object_identifier][contactObjectFieldsMap.priority.dropdown_set].dropdowns;
        const contactPrioritiesList = Object.values(contactPrioritiesObjMap);
        const contactRatingsObjMap = CustomizationsData.dropdown_sets[contactObjectFieldsMap.rating.dropdown_set_options.object_identifier][contactObjectFieldsMap.rating.dropdown_set].dropdowns;
        const contactRatingsList = Object.values(contactRatingsObjMap);
        const contactSourcesObjMap = CustomizationsData.dropdown_sets[contactObjectFieldsMap.source.dropdown_set_options.object_identifier][contactObjectFieldsMap.source.dropdown_set].dropdowns;
        const contactSourcesList = Object.values(contactSourcesObjMap);
        const contactTagsObjMap = CustomizationsData.dropdown_sets[contactObjectFieldsMap.tags.dropdown_set_options.object_identifier][contactObjectFieldsMap.tags.dropdown_set].dropdowns;
        const contactTagsList = Object.values(contactTagsObjMap); const contactItemActionsObjMap = CustomizationsData.actions.contacts.item_actions;
        const contactItemActionsList = Object.values(contactItemActionsObjMap);
        const globalCommunicationMethodsObjMap = CustomizationsData.dropdown_sets[contactObjectFieldsMap.communication_method.dropdown_set_options.object_identifier][contactObjectFieldsMap.communication_method.dropdown_set].dropdowns;
        const globalCommunicationMethodsList = Object.values(globalCommunicationMethodsObjMap);
        const languagesObjMap = CustomizationsData.dropdown_sets[contactObjectFieldsMap.language.dropdown_set_options.object_identifier][contactObjectFieldsMap.language.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[contactObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][contactObjectFieldsMap.time_zone.dropdown_set].dropdowns;


        // 
        // Initalize Variables
        //
        const isCompanyContact = (Math.random() < 0.09);
        const companyName = FakeSecondaryData.companyList[_.random(0, (FakeSecondaryData.companyList.length - 1))];
        // const primaryProperty = (Math.random() < 0.42) ? this.CreateFakePropertyData(Auth, PermissionsData, CustomizationsData, UsersData): null;
        // const primaryAddress = (!_.isEmpty(primaryProperty)) ? primaryProperty.address : null;
        const fakeName = Fakerator.names.name();
        const parsedFakeName = parseFullName.parseFullName(fakeName);


        const contactName = !isCompanyContact ? {
            raw: fakeName,
            display: fakeName,
            legal: fakeName,
            first: parsedFakeName.first,
            middle: parsedFakeName.middle,
            last: parsedFakeName.last,
            suffix: parsedFakeName.suffix,
            title: parsedFakeName.title,
            is_person: true
        } : {
            raw: fakeName,
            display: companyName,
            legal: companyName,
            first: null,
            middle: null,
            last: null,
            suffix: null,
            title: null,
            is_person: false
        };

        // Set all the object fields for the contact

        // Database ID
        FakeContactObject.id = uniqid(`db_`);
        // Company
        FakeContactObject.company = Auth.company.id;
        // Name
        FakeContactObject.name = contactName;
        // Phone
        FakeContactObject.phone = (Math.random() < 0.85) ? FakeSecondaryData.phoneList[_.random(0, (FakeSecondaryData.phoneList.length - 1))] : undefined;
        // Email
        FakeContactObject.email = (Math.random() < 0.64) ? FakeSecondaryData.emailList[_.random(0, (FakeSecondaryData.emailList.length - 1))] : undefined;
        // Primary Property
        FakeContactObject.primary_property = null;
        // Address
        FakeContactObject.address = null;
        // Account
        FakeContactObject.account = null;
        // Contact ID
        FakeContactObject.contact_id = uniqid("cont_");
        // Description
        FakeContactObject.description = (Math.random() < 0.35) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Type
        FakeContactObject.type = (Math.random() < 0.94) ? (contactTypesList[_.random(0, (contactTypesList.length - 1))]) : undefined;
        // Stage
        FakeContactObject.stage = (Math.random() < 0.87) ? (contactStagesList[_.random(0, (contactStagesList.length - 1))]) : undefined;
        // Priority
        FakeContactObject.priority = (Math.random() < 0.87) ? (contactPrioritiesList[_.random(0, (contactPrioritiesList.length - 1))]) : undefined;
        // Rating
        FakeContactObject.rating = (Math.random() < 0.87) ? (contactRatingsList[_.random(0, (contactRatingsList.length - 1))]) : undefined;
        // Source
        FakeContactObject.source = (Math.random() < 0.87) ? (contactSourcesList[_.random(0, (contactSourcesList.length - 1))]) : undefined;
        // Tags
        const randomNumOfFakeTags = (_.random(0, contactTagsList.length));
        if (randomNumOfFakeTags > 0) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = contactTagsList[_.random(0, (contactTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeContactObject.tags = Object.values(tagsObjectMapping);
        }
        // Contact Image
        FakeContactObject.image = (Math.random() < 0.50) ? FakeSecondaryData.imageList[_.random(0, (FakeSecondaryData.imageList.length - 1))] : undefined;
        // Owner
        FakeContactObject.owner = (Math.random() < 0.78) ? !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Secondary Phone
        FakeContactObject.secondary_phone = (Math.random() < 0.58) ? FakeSecondaryData.phoneList[_.random(0, (FakeSecondaryData.phoneList.length - 1))] : undefined;
        // Secondary Email
        FakeContactObject.secondary_email = (Math.random() < 0.59) ? FakeSecondaryData.emailList[_.random(0, (FakeSecondaryData.emailList.length - 1))] : undefined;
        // Birthday
        FakeContactObject.birthday = (Math.random() < 0.59) ? new Date() : null;
        // Website    
        FakeContactObject.website = (Math.random() < 0.20) ? FakeSecondaryData.websiteList[_.random(0, (FakeSecondaryData.websiteList.length - 1))] : undefined;
        // Assistant
        FakeContactObject.assistant = (Math.random() < 0.18) ? !_.isEmpty(ContactsData) ? ContactsData[_.random(0, ContactsData.length - 1)] : null : null;
        // Communication Method
        FakeContactObject.communication_method = (Math.random() < 0.42) ? (globalCommunicationMethodsList[_.random(0, (globalCommunicationMethodsList.length - 1))]) : undefined;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeContactObject.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Language
        FakeContactObject.language = languagesObjMap.en;
        // Action Alert
        FakeContactObject.action_alert = (Math.random() < 0.27) ? (FakeSecondaryData.actionAlertList[_.random(1, (FakeSecondaryData.actionAlertList.length - 1))]) : null;
        // Popup Alert
        FakeContactObject.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Do Not Call
        FakeContactObject.do_not_call = (Math.random() < 0.19) ? true : false;
        // Do Not Email
        FakeContactObject.do_not_email = (Math.random() < 0.12) ? true : false;
        // Do Not Mail
        FakeContactObject.do_not_mail = (Math.random() < 0.05) ? true : false;
        // Is Active
        FakeContactObject.is_active = (Math.random() < 0.97) ? true : false;
        // Is Person
        FakeContactObject.is_person = !isCompanyContact;
        // Is Archived
        FakeContactObject.is_archived = false;
        // Is Deleted
        FakeContactObject.is_deleted = false;
        // Is Live Mode
        FakeContactObject.is_live = true;
        // Is Draft
        FakeContactObject.is_draft = false;
        // Is Template
        FakeContactObject.is_template = false;
        // Created At
        FakeContactObject.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeContactObject.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeContactObject.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeContactObject.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakeContactObject.last_activity_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 24), 'days')).toDate();
        // Last Activity By
        FakeContactObject.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeContactObject.last_viewed_at = new Date();
        // Last Viewed By
        FakeContactObject.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Suggested Action
        FakeContactObject.suggested_action = (Math.random() < 0.21) ? (contactItemActionsList[_.random(1, (contactItemActionsList.length - 1))]) : '';
        // Archived Alert
        FakeContactObject.archived_alert = null;
        // Archived At
        FakeContactObject.archived_at = null;
        // Archived By
        FakeContactObject.archived_by = null;
        // Sync Token
        FakeContactObject.sync_token = 1;
        // Metadata
        FakeContactObject.metadata = {};
        // Settings
        FakeContactObject.settings = {};
        // Custom Fields
        FakeContactObject.custom_fields = {};

        // Return Contact Object Data
        return FakeContactObject;
    }

    /**
     * Creates a number of fake properties based on the inputs given.
     * 
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * 
     * @returns {Object} A single randomly generated ProHelper Property object given the inputs provided.
     */
    CreateFakePropertyData(Auth, PermissionsData, CustomizationsData, PropertiesData, ContactsData, UsersData) {

        // Initalize local variables
        let FakePropertyObject = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const propertyObjectFieldsMap = CustomizationsData.object_fields.properties;
        const propertyTypesMap = CustomizationsData.object_types.properties;
        const propertyTypesList = Object.values(propertyTypesMap);
        const propertyConditionsObjMap = CustomizationsData.dropdown_sets[propertyObjectFieldsMap.condition.dropdown_set_options.object_identifier][propertyObjectFieldsMap.condition.dropdown_set].dropdowns;
        const propertyConditionsList = Object.values(propertyConditionsObjMap);
        const propertyPrioritiesObjMap = CustomizationsData.dropdown_sets[propertyObjectFieldsMap.priority.dropdown_set_options.object_identifier][propertyObjectFieldsMap.priority.dropdown_set].dropdowns;
        const propertyPrioritiesList = Object.values(propertyPrioritiesObjMap);
        const propertyDifficultiesObjMap = CustomizationsData.dropdown_sets[propertyObjectFieldsMap.difficulty.dropdown_set_options.object_identifier][propertyObjectFieldsMap.difficulty.dropdown_set].dropdowns;
        const propertyDifficultiesList = Object.values(propertyDifficultiesObjMap);
        const propertySourcesObjMap = CustomizationsData.dropdown_sets[propertyObjectFieldsMap.source.dropdown_set_options.object_identifier][propertyObjectFieldsMap.source.dropdown_set].dropdowns;
        const propertySourcesList = Object.values(propertySourcesObjMap);
        const propertyTagsObjMap = CustomizationsData.dropdown_sets[propertyObjectFieldsMap.tags.dropdown_set_options.object_identifier][propertyObjectFieldsMap.tags.dropdown_set].dropdowns;
        const propertyTagsList = Object.values(propertyTagsObjMap);
        const globalZonesObjMap = CustomizationsData.dropdown_sets[propertyObjectFieldsMap.zone.dropdown_set_options.object_identifier][propertyObjectFieldsMap.zone.dropdown_set].dropdowns;
        const globalZonesList = Object.values(globalZonesObjMap);
        const propertyItemActionsObjMap = CustomizationsData.actions.properties.item_actions;
        const propertyItemActionsList = Object.values(propertyItemActionsObjMap);
        const timeZonesObjMap = CustomizationsData.dropdown_sets[propertyObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][propertyObjectFieldsMap.time_zone.dropdown_set].dropdowns;


        // 
        // Initalize Variables
        //
        const primaryAddress = _.cloneDeep(FakeSecondaryData.addressList[_.random(0, (FakeSecondaryData.addressList.length - 1))]);

        // Set all the object fields for the contact

        // Database ID
        FakePropertyObject.id = uniqid(`db_`);
        // Company ID
        FakePropertyObject.company = Auth.company.id;
        // Address
        FakePropertyObject.address = primaryAddress ? primaryAddress : null;
        // Parent Account
        FakePropertyObject.account = null;
        // Type
        FakePropertyObject.type = (Math.random() < 0.94) ? (propertyTypesList[_.random(0, (propertyTypesList.length - 1))]) : undefined;
        // Primary Contact
        FakePropertyObject.primary_contact = (Math.random() < 0.83) ? !_.isEmpty(ContactsData) ? ContactsData[_.random(0, ContactsData.length - 1)] : null : null;
        // Property Images
        FakePropertyObject.images = null;
        const randomNumOfFakeImages = (_.random(0, 5));
        if (randomNumOfFakeImages > 0) {
            FakePropertyObject.images = [];
            for (let i = 0; i < randomNumOfFakeImages; i++) {
                FakePropertyObject.images.push(FakeSecondaryData.imageList[_.random(0, (FakeSecondaryData.imageList.length - 1))]);
            }
        }
        // Priority
        FakePropertyObject.priority = (Math.random() < 0.79) ? (propertyPrioritiesList[_.random(0, (propertyPrioritiesList.length - 1))]) : undefined;
        // Condition
        FakePropertyObject.condition = (Math.random() < 0.83) ? (propertyConditionsList[_.random(0, (propertyConditionsList.length - 1))]) : undefined;
        // Difficulty
        FakePropertyObject.difficulty = (Math.random() < 0.65) ? (propertyDifficultiesList[_.random(0, (propertyDifficultiesList.length - 1))]) : undefined;
        // Source
        FakePropertyObject.source = (Math.random() < 0.52) ? (propertySourcesList[_.random(0, (propertySourcesList.length - 1))]) : undefined;
        // Zone
        FakePropertyObject.zone = (Math.random() < 0.23) ? (globalZonesList[_.random(0, (globalZonesList.length - 1))]) : undefined;
        // Tags
        const randomNumOfFakeTags = (_.random(0, propertyTagsList.length));
        if (randomNumOfFakeTags > 0) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = propertyTagsList[_.random(0, (propertyTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakePropertyObject.tags = Object.values(tagsObjectMapping);
        }
        // Owner
        FakePropertyObject.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.78) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Property ID
        FakePropertyObject.property_id = uniqid("prop_");
        // Description
        FakePropertyObject.description = (Math.random() < 0.38) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Driving Notes
        FakePropertyObject.driving_notes = (Math.random() < 0.21) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Access Notes
        FakePropertyObject.access_notes = (Math.random() < 0.38) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Customer Instructions
        FakePropertyObject.customer_instructions = (Math.random() < 0.08) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : undefined;
        // Do Not Mail
        FakePropertyObject.do_not_mail = (Math.random() < 0.14) ? true : false;
        // Do Not Visit
        FakePropertyObject.do_not_visit = (Math.random() < 0.09) ? true : false;
        // Action Alert
        FakePropertyObject.action_alert = (Math.random() < 0.27) ? (FakeSecondaryData.actionAlertList[_.random(1, (FakeSecondaryData.actionAlertList.length - 1))]) : null;
        // Popup Alert
        FakePropertyObject.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakePropertyObject.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Is Active
        FakePropertyObject.is_active = (Math.random() < 0.92) ? true : false;
        // Is Archived
        FakePropertyObject.is_archived = false;
        // Is Deleted
        FakePropertyObject.is_deleted = false;
        // Is Draft
        FakePropertyObject.is_draft = false;
        // Is Template
        FakePropertyObject.is_template = false;
        // Parent Property
        FakePropertyObject.parent = Math.random < 0.2 ? !_.isEmpty(PropertiesData) ? PropertiesData[_.random(0, PropertiesData.length - 1)] : null : null;
        // Is Sub Property
        FakePropertyObject.is_sub_property = !_.isEmpty(FakePropertyObject.parent);
        // Level
        FakePropertyObject.level = !_.isEmpty(FakePropertyObject.parent) ? 2 : 1;
        // Created At
        FakePropertyObject.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakePropertyObject.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakePropertyObject.last_activity_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 24), 'days')).toDate();
        // Last Activity By
        FakePropertyObject.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakePropertyObject.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakePropertyObject.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakePropertyObject.last_viewed_at = new Date();
        // Last Viewed By
        FakePropertyObject.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Archived Alert
        FakePropertyObject.archived_alert = null;
        // Archived At
        FakePropertyObject.archived_at = null;
        // Archived By
        FakePropertyObject.archived_by = null;
        // Sync Token
        FakePropertyObject.sync_token = 1;
        // Suggested Action
        FakePropertyObject.suggested_action = (Math.random() < 0.21) ? (propertyItemActionsList[_.random(1, (propertyItemActionsList.length - 1))]) : '';
        // Metadata
        FakePropertyObject.metadata = {};
        // Settings
        FakePropertyObject.settings = {};
        // Custom Fields
        FakePropertyObject.custom_fields = {};

        // Return Property Object
        return FakePropertyObject;
    }

    _UpdateContactsData(ContactsData, Auth, Customizations, AccountsData, PropertiesData) {
        // Basic input validation, check is not empty
        if (
            _.isEmpty(Auth) ||
            _.isEmpty(Customizations) ||
            _.isEmpty(ContactsData)
        ) {
            return [];
        }

        let newContacts = [];

        for (let i = 0; i < ContactsData.length; i++) {
            let newContact = { ...ContactsData[i] };

            newContact.account = !_.isEmpty(AccountsData) && (Math.random() < 0.98 ? AccountsData[_.random(0, AccountsData.length - 1)] : null);
            newContact.property = !_.isEmpty(newContact.account) && (Math.random() < 0.9 ? newContact.account.primary_property : null);
            newContact.address = !_.isEmpty(newContact.property) ? newContact.property.address : null;

            newContacts.push(newContact)
        }

        return newContacts;
    }

    _UpdatePropertiesData(PropertiesData, Auth, Customizations, AccountsData) {
        // Basic input validation, check is not empty
        if (
            _.isEmpty(Auth) ||
            _.isEmpty(Customizations) ||
            _.isEmpty(PropertiesData)
        ) {
            return [];
        }

        let newProperties = [];

        for (let i = 0; i < PropertiesData.length; i++) {
            let newProperty = { ...PropertiesData[i] };

            newProperty.account = !_.isEmpty(AccountsData) && (Math.random() < 0.97 ? AccountsData[_.random(0, AccountsData.length - 1)] : null);

            newProperties.push(newProperty)
        }

        return newProperties;
    }


    /**
     * Creates a fake Visit based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object[]} UsersData The array of generated User records
     * @param {Object[]} AccountsData The array of generated Account records
     * 
     * @returns {Object} A single randomly generated ProHelper Visit object given the inputs provided.
     */
    _CreateFakeVisitData(Index, Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, ContactsData, PropertiesData) {
        // Initalize local variables
        let FakeVisitData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const visitObjectFieldsMap = CustomizationsData.object_fields.visits;
        const visitTypesMap = CustomizationsData.object_types.visits;
        const visitTypesList = Object.values(visitTypesMap);
        const visitStatusesObjMap = CustomizationsData.dropdown_sets[visitObjectFieldsMap.status.dropdown_set_options.object_identifier][visitObjectFieldsMap.status.dropdown_set].dropdowns;
        const visitStatusesList = Object.values(visitStatusesObjMap);
        const zonesObjMap = CustomizationsData.dropdown_sets[visitObjectFieldsMap.zone.dropdown_set_options.object_identifier][visitObjectFieldsMap.zone.dropdown_set].dropdowns;
        const zonesList = Object.values(zonesObjMap);
        const visitTagsObjMap = CustomizationsData.dropdown_sets[visitObjectFieldsMap.tags.dropdown_set_options.object_identifier][visitObjectFieldsMap.tags.dropdown_set].dropdowns;
        const visitTagsList = Object.values(visitTagsObjMap);
        const visitVisiblitiesObjMap = CustomizationsData.dropdown_sets[visitObjectFieldsMap.visibility.dropdown_set_options.object_identifier][visitObjectFieldsMap.visibility.dropdown_set].dropdowns;
        const visitVisiblitiesList = Object.values(visitVisiblitiesObjMap);
        const visitShowAsObjMap = CustomizationsData.dropdown_sets[visitObjectFieldsMap.show_as.dropdown_set_options.object_identifier][visitObjectFieldsMap.show_as.dropdown_set].dropdowns;
        const visitShowAsList = Object.values(visitShowAsObjMap);
        const visitItemActionsObjMap = CustomizationsData.actions.visits.item_actions;
        const visitItemActionsList = Object.values(visitItemActionsObjMap);
        const timeZonesObjMap = CustomizationsData.dropdown_sets[visitObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][visitObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[visitObjectFieldsMap.language.dropdown_set_options.object_identifier][visitObjectFieldsMap.language.dropdown_set].dropdowns;

        const fakeScheduleInfo = FakeSecondaryData.getFakeEventScheduleInfo();

        // Database ID
        FakeVisitData.id = uniqid(`db_`);
        // Company ID
        FakeVisitData.company = Auth.company.id;
        // Subject
        FakeVisitData.subject = FakeSecondaryData.eventLabelList[_.random(0, (FakeSecondaryData.eventLabelList.length - 1))];
        // Description
        FakeVisitData.description = (Math.random() < 0.65) ? FakeSecondaryData.scheduleDescriptionList[_.random(0, (FakeSecondaryData.scheduleDescriptionList.length - 1))] : null;
        // Type
        FakeVisitData.type = (Math.random() < 0.94) ? (visitTypesList[_.random(0, (visitTypesList.length - 1))]) : null;
        // Status
        FakeVisitData.status = visitStatusesList[_.random(0, (visitStatusesList.length - 1))];
        // Start
        FakeVisitData.start_date = (Math.random() < 0.95) ? fakeScheduleInfo.start : "";
        // End
        FakeVisitData.end_date = (Math.random() < 0.95) && FakeVisitData.start_date !== null ? fakeScheduleInfo.end : "";
        // Duration
        FakeVisitData.duration = fakeScheduleInfo.duration;
        // Is All Day
        FakeVisitData.is_all_day = fakeScheduleInfo.is_all_day;
        // Is Any Time
        FakeVisitData.is_any_time = fakeScheduleInfo.is_any_time;
        // Custom Position
        FakeVisitData.custom_position = Index;
        // Visibility
        FakeVisitData.visibility = !_.isEmpty(visitVisiblitiesList) ? visitVisiblitiesList[_.random(0, (visitVisiblitiesList.length - 1))] : null;
        // Show As
        FakeVisitData.show_as = !_.isEmpty(visitShowAsList) ? visitShowAsList[_.random(0, (visitShowAsList.length - 1))] : null;
        // Related To

        // Account
        FakeVisitData.account = !_.isEmpty(AccountsData) ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Contacts
        FakeVisitData.contact = !_.isEmpty(AccountsData) ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Property

        // Address

        // Zone
        FakeVisitData.zone = !_.isEmpty(zonesList) ? (Math.random() < 0.84) ? zonesList[_.random(0, (zonesList.length - 1))] : null : null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(visitTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = visitTagsList[_.random(0, (visitTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeVisitData.tags = Object.values(tagsObjectMapping);
        }
        // Owner
        FakeVisitData.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.78) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Event ID
        FakeVisitData.visit_id = uniqid(`visit_`);
        // Is Active
        FakeVisitData.is_active = (Math.random() < 0.5) ? false : true;
        // Popup Alert
        FakeVisitData.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeVisitData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Language
        FakeVisitData.language = languagesObjMap.en;
        // Created At
        FakeVisitData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeVisitData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeVisitData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeVisitData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakeVisitData.last_activity_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Activity By
        FakeVisitData.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeVisitData.last_viewed_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Viewed By
        FakeVisitData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Is Archived
        FakeVisitData.is_archived = false;
        // Is Deleted
        FakeVisitData.is_deleted = false;
        // Is Live
        FakeVisitData.is_live = true;
        // Is Draft
        FakeVisitData.is_draft = false;
        // Is Template
        FakeVisitData.is_template = false;
        // Is On Hold
        FakeVisitData.is_on_hold = false;
        // Is complete
        FakeVisitData.is_completed = (Math.random() < 0.5) ? false : true;
        // On Hold Alert
        FakeVisitData.on_hold_alert = null;
        // Archived Alert
        FakeVisitData.archived_alert = null;
        // Archived At
        FakeVisitData.archived_at = null;
        // Archived By
        FakeVisitData.archived_by = null;
        // Sync Token
        FakeVisitData.sync_token = 1;
        // Suggested Action
        FakeVisitData.suggested_action = (Math.random() < 0.26) ? (visitItemActionsList[_.random(1, (visitItemActionsList.length - 1))]) : null;
        // Notifications
        FakeVisitData.notifications = {};
        // Metadata
        FakeVisitData.metadata = {};
        // Settings
        FakeVisitData.settings = {};
        // Custom Fields
        FakeVisitData.custom_fields = {};

        // Assigned To
        const randomNumOfUsers = _.random(0, 3);
        if (randomNumOfUsers > 0 && !_.isEmpty(UsersData)) {
            // Shuffle UsersData array
            const shuffledUsers = _.shuffle(UsersData);
            // Pick the first randomNumOfUsers users from the shuffled array
            const usersList = shuffledUsers.slice(0, randomNumOfUsers);
            FakeVisitData.assigned_to = usersList;
        }

        return FakeVisitData;
    }

    // Work

    /**
     * Creates a fake Request based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object} FakeRequestData A fake created request object with subject, description, instruction, client_instructions, and type
     * @param {Object[]} UsersData The array of generated user records
     * @param {Object[]} AccountsData The array of generated account records
     * @param {Object[]} RequestsData The array of generated request records
     * @param {Object} LedgersMap The object mapping of identifier to object of generated ledger records
     * 
     * @returns {Object} A single randomly generated ProHelper Request object given the inputs provided.
     */
    _CreateFakeRequestData(Index, Auth, PermissionsData, CustomizationsData, FakeRequestData, UsersData, AccountsData, RequestsData, LedgersMap) {

        // Initalize local variables
        let FakeRequestObject = {};
        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const requestObjectFieldsMap = CustomizationsData.object_fields.requests;
        const requestTypesMap = CustomizationsData.object_types.requests;
        const requestStatusesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.status.dropdown_set_options.object_identifier][requestObjectFieldsMap.status.dropdown_set].dropdowns;
        const requestStatusesList = Object.values(requestStatusesObjMap);
        const prioritiesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.priority.dropdown_set_options.object_identifier][requestObjectFieldsMap.priority.dropdown_set].dropdowns;
        const prioritiesList = Object.values(prioritiesObjMap);
        const sourcesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.source.dropdown_set_options.object_identifier][requestObjectFieldsMap.source.dropdown_set].dropdowns;
        const sourcesList = Object.values(sourcesObjMap);
        const requestTagsObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.tags.dropdown_set_options.object_identifier][requestObjectFieldsMap.tags.dropdown_set].dropdowns;
        const requestTagsList = Object.values(requestTagsObjMap);
        const globalZonesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.zone.dropdown_set_options.object_identifier][requestObjectFieldsMap.zone.dropdown_set].dropdowns;
        const globalZonesList = Object.values(globalZonesObjMap);
        const assignmentStatusesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.assignment_status.dropdown_set_options.object_identifier][requestObjectFieldsMap.assignment_status.dropdown_set].dropdowns;
        const assignmentStatusesList = Object.values(assignmentStatusesObjMap);
        const scheduleStatusesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.schedule_status.dropdown_set_options.object_identifier][requestObjectFieldsMap.schedule_status.dropdown_set].dropdowns;
        const scheduleStatusesList = Object.values(scheduleStatusesObjMap);
        const requestItemActionsObjMap = CustomizationsData.actions.requests.item_actions;
        const requestItemActionsList = Object.values(requestItemActionsObjMap);
        const currenciesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.currency.dropdown_set_options.object_identifier][requestObjectFieldsMap.currency.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.language.dropdown_set_options.object_identifier][requestObjectFieldsMap.language.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[requestObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][requestObjectFieldsMap.time_zone.dropdown_set].dropdowns;


        // 
        // Initalize Variables
        //

        // Set all the object fields for the contact

        // Database ID
        FakeRequestObject.id = uniqid(`db_`);
        // Company ID
        FakeRequestObject.company = Auth.company.id;
        // Label
        FakeRequestObject.subject = FakeRequestData.subject;
        // Description
        FakeRequestObject.description = FakeRequestData.description;
        // Type
        FakeRequestObject.type = (Math.random() < 0.96) ? ((_.isString(FakeRequestData.type)) ? requestTypesMap[FakeRequestData.type] : null) : null;
        // Status
        FakeRequestObject.status = requestStatusesList[_.random(0, (requestStatusesList.length - 1))];
        // Account
        FakeRequestObject.account = !_.isEmpty(AccountsData) ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Primary Contact
        FakeRequestObject.primary_contact = !_.isEmpty(FakeRequestObject.account) ? FakeRequestObject.account.primary_contact : null;
        // Primary Contact Name
        FakeRequestObject.primary_contact_name = !_.isEmpty(FakeRequestObject.primary_contact) ? FakeRequestObject.primary_contact.name : null;
        // Primary Contact Phone
        FakeRequestObject.primary_contact_phone = !_.isEmpty(FakeRequestObject.primary_contact) ? FakeRequestObject.primary_contact.phone : null;
        // Primary Contact Email
        FakeRequestObject.primary_contact_email = !_.isEmpty(FakeRequestObject.primary_contact) ? FakeRequestObject.primary_contact.email : null;
        // Property
        FakeRequestObject.property = !_.isEmpty(FakeRequestObject.account) ? FakeRequestObject.account.primary_property : null;
        // Property Address
        FakeRequestObject.primary_property_address = !_.isEmpty(FakeRequestObject.property) ? FakeRequestObject.property.address : null;
        // Priority
        FakeRequestObject.priority = (Math.random() < 0.87) ? (prioritiesList[_.random(0, (prioritiesList.length - 1))]) : null;
        // Source
        FakeRequestObject.source = (Math.random() < 0.87) ? (sourcesList[_.random(0, (sourcesList.length - 1))]) : null;
        // Zone
        FakeRequestObject.zone = (Math.random() < 0.87) ? (globalZonesList[_.random(0, (globalZonesList.length - 1))]) : null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(requestTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = requestTagsList[_.random(0, (requestTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeRequestObject.tags = Object.values(tagsObjectMapping);
        }
        // Owner
        FakeRequestObject.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.78) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Request ID
        FakeRequestObject.request_id = uniqid(`req_`);
        // Request Number
        FakeRequestObject.request_number = Index;
        // Instructions
        FakeRequestObject.instructions = FakeRequestData.instructions;
        // Client Instructions
        FakeRequestObject.client_instructions = (Math.random() < 0.85) ? FakeRequestData.client_instructions : null;
        // Minimum Crew Size
        FakeRequestObject.minimum_crew_size = (Math.random() < 0.5) ? parseInt(Math.random() * 5) : null;
        // Recommended Crew Size
        FakeRequestObject.recommended_crew_size = (Math.random() < 0.5 || FakeRequestObject.minimum_crew_size !== null) ? parseInt(Math.random() * 5) : null;
        // Assignment Status
        FakeRequestObject.assignment_status = !_.isEmpty(assignmentStatusesList) ? assignmentStatusesList[_.random(0, (assignmentStatusesList.length - 1))] : null;
        // Schedule Status
        FakeRequestObject.schedule_status = !_.isEmpty(scheduleStatusesList) ? scheduleStatusesList[_.random(0, (scheduleStatusesList.length - 1))] : null;
        // Is Assigned
        FakeRequestObject.is_assigned = (Math.random() < 0.5) ? true : false;
        // Is Scheduled
        FakeRequestObject.is_scheduled = (Math.random() < 0.5) ? true : false;
        // Is Assessment Required
        FakeRequestObject.is_assessment_required = (Math.random() < 0.19) ? true : false;
        // Is Active
        FakeRequestObject.is_active = (Math.random() < 0.96) ? true : false;
        // Is Completed
        FakeRequestObject.is_completed = (Math.random() < 0.33) ? true : false;
        // Is Converted
        FakeRequestObject.is_converted = (Math.random() < 0.25) ? true : false;
        // Is On Hold
        FakeRequestObject.is_on_hold = (Math.random() < 0.09) ? true : false;
        // Is Archived
        FakeRequestObject.is_archived = false;
        // Is Deleted
        FakeRequestObject.is_deleted = false;
        // Is Draft
        FakeRequestObject.is_draft = false;
        // Is Template
        FakeRequestObject.is_template = false;
        // Is Custom
        FakeRequestObject.is_custom = false;
        // Completed Alert
        FakeRequestObject.completed_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Completed At
        FakeRequestObject.completed_at = !_.isEmpty(FakeRequestObject.completed_alert) ? moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate() : null;
        // Completed By
        FakeRequestObject.completed_by = !_.isEmpty(UsersData) ? !_.isEmpty(FakeRequestObject.completed_alert) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Converted Alert
        FakeRequestObject.converted_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Converted At
        FakeRequestObject.converted_at = !_.isEmpty(FakeRequestObject.converted_alert) ? moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate() : null;
        // Converted By
        FakeRequestObject.converted_by = !_.isEmpty(UsersData) ? !_.isEmpty(FakeRequestObject.converted_alert) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Closed Alert
        FakeRequestObject.closed_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Closed At
        FakeRequestObject.closed_at = !_.isEmpty(FakeRequestObject.closed_alert) ? moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate() : null;
        // Closed By
        FakeRequestObject.closed_by = !_.isEmpty(UsersData) ? !_.isEmpty(FakeRequestObject.closed_alert) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Created At
        FakeRequestObject.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeRequestObject.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeRequestObject.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeRequestObject.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeRequestObject.last_viewed_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Viewed By
        FakeRequestObject.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Popup Alert
        FakeRequestObject.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // On Hold Alert
        FakeRequestObject.on_hold_alert = FakeRequestObject.is_on_hold ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Parent Request
        FakeRequestObject.parent = !_.isEmpty(RequestsData) ? (Math.random() < 0.2) ? RequestsData[_.random(0, RequestsData.length - 1)] : null : null;
        // Level
        FakeRequestObject.level = !_.isEmpty(FakeRequestObject.parent) ? 2 : 1;
        // Is Sub Request
        FakeRequestObject.is_sub_request = !_.isEmpty(FakeRequestObject.parent);
        // Archived Alert
        FakeRequestObject.archived_alert = null;
        // Archived At
        FakeRequestObject.archived_at = null;
        // Archived By
        FakeRequestObject.archived_by = null;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeRequestObject.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Language
        FakeRequestObject.language = languagesObjMap.en;
        // Currency
        FakeRequestObject.currency = currenciesObjMap.usd;
        // Sync Token
        FakeRequestObject.sync_token = 1;
        // Suggested Action
        FakeRequestObject.suggested_action = (Math.random() < 0.21) ? (requestItemActionsList[_.random(1, (requestItemActionsList.length - 1))]) : null;
        // Metadata
        FakeRequestObject.metadata = {};
        // Settings
        FakeRequestObject.settings = {};
        // Custom Fields
        FakeRequestObject.custom_fields = {};

        // Return Fake Request Object
        return FakeRequestObject;
    }

    _GetRandomDate(start, end) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }

    /**
     * Creates a fake Estimate based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object} FakeEstimateRecord A fake created estimate record with subject, description, instruction, client_instructions, type, priority, total, and line items list (objects that contain label, type, item, price)
     * @param {Object[]} UsersData The array of generated user records
     * @param {Object[]} AccountsData The array of generated account records
     * @param {Object[]} RequestsData The array of generated request records
     * @param {Object[]} EstimatesData The array of generated estimate records
     * @param {Object} LedgersMap The object mapping of identifier to object of generated ledger records
     * 
     * @returns {Object} A single randomly generated ProHelper Request object given the inputs provided.
     */
    _CreateFakeEstimateData(Index, Auth, PermissionsData, CustomizationsData, FakeEstimateRecord, UsersData, AccountsData, RequestsData, EstimatesData, LineItemsData, LedgersMap) {
        // Initalize local variables
        let FakeEstimateData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const estimateObjectFieldsMap = CustomizationsData.object_fields.estimates;
        const estimateTypesMap = CustomizationsData.object_types.estimates;
        const estimateTypesList = Object.values(estimateTypesMap);
        const estimatePrioritiesObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.priority.dropdown_set_options.object_identifier][estimateObjectFieldsMap.priority.dropdown_set].dropdowns;
        const estimateStatusesObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.status.dropdown_set_options.object_identifier][estimateObjectFieldsMap.status.dropdown_set].dropdowns;
        const estimateStatusesList = Object.values(estimateStatusesObjMap);
        const estimateRatingsObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.rating.dropdown_set_options.object_identifier][estimateObjectFieldsMap.rating.dropdown_set].dropdowns;
        const estimateRatingsList = Object.values(estimateRatingsObjMap);
        const estimateSourcesObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.source.dropdown_set_options.object_identifier][estimateObjectFieldsMap.source.dropdown_set].dropdowns;
        const estimateSourcesList = Object.values(estimateSourcesObjMap);
        const zonesObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.zone.dropdown_set_options.object_identifier][estimateObjectFieldsMap.zone.dropdown_set].dropdowns;
        const zonesList = Object.values(zonesObjMap);
        const estimateTagsObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.tags.dropdown_set_options.object_identifier][estimateObjectFieldsMap.tags.dropdown_set].dropdowns;
        const estimateTagsList = Object.values(estimateTagsObjMap);
        const estimateResultsObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.result.dropdown_set_options.object_identifier][estimateObjectFieldsMap.result.dropdown_set].dropdowns;
        const estimateResultsList = Object.values(estimateResultsObjMap);
        const estimateResultReasonsObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.result_reason.dropdown_set_options.object_identifier][estimateObjectFieldsMap.result_reason.dropdown_set].dropdowns;
        const estimateResultReasonsList = Object.values(estimateResultReasonsObjMap);
        const estimateSendMethodsObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.send_method.dropdown_set_options.object_identifier][estimateObjectFieldsMap.send_method.dropdown_set].dropdowns;
        const estimateSendMethodsList = Object.values(estimateSendMethodsObjMap);
        const paymentMethodsObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.payment_method.dropdown_set_options.object_identifier][estimateObjectFieldsMap.payment_method.dropdown_set].dropdowns;
        const paymentMethodsList = Object.values(paymentMethodsObjMap);
        const estimateItemActionsObjMap = CustomizationsData.actions.estimates.item_actions;
        const estimateItemActionsList = Object.values(estimateItemActionsObjMap);
        const currenciesObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.currency.dropdown_set_options.object_identifier][estimateObjectFieldsMap.currency.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][estimateObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[estimateObjectFieldsMap.language.dropdown_set_options.object_identifier][estimateObjectFieldsMap.language.dropdown_set].dropdowns;

        const fakeScheduleInfo = FakeSecondaryData.getFakeEventScheduleInfo();


        // Database ID
        FakeEstimateData.id = uniqid(`db_`);
        // Company ID
        FakeEstimateData.company = Auth.company.id;
        // Subject
        FakeEstimateData.subject = FakeEstimateRecord.subject;
        // Description
        FakeEstimateData.description = (Math.random() < 0.9) ? FakeEstimateRecord.description : null;
        // Type
        FakeEstimateData.type = (Math.random() < 0.97) ? !_.isEmpty(estimateTypesMap[FakeEstimateRecord.type]) ? estimateTypesMap[FakeEstimateRecord.type] : null : null;
        // Client Instructions
        FakeEstimateData.client_instructions = (Math.random() < 0.9) ? FakeEstimateRecord.client_instructions : null;
        //
        // FIELDS INSERTED HERE FOR LINEAR DATE TIMELINE
        // Created At
        FakeEstimateData.created_at = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Created By
        FakeEstimateData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeEstimateData.last_updated_at = this._GetRandomDate(FakeEstimateData.created_at, new Date());
        // Last Updated By
        FakeEstimateData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeEstimateData.last_viewed_at = this._GetRandomDate(FakeEstimateData.created_at, new Date());
        // Last Viewed By
        FakeEstimateData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakeEstimateData.last_activity_at = this._GetRandomDate(FakeEstimateData.created_at, new Date());
        // Last Activity By
        FakeEstimateData.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        //
        //
        // Due Date
        FakeEstimateData.due_date = this._GetRandomDate(moment(new Date()).add(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Expiration Date
        FakeEstimateData.expiration_date = this._GetRandomDate(moment(new Date()).add(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Issued Date
        FakeEstimateData.issued_date = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Is Sent
        FakeEstimateData.is_active = (Math.random() < 0.98);
        // Is Sent
        FakeEstimateData.is_sent = (Math.random() < 0.75 && FakeEstimateData.is_active);
        // Sent Date
        FakeEstimateData.sent_date = FakeEstimateData.is_sent ? this._GetRandomDate(FakeEstimateData.created_at, new Date()) : null;
        // Account
        FakeEstimateData.account = !_.isEmpty(AccountsData) && (Math.random() < 0.92) ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Primary Contact
        FakeEstimateData.primary_contact = !_.isEmpty(FakeEstimateData.account) ? FakeEstimateData.account.primary_contact : null;
        // Primary Property
        FakeEstimateData.primary_property = !_.isEmpty(FakeEstimateData.account) ? FakeEstimateData.account.primary_property : null;
        // Billing Contact
        FakeEstimateData.billing_contact = !_.isEmpty(FakeEstimateData.account) ? FakeEstimateData.account.billing_contact ? FakeEstimateData.account.billing_contact : FakeEstimateData.primary_contact : null;
        // Billing Property
        FakeEstimateData.billing_property = !_.isEmpty(FakeEstimateData.account) ? FakeEstimateData.account.billing_property ? FakeEstimateData.account.billing_property : FakeEstimateData.primary_property : null;
        // Service Contact
        FakeEstimateData.service_contact = FakeEstimateData.primary_contact;
        // Service Property
        FakeEstimateData.service_property = FakeEstimateData.primary_property;
        // Status
        FakeEstimateData.status = estimateStatusesList[_.random(0, (estimateStatusesList.length - 1))];
        // Priority
        FakeEstimateData.priority = (Math.random() < 0.97 && !_.isEmpty(estimatePrioritiesObjMap[FakeEstimateRecord.priority])) ? estimatePrioritiesObjMap[FakeEstimateRecord.priority] : null;
        // Rating
        FakeEstimateData.rating = (Math.random() < 0.9) ? estimateRatingsList[_.random(0, (estimateRatingsList.length - 1))] : null;
        // Source
        FakeEstimateData.source = (Math.random() < 0.84) ? estimateSourcesList[_.random(0, (estimateSourcesList.length - 1))] : null;
        // Zone
        FakeEstimateData.zone = (!_.isEmpty(zonesList) && (Math.random() < 0.84)) ? zonesList[_.random(0, (zonesList.length - 1))] : null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(estimateTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = estimateTagsList[_.random(0, (estimateTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeEstimateData.tags = Object.values(tagsObjectMapping);
        }
        // Estimate ID
        FakeEstimateData.estimate_id = uniqid(`estimate_`);
        // Estimate Number
        FakeEstimateData.estimate_number = Index;
        // Estimator
        FakeEstimateData.estimator = (!_.isEmpty(UsersData) && (Math.random() < 0.78)) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Request
        FakeEstimateData.request = (Math.random() < 0.84) ? RequestsData[_.random(0, RequestsData.length - 1)] : null;
        // Subscription
        FakeEstimateData.subscription = null;
        // Footer Message
        FakeEstimateData.footer_message = null;
        // Customer Response
        FakeEstimateData.customer_response = null;
        // Result
        FakeEstimateData.result = (Math.random() < 0.84) ? estimateResultsList[_.random(0, estimateResultsList.length - 1)] : null;
        // Result Reason
        FakeEstimateData.result_reason = (Math.random() < 0.84) ? estimateResultReasonsList[_.random(0, estimateResultReasonsList.length - 1)] : null;
        // Result At
        FakeEstimateData.result_at = this._GetRandomDate(FakeEstimateData.created_at, new Date());
        // Result By
        FakeEstimateData.result_by = (!_.isEmpty(UsersData) && (Math.random() < 0.78)) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Result Reason Description
        FakeEstimateData.result_reason_description = "";
        // Revenue Ledger
        FakeEstimateData.revenue_ledger = !_.isEmpty(LedgersMap.sales_of_product_income) ? LedgersMap.sales_of_product_income : null;
        // Expense Ledger
        FakeEstimateData.expense_ledger = !_.isEmpty(LedgersMap.billable_expense_income) ? LedgersMap.billable_expense_income : null;
        // Payment Method
        FakeEstimateData.payment_method = (Math.random() < 0.9) ? paymentMethodsList[_.random(0, paymentMethodsList.length - 1)] : null;
        // Send Method
        FakeEstimateData.send_method = (Math.random() < 0.75) ? estimateSendMethodsList[_.random(0, estimateSendMethodsList.length - 1)] : null;
        // Handle Line Items Generation
        FakeEstimateData.line_items = [];
        const randomNumOfLineItems = _.random(1, 5);

        for (let i = 0; i < randomNumOfLineItems; i++) {
            const currLineItemRecord = this._CreateFakeLineItemData(i, Auth, PermissionsData, CustomizationsData, 'estimates', FakeEstimateData, UsersData, LineItemsData, AccountsData);
            FakeEstimateData.line_items.push(currLineItemRecord);
        }


        // Deposit
        FakeEstimateData.deposit = 0;
        // Sub Total
        FakeEstimateData.sub_total = FakeEstimateRecord.total;
        // Discounts
        FakeEstimateData.discounts = null;
        // Discount Amount
        FakeEstimateData.discount_amount = 0;
        // Is Taxable
        FakeEstimateData.is_taxable = (Math.random() < 0.84);
        // Is Tax applied after discounts
        FakeEstimateData.is_tax_applied_after_discounts = true;
        // Tax Rates
        FakeEstimateData.tax_rates = null;
        // Tax Amount
        FakeEstimateData.tax_amount = 0;
        // Total
        FakeEstimateData.total = FakeEstimateRecord.total;
        // Total excluding tax
        FakeEstimateData.total_excluding_tax = FakeEstimateRecord.total;
        // Amount Due
        FakeEstimateData.amount_due = FakeEstimateRecord.total;
        // Signature
        FakeEstimateData.signature = null;
        // Primary Contact's Name
        FakeEstimateData.primary_contact_name = !_.isEmpty(FakeEstimateData.primary_contact) ? FakeEstimateData.primary_contact.name : null;
        // Primary Contact's Phone
        FakeEstimateData.primary_contact_phone = !_.isEmpty(FakeEstimateData.primary_contact) ? FakeEstimateData.primary_contact.phone : null;
        // Primary Contact's Email
        FakeEstimateData.primary_contact_email = !_.isEmpty(FakeEstimateData.primary_contact) ? FakeEstimateData.primary_contact.email : null;
        // Primary Property's Address
        FakeEstimateData.primary_property_address = !_.isEmpty(FakeEstimateData.primary_property) ? FakeEstimateData.primary_property.address : null;
        // Billing Contact's Name
        FakeEstimateData.billing_contact_name = !_.isEmpty(FakeEstimateData.billing_contact) ? FakeEstimateData.billing_contact.name : null;
        // Billing Contact's Phone
        FakeEstimateData.billing_contact_phone = !_.isEmpty(FakeEstimateData.billing_contact) ? FakeEstimateData.billing_contact.phone : null;
        // Billing Contact's Email
        FakeEstimateData.billing_contact_phone = !_.isEmpty(FakeEstimateData.billing_contact) ? FakeEstimateData.billing_contact.phone : null;
        // Billing Property's Address
        FakeEstimateData.billing_property_address = !_.isEmpty(FakeEstimateData.billing_property) ? FakeEstimateData.billing_property.address : null;
        // Service Contact's Name
        FakeEstimateData.service_contact_name = !_.isEmpty(FakeEstimateData.service_contact) ? FakeEstimateData.service_contact.name : null;
        // Service Contact's Phone
        FakeEstimateData.service_contact_phone = !_.isEmpty(FakeEstimateData.service_contact) ? FakeEstimateData.service_contact.phone : null;
        // Service Property's Address
        FakeEstimateData.service_property_address = !_.isEmpty(FakeEstimateData.service_property) ? FakeEstimateData.service_property.address : null;
        // Is On Hold
        FakeEstimateData.is_on_hold = !FakeEstimateData.is_active && Math.random < 0.6;
        // Is Closed
        FakeEstimateData.is_closed = !FakeEstimateData.is_active && Math.random < 0.5;
        // Is Completed
        FakeEstimateData.is_completed = !FakeEstimateData.is_active && Math.random < 0.45;
        // Is Converted
        FakeEstimateData.is_converted = !FakeEstimateData.is_completed && Math.random < 0.7;
        /// Is Archived
        FakeEstimateData.is_archived = !FakeEstimateData.is_active && Math.random < 0.25;
        // Is Deleted
        FakeEstimateData.is_deleted = !FakeEstimateData.is_active && Math.random < 0.25;
        // Is Live
        FakeEstimateData.is_live = true;
        // Is Draft
        FakeEstimateData.is_draft = false;
        // Is Template
        FakeEstimateData.is_template = false;
        // Is Sub Estaimte
        FakeEstimateData.is_sub_estimate = Math.random < 0.12;
        // Level
        FakeEstimateData.level = FakeEstimateData.is_sub_estimate ? 2 : 1;
        // Parent Estimate
        FakeEstimateData.parent = FakeEstimateData.is_sub_estimate ? EstimatesData[_.random(0, EstimatesData.length - 1)] : null;
        // Bill with Parent
        FakeEstimateData.bill_with_parent = Math.random < 0.5 && FakeEstimateData.is_sub_estimate;
        // Currency
        FakeEstimateData.currency = currenciesObjMap.usd;
        // Language
        FakeEstimateData.language = languagesObjMap.en;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeEstimateData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Hosted Estimate URL
        FakeEstimateData.hosted_domain_url = null;
        // Domain
        FakeEstimateData.domain = 'pro';
        // Popup Alert
        FakeEstimateData.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Completed Alert
        FakeEstimateData.completed_alert = null;
        // Closed Alert
        FakeEstimateData.closed_alert = null;
        // Converted Alert
        FakeEstimateData.converted_alert = null;
        // Locked Alert
        FakeEstimateData.locked_alert = null;
        // On Hold Alert
        FakeEstimateData.on_hold_alert = null;
        // Archived Alert
        FakeEstimateData.archived_alert = null;

        /**
         * Usually created, last_updated, etc fields go here (but moved for linear timeline)
         */

        // Completed At
        FakeEstimateData.completed_at = FakeEstimateData.is_completed ? this._GetRandomDate(FakeEstimateData.created_at, new Date()) : null;
        // Completed By
        FakeEstimateData.completed_by = FakeEstimateData.is_completed ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Closed At
        FakeEstimateData.closed_at = FakeEstimateData.is_closed ? this._GetRandomDate(FakeEstimateData.created_at, new Date()) : null;
        // Closed By
        FakeEstimateData.closed_by = FakeEstimateData.is_closed ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Converted At
        FakeEstimateData.converted_at = FakeEstimateData.is_converted ? this._GetRandomDate(FakeEstimateData.created_at, new Date()) : null;
        // Converted By
        FakeEstimateData.converted_by = FakeEstimateData.is_converted ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Archived At
        FakeEstimateData.archived_at = null;
        // Archived By
        FakeEstimateData.archived_by = null;
        // Sync Token
        FakeEstimateData.sync_token = 1;
        // Suggested Action
        FakeEstimateData.suggested_action = (Math.random() < 0.26) ? (estimateItemActionsList[_.random(1, (estimateItemActionsList.length - 1))]) : null;
        // Notifications
        FakeEstimateData.notifications = {};
        // Metadata
        FakeEstimateData.metadata = {};
        // Payment Settings
        FakeEstimateData.payment_settings = {};
        // Settings
        FakeEstimateData.settings = {};
        // Custom Fields
        FakeEstimateData.custom_fields = {};


        return FakeEstimateData;
    }

    /**
     * Creates a fake Task based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object[]} UsersData The array of generated user records
     * @param {Object[]} AccountsData The array of generated account records
     * @param {Object[]} TasksData The array of generated task records
     * 
     * @returns {Object} A single randomly generated ProHelper Task object given the inputs provided.
     */
    _CreateFakeTaskData(Index, Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, TasksData) {
        // Initalize local variables
        let FakeTaskData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const taskObjectFieldsMap = CustomizationsData.object_fields.tasks;
        const taskTypesMap = CustomizationsData.object_types.tasks;
        const taskTypesList = Object.values(taskTypesMap);
        const taskStatusObjMap = CustomizationsData.dropdown_sets[taskObjectFieldsMap.status.dropdown_set_options.object_identifier][taskObjectFieldsMap.status.dropdown_set].dropdowns;
        const taskStatusList = Object.values(taskStatusObjMap);
        const prioritiesObjMap = CustomizationsData.dropdown_sets[taskObjectFieldsMap.priority.dropdown_set_options.object_identifier][taskObjectFieldsMap.priority.dropdown_set].dropdowns;
        const prioritiesList = Object.values(prioritiesObjMap);
        const taskTagsObjMap = CustomizationsData.dropdown_sets[taskObjectFieldsMap.tags.dropdown_set_options.object_identifier][taskObjectFieldsMap.tags.dropdown_set].dropdowns;
        const taskTagsList = Object.values(taskTagsObjMap);
        const taskItemActionsObjMap = CustomizationsData.actions.tasks.item_actions;
        const taskItemActionsList = Object.values(taskItemActionsObjMap);


        // Created At
        FakeTaskData.created_at = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Created By
        FakeTaskData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeTaskData.last_updated_at = this._GetRandomDate(FakeTaskData.created_at, new Date());
        // Last Updated By
        FakeTaskData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeTaskData.last_viewed_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Viewed By
        FakeTaskData.last_viewed_by = this._GetRandomDate(FakeTaskData.created_at, new Date());


        // Database ID
        FakeTaskData.id = uniqid(`db_`);
        // Company ID
        FakeTaskData.company = Auth.company.id;
        // Label
        FakeTaskData.subject = FakeSecondaryData.taskLabelList[_.random(0, (FakeSecondaryData.taskLabelList.length - 1))];
        // Description
        FakeTaskData.description = FakeSecondaryData.workDescriptionList[_.random(0, (FakeSecondaryData.workDescriptionList.length - 1))]
        // Type
        FakeTaskData.type = (Math.random() < 0.94) ? (taskTypesList[_.random(0, (taskTypesList.length - 1))]) : null;
        // Status
        FakeTaskData.status = taskStatusList[_.random(0, (taskStatusList.length - 1))];
        // Start Date
        FakeTaskData.start_date = this._GetRandomDate(FakeTaskData.created_at, new Date());
        // Due Date
        FakeTaskData.due_date = this._GetRandomDate(FakeTaskData.created_at, moment(new Date()).add(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate());
        // Assigned To

        // Account
        FakeTaskData.account = !_.isEmpty(AccountsData) ? (Math.random() < 0.92) ? AccountsData[_.random(0, AccountsData.length - 1)] : null : null;
        // Primary Contact
        FakeTaskData.primary_contact = !_.isEmpty(FakeTaskData.account) ? FakeTaskData.account.primary_contact : null;
        // Primary Contact's Name
        FakeTaskData.primary_contact_name = !_.isEmpty(FakeTaskData.primary_contact) ? FakeTaskData.primary_contact.name : null;
        // Primary Contact's Number
        FakeTaskData.primary_contact_phone = !_.isEmpty(FakeTaskData.primary_contact) ? FakeTaskData.primary_contact.phone : null;
        // Primary Contact's Email Address
        FakeTaskData.primary_contact_email = !_.isEmpty(FakeTaskData.primary_contact) ? FakeTaskData.primary_contact.email : null;
        // Property
        FakeTaskData.property = !_.isEmpty(FakeTaskData.account) ? FakeTaskData.account.primary_property : null;
        // Primary Property Address
        FakeTaskData.primary_property_address = !_.isEmpty(FakeTaskData.primary_property) ? FakeTaskData.primary_property.address : null;
        // Priority
        FakeTaskData.priority = (Math.random() < 0.84) ? prioritiesList[_.random(0, (prioritiesList.length - 1))] : null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(taskTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = taskTagsList[_.random(0, (taskTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeTaskData.tags = Object.values(tagsObjectMapping);
        }
        // Owner
        FakeTaskData.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.78) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Task Number
        FakeTaskData.task_number = Index;
        // Task ID
        FakeTaskData.task_id = uniqid(`task_`);
        // Is Active 
        FakeTaskData.is_active = (Math.random() < 0.98);
        // Is Completed
        FakeTaskData.is_completed = !FakeTaskData.is_active && Math.random < 0.45;
        // Is Converted
        FakeTaskData.is_converted = !FakeTaskData.is_active && Math.random < 0.3;
        // Is Closed
        FakeTaskData.is_closed = !FakeTaskData.is_active && (FakeTaskData.is_completed || FakeTaskData.is_closed || Math.random < 0.2);
        // Completed Alert
        FakeTaskData.completed_alert = FakeTaskData.is_completed ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Completed At
        FakeTaskData.completed_at = FakeTaskData.is_completed ? this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate()) : null;
        // Completed By
        FakeTaskData.completed_by = (!_.isEmpty(UsersData) && FakeTaskData.is_completed) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Converted Alert
        FakeTaskData.converted_alert = FakeTaskData.is_converted ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Converted At
        FakeTaskData.converted_at = FakeTaskData.is_converted ? this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate()) : null;
        // Converted By
        FakeTaskData.converted_by = (!_.isEmpty(UsersData) && FakeTaskData.is_converted) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Closed Alert
        FakeTaskData.closed_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Closed At
        FakeTaskData.closed_at = FakeTaskData.is_converted ? this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate()) : null;
        // Closed By
        FakeTaskData.closed_by = (!_.isEmpty(UsersData) && FakeTaskData.is_converted) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Is Sub Task
        FakeTaskData.is_sub_task = Math.random < 0.4;
        // Parent
        FakeTaskData.parent = (FakeTaskData.is_sub_task && !_.isEmpty(TasksData)) ? TasksData[_.random(0, TasksData.length - 1)] : null;
        // Level
        FakeTaskData.level = FakeTaskData.is_sub_task ? 2 : 1;
        // Archived Alert
        FakeTaskData.archived_alert = null;
        // Archived At
        FakeTaskData.archived_at = null;
        // Archived By
        FakeTaskData.archived_by = null;
        // Sync Token
        FakeTaskData.sync_token = 1;
        // Suggested Action
        FakeTaskData.suggested_action = (Math.random() < 0.26) ? (taskItemActionsList[_.random(1, (taskItemActionsList.length - 1))]) : null;
        // Metadata
        FakeTaskData.metadata = {};
        // Settings
        FakeTaskData.settings = {};
        // Custom Fields
        FakeTaskData.custom_fields = {};


        return FakeTaskData;
    }

    /**
     * Creates a fake Job based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object[]} UsersData The array of generated User records
     * @param {Object[]} AccountsData The array of generated Account records
     * @param {Object[]} RequestsData The array of generated Request records
     * @param {Object[]} EstimatesData The array of generated Estimate records
     * @param {Object[]} JobsData The array of generated Job records
     * @param {Object} LedgersMap The object mapping of identifier to object of generated ledger records
     * 
     * @returns {Object} A single randomly generated ProHelper Job object given the inputs provided.
     */
    _CreateFakeJobData(Index, Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, RequestsData, EstimatesData, JobsData, LedgersMap, LineItemsData) {
        // Initalize local variables
        let FakeJobData = {};
        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const jobObjectFieldsMap = CustomizationsData.object_fields.jobs;
        const jobTypesMap = CustomizationsData.object_types.jobs;
        const jobTypesList = Object.values(jobTypesMap);
        const jobStatusesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.status.dropdown_set_options.object_identifier][jobObjectFieldsMap.status.dropdown_set].dropdowns;
        const jobStatusesList = Object.values(jobStatusesObjMap);
        const prioritiesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.priority.dropdown_set_options.object_identifier][jobObjectFieldsMap.priority.dropdown_set].dropdowns;
        const prioritiesList = Object.values(prioritiesObjMap);
        const sourcesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.source.dropdown_set_options.object_identifier][jobObjectFieldsMap.source.dropdown_set].dropdowns;
        const sourcesList = Object.values(sourcesObjMap);
        const zonesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.zone.dropdown_set_options.object_identifier][jobObjectFieldsMap.zone.dropdown_set].dropdowns;
        const zonesList = Object.values(zonesObjMap);
        const jobTagsObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.tags.dropdown_set_options.object_identifier][jobObjectFieldsMap.tags.dropdown_set].dropdowns;
        const jobTagsList = Object.values(jobTagsObjMap);
        const skillsObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.skills_required.dropdown_set_options.object_identifier][jobObjectFieldsMap.skills_required.dropdown_set].dropdowns;
        const skillsList = Object.values(skillsObjMap);
        const toolsObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.tools_required.dropdown_set_options.object_identifier][jobObjectFieldsMap.tools_required.dropdown_set].dropdowns;
        const toolsList = Object.values(toolsObjMap);
        const assignmentStatusesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.assignment_status.dropdown_set_options.object_identifier][jobObjectFieldsMap.assignment_status.dropdown_set].dropdowns;
        const assignmentStatusesList = Object.values(assignmentStatusesObjMap);
        const scheduleStatusesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.schedule_status.dropdown_set_options.object_identifier][jobObjectFieldsMap.schedule_status.dropdown_set].dropdowns;
        const scheduleStatusesList = Object.values(scheduleStatusesObjMap);
        const jobItemActionsObjMap = CustomizationsData.actions.jobs.item_actions;
        const jobItemActionsList = Object.values(jobItemActionsObjMap);
        const currenciesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.currency.dropdown_set_options.object_identifier][jobObjectFieldsMap.currency.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.language.dropdown_set_options.object_identifier][jobObjectFieldsMap.language.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[jobObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][jobObjectFieldsMap.time_zone.dropdown_set].dropdowns;


        // Database ID
        FakeJobData.id = uniqid(`db_`);
        // Company ID
        FakeJobData.company = Auth.company.id;
        // Subject
        FakeJobData.subject = FakeSecondaryData.jobLabelList[_.random(0, (FakeSecondaryData.jobLabelList.length - 1))];
        // Description
        FakeJobData.description = (Math.random() < 0.65) ? FakeSecondaryData.workDescriptionList[_.random(0, (FakeSecondaryData.workDescriptionList.length - 1))] : null;
        // Type
        FakeJobData.type = (Math.random() < 0.94) ? (jobTypesList[_.random(0, (jobTypesList.length - 1))]) : null;
        // Due Date
        FakeJobData.due_date = moment(new Date()).add(moment.duration(Math.floor(Math.random() * 7), 'days')).toDate();
        // Account
        FakeJobData.account = !_.isEmpty(AccountsData) ? (Math.random() < 0.92) ? AccountsData[_.random(0, AccountsData.length - 1)] : null : null;
        // Primary Contact
        FakeJobData.primary_contact = !_.isEmpty(FakeJobData.account) ? FakeJobData.account.primary_contact : null;
        // Contact Name
        FakeJobData.contact_name = !_.isEmpty(FakeJobData.primary_contact) ? FakeJobData.primary_contact.name : null;
        // Contact Phone Number
        FakeJobData.contact_phone = !_.isEmpty(FakeJobData.primary_contact) ? FakeJobData.primary_contact.phone : null;
        // Contact Email Address
        FakeJobData.contact_email = !_.isEmpty(FakeJobData.primary_contact) ? FakeJobData.primary_contact.email : null;
        // Property
        FakeJobData.property = !_.isEmpty(FakeJobData.account) ? FakeJobData.account.primary_property : null;
        // Property Address
        FakeJobData.property_address = !_.isEmpty(FakeJobData.property) ? FakeJobData.property.address : null;
        // Status
        FakeJobData.status = jobStatusesList[_.random(0, (jobStatusesList.length - 1))];
        // Priority
        FakeJobData.priority = !_.isEmpty(prioritiesList) ? (Math.random() < 0.84) ? prioritiesList[_.random(0, (prioritiesList.length - 1))] : null : null;
        // Skills Required
        const randomNumOfSkills = (Math.random() < 0.84) ? (_.random(1, 3)) : 0;
        if (randomNumOfSkills > 0 && !_.isEmpty(skillsList)) {

        }
        // Tools Required
        const randomNumOfTools = (Math.random() < 0.84) ? (_.random(1, 4)) : 0;
        if (randomNumOfTools > 0 && !_.isEmpty(toolsList)) {

        }
        // Source
        FakeJobData.source = !_.isEmpty(sourcesList) ? (Math.random() < 0.84) ? sourcesList[_.random(0, (sourcesList.length - 1))] : null : null;
        // Zone
        FakeJobData.zone = !_.isEmpty(zonesList) ? (Math.random() < 0.84) ? zonesList[_.random(0, (zonesList.length - 1))] : null : null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(jobTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = jobTagsList[_.random(0, (jobTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeJobData.tags = Object.values(tagsObjectMapping);
        }
        // Internal Instructions
        FakeJobData.internal_instructions = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : null;
        // Client Instructions
        FakeJobData.client_instructions = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : null;
        // Resolution
        FakeJobData.resolution = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : null;
        // Public Resolution
        FakeJobData.public_resolution = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : null;
        // Request
        FakeJobData.request = !_.isEmpty(RequestsData) && (Math.random() < 0.85) ? RequestsData[_.random(0, RequestsData.length - 1)] : null;
        // Request Converted By
        FakeJobData.request_converted_by = !_.isEmpty(FakeJobData.request) ? UsersData[_.random(0, RequestsData.length - 1)] : null;
        // Request Converted At
        FakeJobData.request_converted_by = !_.isEmpty(FakeJobData.request) ? moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate() : null;
        // Estimate
        FakeJobData.estimate = !_.isEmpty(UsersData) && (Math.random() < 0.7) ? EstimatesData[_.random(0, EstimatesData.length - 1)] : null;
        // Estimate Converted By
        FakeJobData.estimate_converted_by = null;
        // Estimate Covnerted At
        FakeJobData.estimate_converted_at = null;
        // Owner
        FakeJobData.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.78) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Job ID
        FakeJobData.job_id = uniqid(`job_`);
        // Job Number
        FakeJobData.job_number = Index;
        // Is Active
        FakeJobData.is_active = true;
        // Custom Position
        FakeJobData.custom_position = Index;
        // Is Assigned
        FakeJobData.is_assigned = (Math.random() < 0.5) ? true : false;
        // Assignment Status
        FakeJobData.assignment_status = !_.isEmpty(assignmentStatusesList) ? assignmentStatusesList[_.random(0, (assignmentStatusesList.length - 1))] : null;
        // Is Scheduled
        FakeJobData.is_scheduled = (Math.random() < 0.5) ? true : false;
        // Schedule Status
        FakeJobData.schedule_status = !_.isEmpty(scheduleStatusesList) ? scheduleStatusesList[_.random(0, (scheduleStatusesList.length - 1))] : null;
        // Is Recurrence
        FakeJobData.is_assigned = (Math.random() < 0.5) ? true : false;
        // Is Recurrence Exception
        FakeJobData.is_recurrence_schedule = false;
        // Recurrence Schedule
        FakeJobData.recurrence_schedule = null;
        // Minimum Crew Size
        FakeJobData.minimum_crew_size = (Math.random() < 0.5) ? parseInt(Math.random() * 10) : null;
        // Recommended Crew Size
        FakeJobData.recommended_crew_size = (Math.random() < 0.5) ? parseInt(Math.random() * 10) : null;
        // Number of Reschedules
        FakeJobData.number_of_reschedule = (Math.random() < 0.5) ? parseInt(Math.random() * 4) : null;
        // Number of Visits
        FakeJobData.number_of_visits = (Math.random() < 0.5) ? parseInt(Math.random() * 4) : null;
        // Deposit
        FakeJobData.deposit = 0;
        // Discounts
        FakeJobData.discounts = null;
        // Sub Total
        FakeJobData.sub_total = 0;
        // Total
        FakeJobData.total = 0;
        // Balance
        FakeJobData.balance = 0;
        // Sub Total with Sub Jobs
        FakeJobData.sub_total_with_sub_jobs = 0;
        // Total with Sub Jobs
        FakeJobData.total_with_sub_jobs = 0;
        // Balance with Sub Jobs
        FakeJobData.balance_with_sub_jobs = 0;
        // Is Taxable
        FakeJobData.is_taxable = false;
        // Is Tax applied after Discounts
        FakeJobData.is_tax_applied_after_discounts = false;
        // Tax Amount
        FakeJobData.tax_amounts = null;
        // Tax Rates
        FakeJobData.tax_rates = null;
        // Route
        FakeJobData.route = null;
        // Parent
        FakeJobData.parent = Math.random < 0.4 ? !_.isEmpty(JobsData) ? JobsData[_.random(0, JobsData.length - 1)] : null : null;
        // Level
        FakeJobData.level = !_.isEmpty(FakeJobData.parent) ? 2 : 1;
        // Is Sub Job
        FakeJobData.is_sub_job = !_.isEmpty(FakeJobData.parent);
        // Bill with Parent Job
        FakeJobData.bill_with_parent_job = !_.isEmpty(FakeJobData.parent);
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeJobData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Language
        FakeJobData.language = languagesObjMap.en;
        // Currency
        FakeJobData.currency = currenciesObjMap.usd;
        // Subscription
        FakeJobData.subscription = null;
        // Created At
        FakeJobData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeJobData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeJobData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeJobData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeJobData.last_viewed_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Viewed By
        FakeJobData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Completed Alert
        FakeJobData.completed_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Completed At
        FakeJobData.completed_at = !_.isEmpty(FakeJobData.completed_alert) ? moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate() : null;
        // Completed By
        FakeJobData.completed_by = !_.isEmpty(UsersData) ? !_.isEmpty(FakeJobData.completed_alert) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Converted Alert
        FakeJobData.converted_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Converted At
        FakeJobData.converted_at = !_.isEmpty(UsersData) ? !_.isEmpty(FakeJobData.converted_alert) ? moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate() : null : null;
        // Converted By
        FakeJobData.converted_by = !_.isEmpty(UsersData) ? !_.isEmpty(FakeJobData.converted_alert) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Closed Alert
        FakeJobData.closed_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Closed At
        FakeJobData.closed_at = !_.isEmpty(UsersData) ? !_.isEmpty(FakeJobData.closed_alert) ? moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 14), 'days')).toDate() : null : null;
        // Closed By
        FakeJobData.closed_by = !_.isEmpty(UsersData) ? !_.isEmpty(FakeJobData.closed_alert) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Is Closed
        FakeJobData.is_closed = false;
        // Is Completed
        FakeJobData.is_completed = false;
        // Is Converted
        FakeJobData.is_converted = false;
        // Is Live
        FakeJobData.is_live = true;
        // Is Draft
        FakeJobData.is_draft = false;
        // Is Template
        FakeJobData.is_template = false;
        // Is On Hold
        FakeJobData.is_on_hold = false;
        // On Hold Alert
        FakeJobData.on_hold_alert = null;
        // Is Archived
        FakeJobData.is_archived = false;
        // Is Deleted
        FakeJobData.is_deleted = false;
        // Archived Alert
        FakeJobData.archived_alert = null;
        // Archived At
        FakeJobData.archived_at = null;
        // Archived By
        FakeJobData.archived_by = null;
        // Sync Token
        FakeJobData.sync_token = 1;
        // Suggested Action
        FakeJobData.suggested_action = (Math.random() < 0.26) ? (jobItemActionsList[_.random(1, (jobItemActionsList.length - 1))]) : null;
        // Notifications
        FakeJobData.notifications = {};
        // Metadata
        FakeJobData.metadata = {};
        // Settings
        FakeJobData.settings = {};
        // Custom Fields
        FakeJobData.custom_fields = {};

        // Handle Line Items Generation
        FakeJobData.line_items = [];
        const randomNumOfLineItems = _.random(5, 15);

        for (let i = 0; i < randomNumOfLineItems; i++) {
            const currLineItemRecord = this._CreateFakeLineItemData(i, Auth, PermissionsData, CustomizationsData, 'jobs', FakeJobData, UsersData, LineItemsData, AccountsData);
            
            FakeJobData.line_items.push(currLineItemRecord);
        }
        return FakeJobData;
    }

    /**
     * Creates a fake Route based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object[]} UsersData The array of generated User records
     * @param {Object[]} JobsData The array of generated Job records
     * 
     * @returns {Object} A single randomly generated ProHelper Route object given the inputs provided.
     */
    _CreateFakeRouteData(Index, Auth, PermissionsData, CustomizationsData, UsersData, JobsData) {
        // Initalize local variables
        let FakeRouteData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const routeObjectFieldsMap = CustomizationsData.object_fields.routes;
        const routeTypesMap = CustomizationsData.object_types.routes;
        const routeTypesList = Object.values(routeTypesMap);
        const routeStatusesObjMap = CustomizationsData.dropdown_sets[routeObjectFieldsMap.status.dropdown_set_options.object_identifier][routeObjectFieldsMap.status.dropdown_set].dropdowns;
        const routeStatusesList = Object.values(routeStatusesObjMap);
        const zonesObjMap = CustomizationsData.dropdown_sets[routeObjectFieldsMap.zone.dropdown_set_options.object_identifier][routeObjectFieldsMap.zone.dropdown_set].dropdowns;
        const zonesList = Object.values(zonesObjMap);
        const routeTagsObjMap = CustomizationsData.dropdown_sets[routeObjectFieldsMap.tags.dropdown_set_options.object_identifier][routeObjectFieldsMap.tags.dropdown_set].dropdowns;
        const routeTagsList = Object.values(routeTagsObjMap);
        const assignmentStatusesObjMap = CustomizationsData.dropdown_sets[routeObjectFieldsMap.assignment_status.dropdown_set_options.object_identifier][routeObjectFieldsMap.assignment_status.dropdown_set].dropdowns;
        const assignmentStatusesList = Object.values(assignmentStatusesObjMap);
        const scheduleStatusesObjMap = CustomizationsData.dropdown_sets[routeObjectFieldsMap.schedule_status.dropdown_set_options.object_identifier][routeObjectFieldsMap.schedule_status.dropdown_set].dropdowns;
        const scheduleStatusesList = Object.values(scheduleStatusesObjMap);
        const routeItemActionsObjMap = CustomizationsData.actions.routes.item_actions;
        const routeItemActionsList = Object.values(routeItemActionsObjMap);
        const timeZonesObjMap = CustomizationsData.dropdown_sets[routeObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][routeObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[routeObjectFieldsMap.language.dropdown_set_options.object_identifier][routeObjectFieldsMap.language.dropdown_set].dropdowns;

        // Database ID
        FakeRouteData.id = uniqid(`db_`);
        // Company ID
        FakeRouteData.company = Auth.company.id;
        // Title
        FakeRouteData.title = FakeSecondaryData.jobLabelList[_.random(0, (FakeSecondaryData.jobLabelList.length - 1))];
        // Description
        FakeRouteData.description = (Math.random() < 0.65) ? FakeSecondaryData.workDescriptionList[_.random(0, (FakeSecondaryData.workDescriptionList.length - 1))] : null;
        // Internal Instructions
        FakeRouteData.internal_instructions = (Math.random() < 0.50) ? FakeSecondaryData.descriptionList[_.random(0, (FakeSecondaryData.descriptionList.length - 1))] : null;
        // Type
        FakeRouteData.type = (Math.random() < 0.94) ? (routeTypesList[_.random(0, (routeTypesList.length - 1))]) : null;
        // Status
        FakeRouteData.status = routeStatusesList[_.random(0, (routeStatusesList.length - 1))];
        // Zone
        FakeRouteData.zone = !_.isEmpty(zonesList) ? (Math.random() < 0.84) ? zonesList[_.random(0, (zonesList.length - 1))] : null : null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(routeTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = routeTagsList[_.random(0, (routeTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeRouteData.tags = Object.values(tagsObjectMapping);
        }
        // Owner
        FakeRouteData.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.78) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Route ID
        FakeRouteData.route_id = uniqid(`route_`);
        // Route Number
        FakeRouteData.route_number = Index;
        // Is Active
        FakeRouteData.is_active = true;
        // Is Assigned
        FakeRouteData.is_assigned = (Math.random() < 0.65) ? true : false;
        // Assignment Status
        FakeRouteData.assignment_status = !_.isEmpty(assignmentStatusesList) ? assignmentStatusesList[_.random(0, (assignmentStatusesList.length - 1))] : null;
        // Is Scheduled
        FakeRouteData.is_scheduled = (Math.random() < 0.6) ? true : false;
        // Schedule Status
        FakeRouteData.assignment_status = !_.isEmpty(scheduleStatusesList) ? scheduleStatusesList[_.random(0, (scheduleStatusesList.length - 1))] : null;
        // Minimum Crew Size
        FakeRouteData.minimum_crew_size = (Math.random() < 0.5) ? parseInt(Math.random() * 10) : null;
        // Recommended Crew Size
        FakeRouteData.recommended_crew_size = (Math.random() < 0.5) ? parseInt(Math.random() * 10) : null;
        // Popup Alert
        FakeRouteData.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeRouteData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Language
        FakeRouteData.language = languagesObjMap.en;
        // Created At
        FakeRouteData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeRouteData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeRouteData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeRouteData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakeRouteData.last_activity_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Activity By
        FakeRouteData.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeRouteData.last_viewed_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Viewed By
        FakeRouteData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Is Archived
        FakeRouteData.is_archived = false;
        // Is Deleted
        FakeRouteData.is_deleted = false;
        // Is Live
        FakeRouteData.is_live = true;
        // Is Draft
        FakeRouteData.is_draft = false;
        // Is Template
        FakeRouteData.is_template = false;
        // Is On Hold
        FakeRouteData.is_on_hold = false;
        // On Hold Alert
        FakeRouteData.on_hold_alert = null;
        // Archived Alert
        FakeRouteData.archived_alert = null;
        // Archived At
        FakeRouteData.archived_at = null;
        // Archived By
        FakeRouteData.archived_by = null;
        // Sync Token
        FakeRouteData.sync_token = 1;
        // Suggested Action
        FakeRouteData.suggested_action = (Math.random() < 0.26) ? (routeItemActionsList[_.random(1, (routeItemActionsList.length - 1))]) : null;
        // Notifications
        FakeRouteData.notifications = {};
        // Metadata
        FakeRouteData.metadata = {};
        // Settings
        FakeRouteData.settings = {};
        // Custom Fields
        FakeRouteData.custom_fields = {};


        return FakeRouteData;
    }

    // Billing

    /**
     * Creates a fake Invoice based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object[]} UsersData The array of generated User records
     * @param {Object[]} AccountsData The array of generated Account records
     * @param {Object[]} RequestsData The array of generated Request records
     * @param {Object[]} EstimatesData The array of generated Estimate records
     * @param {Object[]} JobsData The array of generated Job records
     * @param {Object[]} InvoicesData The array of generated Invoice records
     * @param {Object} LedgersMap The object mapping of identifier to object of generated ledger records
     * 
     * @returns {Object} A single randomly generated ProHelper Invoice object given the inputs provided.
     */
    _CreateFakeInvoiceData(Index, Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, RequestsData, EstimatesData, JobsData, InvoicesData, LineItemsData, LedgersMap) {
        // Initalize local variables
        let FakeInvoiceData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const invoiceObjectFieldsMap = CustomizationsData.object_fields.invoices;
        const invoiceTypesMap = CustomizationsData.object_types.invoices;
        const invoiceTypesList = Object.values(invoiceTypesMap);
        const invoiceStatusesObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.status.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.status.dropdown_set].dropdowns;
        const invoiceStatusesList = Object.values(invoiceStatusesObjMap);
        const invoiceBillingReasonsObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.billing_reason.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.billing_reason.dropdown_set].dropdowns;
        const invoiceBillingReasonsList = Object.values(invoiceBillingReasonsObjMap);
        const invoiceSendMethodsObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.send_method.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.send_method.dropdown_set].dropdowns;
        const invoiceSendMethodsList = Object.values(invoiceSendMethodsObjMap);
        const invoiceTagsObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.tags.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.tags.dropdown_set].dropdowns;
        const invoiceTagsList = Object.values(invoiceTagsObjMap);
        const paymentMethodsObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.payment_method.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.payment_method.dropdown_set].dropdowns;
        const paymentMethodsList = Object.values(paymentMethodsObjMap);
        const invoiceItemActionsObjMap = CustomizationsData.actions.invoices.item_actions;
        const invoiceItemActionsList = Object.values(invoiceItemActionsObjMap);
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        const currenciesObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.currency.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.currency.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.language.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.language.dropdown_set].dropdowns;
        const countriesObjMap = CustomizationsData.dropdown_sets[invoiceObjectFieldsMap.country.dropdown_set_options.object_identifier][invoiceObjectFieldsMap.country.dropdown_set].dropdowns;


        //
        // Created At
        FakeInvoiceData.created_at = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate(), new Date());
        // Created By
        FakeInvoiceData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeInvoiceData.last_updated_at = this._GetRandomDate(FakeInvoiceData.created_at, new Date());
        // Last Updated By
        FakeInvoiceData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeInvoiceData.last_viewed_at = this._GetRandomDate(FakeInvoiceData.created_at, new Date());
        // Last Viewed By
        FakeInvoiceData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakeInvoiceData.last_activity_at = this._GetRandomDate(FakeInvoiceData.created_at, new Date());
        // Last Activity By
        FakeInvoiceData.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        //
        // Database ID
        FakeInvoiceData.id = uniqid(`db_`);
        // Company ID
        FakeInvoiceData.company = Auth.company.id;
        // Subject
        FakeInvoiceData.subject = FakeSecondaryData.jobLabelList[_.random(0, (FakeSecondaryData.jobLabelList.length - 1))];
        // Description
        FakeInvoiceData.description = (Math.random() < 0.65) ? FakeSecondaryData.workDescriptionList[_.random(0, (FakeSecondaryData.workDescriptionList.length - 1))] : null;
        // Type
        FakeInvoiceData.type = (Math.random() < 0.94) ? (invoiceTypesList[_.random(0, (invoiceTypesList.length - 1))]) : null;
        // Due Date
        FakeInvoiceData.due_date = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), moment(new Date()).add(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate());
        // Issued Date
        FakeInvoiceData.issued_date = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Account
        FakeInvoiceData.account = !_.isEmpty(AccountsData) && (Math.random() < 0.92) ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Primary Contact
        FakeInvoiceData.primary_contact = !_.isEmpty(FakeInvoiceData.account) ? FakeInvoiceData.account.primary_contact : null;
        // Primary Property
        FakeInvoiceData.primary_property = !_.isEmpty(FakeInvoiceData.account) ? FakeInvoiceData.account.primary_property : null;
        // Billing Contact
        FakeInvoiceData.billing_contact = !_.isEmpty(FakeInvoiceData.account) ? FakeInvoiceData.account.billing_contact : null;
        // Billing Property
        FakeInvoiceData.billing_property = !_.isEmpty(FakeInvoiceData.account) ? FakeInvoiceData.account.billing_property : null;
        // Service Contact
        FakeInvoiceData.service_contact = !_.isEmpty(FakeInvoiceData.account) ? FakeInvoiceData.account.service_contact : null;
        // Service Property
        FakeInvoiceData.service_property = !_.isEmpty(FakeInvoiceData.account) ? FakeInvoiceData.account.service_property : null;
        // Status
        FakeInvoiceData.status = invoiceStatusesList[_.random(0, (invoiceStatusesList.length - 1))];
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(invoiceTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = invoiceTagsList[_.random(0, (invoiceTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeInvoiceData.tags = Object.values(tagsObjectMapping);
        }
        // Invoice ID
        FakeInvoiceData.invoice_id = uniqid(`invoice_`);
        // Invoice Number
        FakeInvoiceData.invoice_number = Index;
        // Invoicer
        FakeInvoiceData.invoicer = (!_.isEmpty(UsersData) && (Math.random() < 0.78)) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Request
        FakeInvoiceData.request = (Math.random() < 0.84) ? RequestsData[_.random(0, RequestsData.length - 1)] : null;
        // Estimate
        FakeInvoiceData.estimate = (Math.random() < 0.84) ? EstimatesData[_.random(0, EstimatesData.length - 1)] : null;
        // Jobs
        const randomNumOfjobs = (_.random(0, 5));
        if (randomNumOfjobs > 0 && !_.isEmpty(JobsData)) {
            let jobsList = [];
            for (let i = 0; i < randomNumOfjobs; i++) {
                if (Math.random() < 0.84) {
                    jobsList.push(JobsData[_.random(0, JobsData.length - 1)]);
                }
            }
            FakeInvoiceData.jobs = jobsList;
        }
        // Subscription
        FakeInvoiceData.subscription = null;
        // Footer Message
        FakeInvoiceData.footer_message = FakeSecondaryData.footerMessageList[_.random(0, FakeSecondaryData.footerMessageList.length - 1)];
        // Customer Message 
        FakeInvoiceData.customer_message = '';
        // Customer Response
        FakeInvoiceData.customer_response = '';
        // Revenue Ledger
        FakeInvoiceData.revenue_ledger = !_.isEmpty(LedgersMap.sales_of_product_income) ? LedgersMap.sales_of_product_income : null;
        // Expense Ledger
        FakeInvoiceData.expense_ledger = !_.isEmpty(LedgersMap.billable_expense_income) ? LedgersMap.billable_expense_income : null;
        // Billing Reason
        FakeInvoiceData.billing_reason = (Math.random() < 0.75) ? invoiceBillingReasonsList[_.random(0, invoiceBillingReasonsList.length - 1)] : null;
        // Payment MethodLineItemsData
        FakeInvoiceData.payment_method = (Math.random() < 0.9) ? paymentMethodsList[_.random(0, paymentMethodsList.length - 1)] : null;
        // Send Method
        FakeInvoiceData.send_method = (Math.random() < 0.75) ? invoiceSendMethodsList[_.random(0, invoiceSendMethodsList.length - 1)] : null;
        // Auto Advance
        FakeInvoiceData.auto_advance = (Math.random() < 0.75);
        // Payment Source
        FakeInvoiceData.payment_source = null;
        // Tax ID
        FakeInvoiceData.tax_id = (Math.random() < 0.69) ? aes.encrypt(FakeSecondaryData.taxIDs[_.random(0, (FakeSecondaryData.taxIDs.length - 1))], Auth.company.secret_key).toString() : null;
        // Period Start
        FakeInvoiceData.period_start = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Period End
        FakeInvoiceData.period_end = moment(FakeInvoiceData.period_start).add(moment.duration(Math.floor(Math.random() * 30), 'days')).toDate();

        // Handle Line Items Generation
        FakeInvoiceData.line_items = [];
        const randomNumOfLineItems = _.random(15, 25);

        for (let i = 0; i < randomNumOfLineItems; i++) {
            const currLineItemRecord = this._CreateFakeLineItemData(i, Auth, PermissionsData, CustomizationsData, 'invoices', FakeInvoiceData, UsersData, LineItemsData, AccountsData);
            FakeInvoiceData.line_items.push(currLineItemRecord);
        }


        // Deposit
        FakeInvoiceData.deposit = bigInt(_.random(-10000, 99999)).toString();
        // Sub Total
        FakeInvoiceData.sub_total = bigInt(_.random(-10000, 99999)).toString();
        // Sub Total Excluding Tax
        FakeInvoiceData.sub_total_excluding_tax = bigInt(_.random(-10000, 99999)).toString();
        // Discounts
        FakeInvoiceData.discounts = null;
        // Discount Amount
        FakeInvoiceData.discount_amount = 0;
        // Is Taxable
        FakeInvoiceData.is_taxable = Math.random < 0.9;
        // Is Tax applied after Discount
        FakeInvoiceData.is_tax_applied_after_discounts = Math.random < 0.9 && FakeInvoiceData.is_taxable;
        // Tax Rates
        FakeInvoiceData.tax_rates = null;
        // Tax Amount   
        FakeInvoiceData.tax_amount = bigInt(_.random(-10000, 99999)).toString();
        // Total Excluding Tax
        FakeInvoiceData.total_excluding_tax = bigInt(_.random(-10000, 99999)).toString();
        // Total
        FakeInvoiceData.total = bigInt(_.random(-10000, 99999)).toString();
        // Amount Due
        FakeInvoiceData.amount_due = bigInt(_.random(-10000, 99999)).toString();
        // Amount Paid
        FakeInvoiceData.amount_paid = bigInt(_.random(-10000, 99999)).toString();
        // Amount Remaining
        FakeInvoiceData.amount_remaining = bigInt(_.random(-10000, 99999)).toString();
        // Starting Balance
        FakeInvoiceData.starting_balance = bigInt(_.random(-10000, 99999)).toString();
        // Ending Balance
        FakeInvoiceData.ending_balance = bigInt(_.random(-10000, 99999)).toString();
        // Payments
        FakeInvoiceData.payments = null;
        // Number of Attempts
        FakeInvoiceData.num_of_attempts = _.random(0, 3);
        // Next Payment Attempt

        // Statement Descriptor

        // Subscription Proration Date

        // Application Fee Amount

        // Primary Contact's Name
        FakeInvoiceData.primary_contact_name = !_.isEmpty(FakeInvoiceData.primary_contact) ? FakeInvoiceData.primary_contact.name : null;
        // Primary Contact's Phone
        FakeInvoiceData.primary_contact_phone = !_.isEmpty(FakeInvoiceData.primary_contact) ? FakeInvoiceData.primary_contact.phone : null;
        // Primary Contact's Email
        FakeInvoiceData.primary_contact_email = !_.isEmpty(FakeInvoiceData.primary_contact) ? FakeInvoiceData.primary_contact.email : null;
        // Primary Property's Address
        FakeInvoiceData.primary_property_address = !_.isEmpty(FakeInvoiceData.primary_contact) ? FakeInvoiceData.primary_contact.email : null;
        // Billing Contact's Name
        FakeInvoiceData.billing_contact_name = !_.isEmpty(FakeInvoiceData.billing_contact) ? FakeInvoiceData.billing_contact.name : null;
        // Billing Contact's Phone
        FakeInvoiceData.billing_contact_phone = !_.isEmpty(FakeInvoiceData.billing_contact) ? FakeInvoiceData.billing_contact.phone : null;
        // Billing Contact's Email
        FakeInvoiceData.billing_contact_phone = !_.isEmpty(FakeInvoiceData.billing_contact) ? FakeInvoiceData.billing_contact.phone : null;
        // Billing Property's Address
        FakeInvoiceData.billing_property_address = !_.isEmpty(FakeInvoiceData.billing_property) ? FakeInvoiceData.billing_property.address : null;
        // Service Contact's Name
        FakeInvoiceData.service_contact_name = !_.isEmpty(FakeInvoiceData.service_contact) ? FakeInvoiceData.service_contact.name : null;
        // Service Contact's Phone
        FakeInvoiceData.service_contact_phone = !_.isEmpty(FakeInvoiceData.service_contact) ? FakeInvoiceData.service_contact.phone : null;
        // Service Property's Address
        FakeInvoiceData.service_property_address = !_.isEmpty(FakeInvoiceData.service_property) ? FakeInvoiceData.service_property.address : null;
        // Is Active
        FakeInvoiceData.is_active = (Math.random() < 0.95);
        // Is Sent
        FakeInvoiceData.is_sent = (FakeInvoiceData.is_active && Math.random() < 0.7);
        // Is Attempted
        FakeInvoiceData.is_attempted = (FakeInvoiceData.is_active && FakeInvoiceData.is_sent && Math.random() < 0.85);
        // Is Paid
        FakeInvoiceData.is_attempted = (FakeInvoiceData.is_active && FakeInvoiceData.is_sent && FakeInvoiceData.is_attempted && Math.random() < 0.85);
        // Out of Band
        FakeInvoiceData.out_of_band = (Math.random() < 0.13);
        // Is On Hold
        FakeInvoiceData.is_on_hold = !FakeInvoiceData.is_active && (Math.random() < 0.6);
        // Is Closed
        FakeInvoiceData.is_closed = false;
        // Is Completed
        FakeInvoiceData.is_completed = false;
        // Is Archived
        FakeInvoiceData.is_archived = false;
        // Is Deleted
        FakeInvoiceData.is_deleted = false;
        // Is Locked
        FakeInvoiceData.is_locked = false;
        // Is Live
        FakeInvoiceData.is_live = true;
        // Is Draft
        FakeInvoiceData.is_draft = false;
        // Is Template
        FakeInvoiceData.is_template = false;
        // Is Sub Invoice
        FakeInvoiceData.is_sub_invoice = Math.random < 0.12;
        // Level
        FakeInvoiceData.level = FakeInvoiceData.is_sub_estimate ? 2 : 1;
        // Parent Invoice
        FakeInvoiceData.parent = FakeInvoiceData.is_sub_estimate ? InvoicesData[_.random(0, InvoicesData.length - 1)] : null;
        // Bill with Parent
        FakeInvoiceData.bill_with_parent = FakeInvoiceData.is_sub_invoice && Math.random < 0.4;
        // Currency
        FakeInvoiceData.currency = currenciesObjMap.usd;
        // Language
        FakeInvoiceData.language = languagesObjMap.en;
        // Time Zone
        FakeInvoiceData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Country
        FakeInvoiceData.country = countriesObjMap.us;
        // Hosted Invoice URL
        FakeInvoiceData.hosted_invoice_url = null;
        // Domain
        FakeInvoiceData.domain = 'pro';
        // Popup Alert
        FakeInvoiceData.popup_alert = (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]);
        //FakeInvoiceData.popup_alert = (Math.random() < 0.33 ) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;

        // Completed Alert
        FakeInvoiceData.completed_alert = null;
        // Closed Alert
        FakeInvoiceData.closed_alert = null;
        // Locked Alert
        FakeInvoiceData.locked_alert = null;
        // On Hold Alert
        FakeInvoiceData.on_hold_alert = null;
        // Archived Alert
        FakeInvoiceData.archived_alert = null;
        // Completed At
        FakeInvoiceData.completed_at = FakeInvoiceData.is_completed ? this._GetRandomDate(FakeInvoiceData.created_at, new Date()) : null;
        // Completed By
        FakeInvoiceData.completed_by = FakeInvoiceData.is_completed ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Closed At
        FakeInvoiceData.closed_at = FakeInvoiceData.is_closed ? this._GetRandomDate(FakeInvoiceData.created_at, new Date()) : null;
        // Closed By
        FakeInvoiceData.closed_by = FakeInvoiceData.is_closed ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Archived At
        FakeInvoiceData.archived_at = null;
        // Archived By
        FakeInvoiceData.archived_by = null;
        // Sync Token
        FakeInvoiceData.sync_token = 1;
        // Suggested Action
        FakeInvoiceData.suggested_action = (Math.random() < 0.26) ? (invoiceItemActionsList[_.random(1, (invoiceItemActionsList.length - 1))]) : null;
        // Notifications
        FakeInvoiceData.notifications = {};
        // Metadata
        FakeInvoiceData.metadata = {};
        // Payment Settings
        FakeInvoiceData.payment_settings = {};
        // Settings
        FakeInvoiceData.settings = {};
        // Custom Fields
        FakeInvoiceData.custom_fields = {};


        return FakeInvoiceData;
    }

    /**
     * Creates a fake Payment based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object[]} UsersData The array of generated User records
     * @param {Object[]} AccountsData The array of generated Account records
     * @param {Object[]} InvoicesData The array of generated Invoice records
     * @param {Object} LedgersMap The object mapping of identifier to object of generated ledger records
     * 
     * @returns {Object} A single randomly generated ProHelper Payment object given the inputs provided.
     */
    _CreateFakePaymentData(Index, Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, InvoicesData, LedgersMap) {
        // Initalize local variables
        let FakePaymentData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const paymentObjectFieldsMap = CustomizationsData.object_fields.payments;
        const paymentStatusesObjMap = CustomizationsData.dropdown_sets[paymentObjectFieldsMap.status.dropdown_set_options.object_identifier][paymentObjectFieldsMap.status.dropdown_set].dropdowns;
        const paymentStatusesList = Object.values(paymentStatusesObjMap);
        const paymentCaptureMethodsObjMap = CustomizationsData.dropdown_sets[paymentObjectFieldsMap.capture_method.dropdown_set_options.object_identifier][paymentObjectFieldsMap.capture_method.dropdown_set].dropdowns;
        const paymentCaptureMethodsList = Object.values(paymentCaptureMethodsObjMap);
        const paymentConfirmationMethodsObjMap = CustomizationsData.dropdown_sets[paymentObjectFieldsMap.confirmation_method.dropdown_set_options.object_identifier][paymentObjectFieldsMap.confirmation_method.dropdown_set].dropdowns;
        const paymentConfirmationMethodsList = Object.values(paymentConfirmationMethodsObjMap);
        const paymentSetupFutureUsagesObjMap = CustomizationsData.dropdown_sets[paymentObjectFieldsMap.setup_future_usage.dropdown_set_options.object_identifier][paymentObjectFieldsMap.setup_future_usage.dropdown_set].dropdowns;
        const paymentSetupFutureUsagesList = Object.values(paymentSetupFutureUsagesObjMap);
        const paymentMethodsObjMap = CustomizationsData.dropdown_sets[paymentObjectFieldsMap.payment_method.dropdown_set_options.object_identifier][paymentObjectFieldsMap.payment_method.dropdown_set].dropdowns;
        const paymentMethodsList = Object.values(paymentMethodsObjMap);
        const paymentItemActionsObjMap = CustomizationsData.actions.payments.item_actions;
        const paymentItemActionsList = Object.values(paymentItemActionsObjMap);
        const currenciesObjMap = CustomizationsData.dropdown_sets[paymentObjectFieldsMap.currency.dropdown_set_options.object_identifier][paymentObjectFieldsMap.currency.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[paymentObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][paymentObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[paymentObjectFieldsMap.language.dropdown_set_options.object_identifier][paymentObjectFieldsMap.language.dropdown_set].dropdowns;

        // Is Active
        FakePaymentData.is_active = Math.random < 0.95;
        // Is Attempted
        FakePaymentData.is_attempted = FakePaymentData.is_active && Math.random < 0.75;
        // Is Completed
        FakePaymentData.is_completed = FakePaymentData.is_attempted && Math.random < 0.75;
        // Is Canceled
        FakePaymentData.is_cancelled = Math.random < 0.25;

        // Database ID
        FakePaymentData.id = uniqid(`db_`);
        // Company ID
        FakePaymentData.company = Auth.company.id;
        // Subject
        FakePaymentData.subject = FakeSecondaryData.jobLabelList[_.random(0, (FakeSecondaryData.jobLabelList.length - 1))];
        // Description
        FakePaymentData.description = (Math.random() < 0.65) ? FakeSecondaryData.workDescriptionList[_.random(0, (FakeSecondaryData.workDescriptionList.length - 1))] : null;
        // Amount
        FakePaymentData.amount = bigInt(_.random(-10000, 99999)).toString();
        // Status
        FakePaymentData.status = paymentStatusesList[_.random(0, (paymentStatusesList.length - 1))];
        // Invoice
        FakePaymentData.invoice = (Math.random() < 0.95) ? InvoicesData[_.random(0, InvoicesData.length - 1)] : null;
        // Account
        FakePaymentData.account = (Math.random() < 0.95) ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Primary Contact
        FakePaymentData.primary_contact = !_.isEmpty(FakePaymentData.account) ? FakePaymentData.account.primary_contact : null;
        // Primary Property
        FakePaymentData.primary_property = !_.isEmpty(FakePaymentData.account) ? FakePaymentData.account.primary_property : null;
        // Due Date
        FakePaymentData.due_date = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), moment(new Date()).add(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate());
        // Issued Date
        FakePaymentData.issued_date = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Primary Contact's Name
        FakePaymentData.primary_contact_name = !_.isEmpty(FakePaymentData.primary_contact) ? FakePaymentData.primary_contact.name : null;
        // Primary Contact's Phone
        FakePaymentData.primary_contact_phone = !_.isEmpty(FakePaymentData.primary_contact) ? FakePaymentData.primary_contact.phone : null;
        // Primary Contact's Email
        FakePaymentData.primary_contact_email = !_.isEmpty(FakePaymentData.primary_contact) ? FakePaymentData.primary_contact.email : null;
        // Primary Property's Address
        FakePaymentData.primary_property_address = !_.isEmpty(FakePaymentData.primary_property) ? FakePaymentData.primary_property.address : null;
        // Receipt Email
        FakePaymentData.receipt_email = !_.isEmpty(FakePaymentData.primary_contact) ? FakePaymentData.primary_contact.email : null;
        // Payment ID
        FakePaymentData.payment_id = uniqid(`payment_`);
        // Payment Method
        FakePaymentData.payment_method = (Math.random() < 0.9) ? paymentMethodsList[_.random(0, paymentMethodsList.length - 1)] : null;
        // Payment Source
        FakePaymentData.payment_source = null;
        // Amount Details
        FakePaymentData.amount_details = { tip: 0, tax: 0 };
        // Amount Received
        FakePaymentData.amount_received = bigInt(_.random(-10000, 99999)).toString();
        // Capture Method
        FakePaymentData.capture_method = (Math.random() < 0.9) ? paymentCaptureMethodsList[_.random(0, paymentCaptureMethodsList.length - 1)] : null;
        // Confirmation Method
        FakePaymentData.confirmation_method = (Math.random() < 0.9) ? paymentConfirmationMethodsList[_.random(0, paymentConfirmationMethodsList.length - 1)] : null;
        // Setup Future Usage
        FakePaymentData.setup_future_usage = (Math.random() < 0.9) ? paymentSetupFutureUsagesList[_.random(0, paymentSetupFutureUsagesList.length - 1)] : null;
        // Completed At
        FakePaymentData.completed_at = FakePaymentData.is_completed ? this._GetRandomDate(FakePaymentData.created_at, new Date()) : null;
        // Completed By
        FakePaymentData.completed_by = FakePaymentData.is_completed ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Closed At
        FakePaymentData.closed_at = FakePaymentData.is_closed ? this._GetRandomDate(FakePaymentData.created_at, new Date()) : null;
        // Closed By
        FakePaymentData.closed_by = FakePaymentData.is_closed ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Cancellation Reason

        // Statement Descriptor
        FakePaymentData.statment_descriptor = '';
        // Statement Descriptor Suffix
        FakePaymentData.statment_descriptor_suffix = '';
        // Application Fee Amount
        FakePaymentData.application_fee = 0;
        // Revenue Ledger
        FakePaymentData.revenue_ledger = !_.isEmpty(LedgersMap.sales_of_product_income) ? LedgersMap.sales_of_product_income : null;
        // Expense Ledger
        FakePaymentData.expense_ledger = !_.isEmpty(LedgersMap.billable_expense_income) ? LedgersMap.billable_expense_income : null;


        // Is Locked
        FakePaymentData.is_locked = FakePaymentData.is_completed || Math.random < 0.05;
        // Is Archived
        FakePaymentData.is_archived = !FakePaymentData.is_active && Math.random < 0.5;
        // Is Deleted
        FakePaymentData.is_deleted = !FakePaymentData.is_active && !FakePaymentData.is_archived && Math.random < 0.5;
        // Is Live
        FakePaymentData.is_live = true;
        // Is Draft
        FakePaymentData.is_draft = false;
        // Payment Error Alert
        FakePaymentData.payment_error_alert = null;
        // Completed Alert
        FakePaymentData.completed_alert = null;
        // Canceled Alert
        FakePaymentData.canceled_alert = null;
        // Popup Alert
        FakePaymentData.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Archived Alert
        FakePaymentData.archived_alert = null;
        // Currency
        FakePaymentData.currency = currenciesObjMap.usd;
        // Language
        FakePaymentData.language = languagesObjMap.en;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakePaymentData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Country

        // Created At
        FakePaymentData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakePaymentData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakePaymentData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakePaymentData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakePaymentData.last_viewed_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Viewed By
        FakePaymentData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Archived At
        FakePaymentData.archived_at = null;
        // Archived By
        FakePaymentData.archived_by = null;
        // Hosted Invoice URL
        FakePaymentData.hosted_invoice_url = null;
        // Domain
        FakePaymentData.domain = 'pro';
        // Client Secret
        FakePaymentData.client_secret = uniqid("secret_");
        // Sync Token
        FakePaymentData.sync_token = 1;
        // Suggested Action
        FakePaymentData.suggested_action = (Math.random() < 0.26) ? (paymentItemActionsList[_.random(1, (paymentItemActionsList.length - 1))]) : null;
        // Notifications
        FakePaymentData.notifications = {};
        // Metadata
        FakePaymentData.metadata = {};
        // Settings
        FakePaymentData.settings = {};
        // Custom Fields
        FakePaymentData.custom_fields = {};


        return FakePaymentData;
    }

    /**
     * Creates a fake Item based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object[]} UsersData The array of generated User records
     * @param {Object[]} AccountsData The array of generated Account records
     * @param {Object[]} ItemsData The array of generated Items records
     * @param {Object} LedgersMap The object mapping of identifier to object of generated ledger records
     * 
     * @returns {Object} A single randomly generated ProHelper Payment object given the inputs provided.
     */
    _CreateFakeItemData(Index, Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, ItemsData, LedgersMap) {
        // Initalize local variables
        let FakeItemData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const itemObjectFieldsMap = CustomizationsData.object_fields.items;
        const itemTypesMap = CustomizationsData.object_types.items;
        const itemTypesList = Object.values(itemTypesMap);
        const itemTagsObjMap = CustomizationsData.dropdown_sets[itemObjectFieldsMap.tags.dropdown_set_options.object_identifier][itemObjectFieldsMap.tags.dropdown_set].dropdowns;
        const itemTagsList = Object.values(itemTagsObjMap);
        const itemItemActionsObjMap = CustomizationsData.actions.items.item_actions;
        const itemItemActionsList = Object.values(itemItemActionsObjMap);
        const currenciesObjMap = CustomizationsData.dropdown_sets[itemObjectFieldsMap.currency.dropdown_set_options.object_identifier][itemObjectFieldsMap.currency.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[itemObjectFieldsMap.language.dropdown_set_options.object_identifier][itemObjectFieldsMap.language.dropdown_set].dropdowns;


        // Database ID
        FakeItemData.id = uniqid(`db_`);
        // Company ID
        FakeItemData.company = Auth.company.id;
        // Label
        FakeItemData.label = FakeSecondaryData.jobLabelList[_.random(0, (FakeSecondaryData.jobLabelList.length - 1))];
        // Short Label
        FakeItemData.short_label = FakeSecondaryData.jobLabelList[_.random(0, (FakeSecondaryData.jobLabelList.length - 1))];
        // Description
        FakeItemData.description = FakeSecondaryData.workDescriptionList[_.random(0, (FakeSecondaryData.workDescriptionList.length - 1))];
        // Default Price
        FakeItemData.default_price = null;
        // Images
        FakeItemData.images = null;
        // Type
        FakeItemData.type = itemTypesList[_.random(0, itemTypesList.length - 1)];
        // Sub Type
        FakeItemData.sub_type = null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(itemTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = itemTagsList[_.random(0, (itemTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeItemData.tags = Object.values(tagsObjectMapping);
        }
        // Item ID
        FakeItemData.item_id = uniqid(`item_`);
        // Preferred Vendor
        FakeItemData.preferred_vendor = !_.isEmpty(AccountsData) ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Default Quantity
        FakeItemData.default_quantity = _.random(0, 100);
        // Track Quantity on Hand
        FakeItemData.track_quantity_on_hand = Math.random < 0.12;
        // Quantity on Hand
        FakeItemData.quantity_on_hand = FakeItemData.track_quantity_on_hand ? _.random(0, 100) : null;
        // Is Quantity Editable
        FakeItemData.is_quantity_editable = true;
        // Inventory Start Date
        FakeItemData.inventory_start_date = FakeItemData.track_quantity_on_hand ? this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date()) : null;
        // Unit Label
        FakeItemData.unit_label = "Bags";
        // SKU

        // Barcode Photo

        // QR Code Photo

        // Asset Ledger
        FakeItemData.asset_ledger = !_.isEmpty(LedgersMap.inventory_asset) ? LedgersMap.inventory_asset : null;
        // Inventory Ledger
        FakeItemData.inventory_ledger = null;
        // Revenue Ledger
        FakeItemData.revenue_ledger = !_.isEmpty(LedgersMap.sales_of_product_income) ? LedgersMap.sales_of_product_income : null;
        // Expense Ledger
        FakeItemData.expense_ledger = !_.isEmpty(LedgersMap.billable_expense_income) ? LedgersMap.billable_expense_income : null;
        // Statement Descriptor
        FakeItemData.statment_descriptor = '';
        // Is Taxable
        FakeItemData.is_taxable = true;
        // Is Sales Tax Included
        FakeItemData.is_sales_tax_included = true;
        // Is Shippable
        FakeItemData.is_shipable = true;
        // Is Service
        FakeItemData.is_service = Math.random < 0.5;
        // Is Active
        FakeItemData.is_active = true;
        // Is Archived
        FakeItemData.is_archived = false;
        // Is Deleted
        FakeItemData.is_deleted = false;
        // Is Live
        FakeItemData.is_live = true;
        // Is Draft
        FakeItemData.is_draft = false;
        // Is Template 
        FakeItemData.is_template = false;
        // Is Sub Item
        FakeItemData.is_sub_item = Math.random < 0.1;
        // Level
        FakeItemData.level = FakeItemData.is_sub_item ? 2 : 1;
        // Parent Item
        FakeItemData.parent = FakeItemData.is_sub_item ? ItemsData[_.random(0, ItemsData.length - 1)] : null;
        // Created At
        FakeItemData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeItemData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeItemData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeItemData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Sold At
        FakeItemData.last_sold_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Currency
        FakeItemData.currency = currenciesObjMap.usd;
        // Language
        FakeItemData.language = languagesObjMap.en;
        // Popup Alert
        FakeItemData.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Archived Alert
        FakeItemData.archived_alert = null;
        // Archived At
        FakeItemData.archived_at = null;
        // Archived By
        FakeItemData.archived_by = null;
        // Hosted Invoice URL
        FakeItemData.hosted_invoice_url = '';
        // Domain
        FakeItemData.domain = 'pro';
        // Sync Token
        FakeItemData.sync_token = 1;
        // Suggested Action
        FakeItemData.suggested_action = (Math.random() < 0.26) ? (itemItemActionsList[_.random(1, (itemItemActionsList.length - 1))]) : null;
        // Notifications
        FakeItemData.notifications = {};
        // Metadata
        FakeItemData.metadata = {};
        // Settings
        FakeItemData.settings = {};
        // Custom Fields
        FakeItemData.custom_fields = {};

        return FakeItemData;
    }

    /**
     * Creates a fake Subscription based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object[]} UsersData The array of generated User records
     * @param {Object[]} AccountsData The array of generated Account records
     * @param {Object[]} InvoicesData The array of generated Invoices records
     * @param {Object[]} PaymentsData The array of generated Payments records
     * @param {Object[]} ItemsData The array of generated Items records
     * @param {Object} LedgersMap The object mapping of identifier to object of generated ledger records
     * 
     * @returns {Object} A single randomly generated ProHelper Subscription object given the inputs provided.
     */
    _CreateFakeSubscriptionData(Index, Auth, PermissionsData, CustomizationsData, UsersData, AccountsData, InvoicesData, PaymentsData, LineItemsData, LedgersMap) {
        // Initalize local variables
        let FakeSubscriptionData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const subscriptionObjectFieldsMap = CustomizationsData.object_fields.subscriptions;
        const subscriptionStatusesObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.status.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.status.dropdown_set].dropdowns;
        const subscriptionStatusesList = Object.values(subscriptionStatusesObjMap);
        const subscriptionCollectionMethodsObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.collection_method.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.collection_method.dropdown_set].dropdowns;
        const subscriptionCollectionMethodsList = Object.values(subscriptionCollectionMethodsObjMap);
        const subscriptionTagsObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.tags.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.tags.dropdown_set].dropdowns;
        const subscriptionTagsList = Object.values(subscriptionTagsObjMap);
        const paymentMethodsObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.payment_method.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.payment_method.dropdown_set].dropdowns;
        const paymentMethodsList = Object.values(paymentMethodsObjMap);
        const subscriptionCollectionObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.tags.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.tags.dropdown_set].dropdowns;
        const subscriptionCollectionList = Object.values(subscriptionCollectionObjMap);
        const subscriptionItemActionsObjMap = CustomizationsData.actions.subscriptions.item_actions;
        const subscriptionItemActionsList = Object.values(subscriptionItemActionsObjMap);
        const currenciesObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.currency.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.currency.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.language.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.language.dropdown_set].dropdowns;
        const countriesObjMap = CustomizationsData.dropdown_sets[subscriptionObjectFieldsMap.country.dropdown_set_options.object_identifier][subscriptionObjectFieldsMap.country.dropdown_set].dropdowns;


        // Database ID
        FakeSubscriptionData.id = uniqid(`db_`);
        // Company ID
        FakeSubscriptionData.company = Auth.company.id;
        // Subject
        FakeSubscriptionData.subject = FakeSecondaryData.jobLabelList[_.random(0, (FakeSecondaryData.jobLabelList.length - 1))];
        // Description
        FakeSubscriptionData.description = (Math.random() < 0.65) ? FakeSecondaryData.workDescriptionList[_.random(0, (FakeSecondaryData.workDescriptionList.length - 1))] : null;
        // Status
        FakeSubscriptionData.status = subscriptionStatusesList[_.random(0, (subscriptionStatusesList.length - 1))];
        // Account
        FakeSubscriptionData.account = Math.random < 0.95 ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Primary Contact
        FakeSubscriptionData.primary_contact = !_.isEmpty(FakeSubscriptionData.account) ? FakeSubscriptionData.account.primary_contact : null;
        // Primary Property
        FakeSubscriptionData.primary_property = !_.isEmpty(FakeSubscriptionData.account) ? FakeSubscriptionData.account.primary_property : null;
        // Billing Contact
        FakeSubscriptionData.billing_contact = !_.isEmpty(FakeSubscriptionData.account) ? FakeSubscriptionData.account.billing_contact ? FakeSubscriptionData.account.billing_contact : FakeSubscriptionData.primary_contact : null;
        // Billing Property
        FakeSubscriptionData.billing_property = !_.isEmpty(FakeSubscriptionData.account) ? FakeSubscriptionData.account.billing_property ? FakeSubscriptionData.account.billing_property : FakeSubscriptionData.primary_property : null;
        // Service Contact
        FakeSubscriptionData.service_contact = !_.isEmpty(FakeSubscriptionData.account) ? FakeSubscriptionData.account.service_contact ? FakeSubscriptionData.account.service_contact : FakeSubscriptionData.primary_contact : null;
        // Service Property
        FakeSubscriptionData.service_property = !_.isEmpty(FakeSubscriptionData.account) ? FakeSubscriptionData.account.service_property ? FakeSubscriptionData.account.service_property : FakeSubscriptionData.primary_property : null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(subscriptionTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = subscriptionTagsList[_.random(0, (subscriptionTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeSubscriptionData.tags = Object.values(tagsObjectMapping);
        }
        // Subscription ID
        FakeSubscriptionData.subscription_id = uniqid(`sub_`);
        // Subscription Number
        FakeSubscriptionData.subscription_number = Index;
        // Payment Method
        FakeSubscriptionData.payment_method = (Math.random() < 0.9) ? paymentMethodsList[_.random(0, paymentMethodsList.length - 1)] : null;
        // Default Payment Source
        FakeSubscriptionData.default_payment_source = null;
        // Pending Payment
        FakeSubscriptionData.pending_payment = Math.random < 0.95 ? PaymentsData[_.random(0, PaymentsData.length - 1)] : null;
        // Latest Invoice
        FakeSubscriptionData.latest_invoice = Math.random < 0.95 ? InvoicesData[_.random(0, InvoicesData.length - 1)] : null;
        // Collection Method
        FakeSubscriptionData.collection_method = (Math.random() < 0.9) ? subscriptionCollectionMethodsList[_.random(0, subscriptionCollectionMethodsList.length - 1)] : null;
        // Days Until Due
        FakeSubscriptionData.days_until_due = _.random(1, 30);
        // Discounts
        FakeSubscriptionData.discounts = null;
        // Tax Rates
        FakeSubscriptionData.tax_rates = null;
        // Trial Start
        FakeSubscriptionData.trial_start = null;
        // Trial End
        FakeSubscriptionData.trial_end = null;
        // Billing Cycle Anchor
        FakeSubscriptionData.billing_cycle_anchor = null;
        // Application Fee Amount
        FakeSubscriptionData.application_fee_amount = 0;
        // Handle Line Items Generation
        FakeSubscriptionData.line_items = [];
        const randomNumOfLineItems = _.random(1, 5);

        for (let i = 0; i < randomNumOfLineItems; i++) {
            const currLineItemRecord = this._CreateFakeLineItemData(i, Auth, PermissionsData, CustomizationsData, 'subscriptions', FakeSubscriptionData, UsersData, LineItemsData, AccountsData);
            FakeSubscriptionData.line_items.push(currLineItemRecord);
        }
        // Revenue Ledger
        FakeSubscriptionData.revenue_ledger = !_.isEmpty(LedgersMap.sales_of_product_income) ? LedgersMap.sales_of_product_income : null;
        // Expense Ledger
        FakeSubscriptionData.expense_ledger = !_.isEmpty(LedgersMap.billable_expense_income) ? LedgersMap.billable_expense_income : null;
        // Started At
        FakeSubscriptionData.started_at = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 30), 'days')).toDate(), new Date());
        // Current Period Start
        FakeSubscriptionData.started_at = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 30), 'days')).toDate(), new Date());
        // Current Period End
        FakeSubscriptionData.started_at = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 30), 'days')).toDate(), new Date());
        // Cancel At
        FakeSubscriptionData.canceled_at = null;
        // Ended At
        FakeSubscriptionData.ended_at = null;
        // Recurrence Schedule
        FakeSubscriptionData.recurrence_schedule = null;
        // Number of Invoices
        FakeSubscriptionData.num_of_invoices = _.random(1, 15);
        // Number of Payments
        FakeSubscriptionData.num_of_invoices = _.random(0, FakeSubscriptionData.num_of_invoices);
        // Number of Times Past Due 30
        FakeSubscriptionData.num_of_times_past_due_30 = _.random(1, 15);
        // Number of Times Past Due 60
        FakeSubscriptionData.num_of_times_past_due_60 = _.random(1, 15);
        // Number of Times Past Due 90
        FakeSubscriptionData.num_of_times_past_due_90 = _.random(1, 15);
        // Primary Contact's Name
        FakeSubscriptionData.primary_contact_name = !_.isEmpty(FakeSubscriptionData.primary_contact) ? FakeSubscriptionData.primary_contact.name : null;
        // Primary Contact's Phone
        FakeSubscriptionData.primary_contact_phone = !_.isEmpty(FakeSubscriptionData.primary_contact) ? FakeSubscriptionData.primary_contact.phone : null;
        // Primary Contact's Email
        FakeSubscriptionData.primary_contact_email = !_.isEmpty(FakeSubscriptionData.primary_contact) ? FakeSubscriptionData.primary_contact.email : null;
        // Primary Property's Address
        FakeSubscriptionData.primary_property_address = !_.isEmpty(FakeSubscriptionData.primary_property) ? FakeSubscriptionData.primary_property.address : null;
        // Billing Contact's Name
        FakeSubscriptionData.billing_contact_name = !_.isEmpty(FakeSubscriptionData.billing_contact) ? FakeSubscriptionData.billing_contact.name : null;
        // Billing Contact's Phone
        FakeSubscriptionData.billing_contact_phone = !_.isEmpty(FakeSubscriptionData.billing_contact) ? FakeSubscriptionData.billing_contact.phone : null;
        // Billing Contact's Email
        FakeSubscriptionData.billing_contact_phone = !_.isEmpty(FakeSubscriptionData.billing_contact) ? FakeSubscriptionData.billing_contact.phone : null;
        // Billing Property's Address
        FakeSubscriptionData.billing_property_address = !_.isEmpty(FakeSubscriptionData.billing_property) ? FakeSubscriptionData.billing_property.address : null;
        // Service Contact's Name
        FakeSubscriptionData.service_contact_name = !_.isEmpty(FakeSubscriptionData.service_contact) ? FakeSubscriptionData.service_contact.name : null;
        // Service Contact's Phone
        FakeSubscriptionData.service_contact_phone = !_.isEmpty(FakeSubscriptionData.service_contact) ? FakeSubscriptionData.service_contact.phone : null;
        // Service Contact's Email
        FakeSubscriptionData.service_contact_email = !_.isEmpty(FakeSubscriptionData.service_contact) ? FakeSubscriptionData.service_contact.email : null;
        // Service Property's Address
        FakeSubscriptionData.service_property_address = !_.isEmpty(FakeSubscriptionData.service_property) ? FakeSubscriptionData.service_property.address : null;
        // Is Trialing
        FakeSubscriptionData.is_trailing = Math.random < 0.1;
        // Is Taxable
        FakeSubscriptionData.is_taxable = Math.random < 0.9;
        // Is Tax Applied After Discount
        FakeSubscriptionData.is_tax_applied_after_discount = Math.random < 0.9;
        // Is Active
        FakeSubscriptionData.is_active = true;
        // Is On Hold
        FakeSubscriptionData.is_on_hold = false;
        // Is Archived
        FakeSubscriptionData.is_archived = false;
        // Is Deleted
        FakeSubscriptionData.is_deleted = false;
        // Is Draft
        FakeSubscriptionData.is_draft = false;
        // Is Template
        FakeSubscriptionData.is_template = false;
        // Is Live
        FakeSubscriptionData.is_live = true;
        // Currency
        FakeSubscriptionData.currency = currenciesObjMap.usd;
        // Language
        FakeSubscriptionData.language = languagesObjMap.en;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeSubscriptionData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Country
        FakeSubscriptionData.country = countriesObjMap.us;
        // Domain
        FakeSubscriptionData.domain = 'pro';
        // Popup Alert
        FakeSubscriptionData.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // On Hold Alert
        FakeSubscriptionData.on_hold_alert = null;
        // Canceled Alert
        FakeSubscriptionData.canceled_alert = null;
        // Created At
        FakeSubscriptionData.created_at = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Created By
        FakeSubscriptionData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeSubscriptionData.last_updated_at = this._GetRandomDate(FakeSubscriptionData.created_at, new Date());
        // Last Updated By
        FakeSubscriptionData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakeSubscriptionData.last_viewed_at = this._GetRandomDate(FakeSubscriptionData.created_at, new Date());
        // Last Viewed By
        FakeSubscriptionData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakeSubscriptionData.last_activity_at = this._GetRandomDate(FakeSubscriptionData.created_at, new Date());
        // Last Activity By
        FakeSubscriptionData.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Archived Alert
        FakeSubscriptionData.archived_alert = null;
        // Archived At
        FakeSubscriptionData.archived_at = null;
        // Archived By
        FakeSubscriptionData.archived_by = null;
        // Sync Token
        FakeSubscriptionData.sync_token = 1;
        // Suggested Action
        FakeSubscriptionData.suggested_action = (Math.random() < 0.26) ? (subscriptionItemActionsList[_.random(1, (subscriptionItemActionsList.length - 1))]) : null;
        // Notifications
        FakeSubscriptionData.notifications = {};
        // Metadata
        FakeSubscriptionData.metadata = {};
        // Payment Settings
        FakeSubscriptionData.payment_settings = {};
        // Settings
        FakeSubscriptionData.settings = {};
        // Custom Fields
        FakeSubscriptionData.custom_fields = {};


        return FakeSubscriptionData;
    }

    _CreateFakePurchaseOrderData(Index, Auth, PermissionsData, CustomizationsData, PurchaseOrdersData, AccountsData, UsersData, RequestsData, EstimatesData, InvoicesData, JobsData, ItemsData, LedgersMap) {
        // Initalize local variables
        let FakePurchaseOrderData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const purchaseOrderObjectFieldsMap = CustomizationsData.object_fields.purchase_orders;
        const purchaseOrderTypesMap = CustomizationsData.object_types.purchase_orders;
        const purchaseOrderTypesList = Object.values(purchaseOrderTypesMap);
        const purchaseOrderStatusesObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.status.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.status.dropdown_set].dropdowns;
        const purchaseOrderStatusesList = Object.values(purchaseOrderStatusesObjMap);
        const purchaseOrderBillingReasonsObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.billing_reason.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.billing_reason.dropdown_set].dropdowns;
        const purchaseOrderBillingReasonsList = Object.values(purchaseOrderBillingReasonsObjMap);
        const purchaseOrderDeliveryMethodsObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.delivery_method.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.delivery_method.dropdown_set].dropdowns;
        const purchaseOrderDeliveryMethodsList = Object.values(purchaseOrderDeliveryMethodsObjMap);
        const purchaseOrderTagsObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.tags.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.tags.dropdown_set].dropdowns;
        const purchaseOrderTagsList = Object.values(purchaseOrderTagsObjMap);
        const paymentMethodsObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.payment_method.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.payment_method.dropdown_set].dropdowns;
        const paymentMethodsList = Object.values(paymentMethodsObjMap);
        const purchaseOrderItemActionsObjMap = CustomizationsData.actions.purchase_orders.item_actions;
        const purchaseOrderItemActionsList = Object.values(purchaseOrderItemActionsObjMap);

        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        const currenciesObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.currency.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.currency.dropdown_set].dropdowns;
        const timeZonesObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.language.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.language.dropdown_set].dropdowns;
        const countriesObjMap = CustomizationsData.dropdown_sets[purchaseOrderObjectFieldsMap.country.dropdown_set_options.object_identifier][purchaseOrderObjectFieldsMap.country.dropdown_set].dropdowns;


        //
        
        //
        // Database ID
        FakePurchaseOrderData.id = uniqid(`db_`);
        // Company ID
        FakePurchaseOrderData.company = Auth.company.id;
        // Subject
        FakePurchaseOrderData.subject = FakeSecondaryData.jobLabelList[_.random(0, (FakeSecondaryData.jobLabelList.length - 1))];
        // Description
        FakePurchaseOrderData.description = (Math.random() < 0.65) ? FakeSecondaryData.workDescriptionList[_.random(0, (FakeSecondaryData.workDescriptionList.length - 1))] : null;
        // Type
        FakePurchaseOrderData.type = purchaseOrderTypesList[_.random(0, (purchaseOrderTypesList.length - 1))];
        // Status
        FakePurchaseOrderData.status = purchaseOrderStatusesList[_.random(0, (purchaseOrderStatusesList.length - 1))];
        // Due Date
        FakePurchaseOrderData.due_date = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), moment(new Date()).add(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate());
        // Issued Date
        FakePurchaseOrderData.issued_date = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Vendor
        FakePurchaseOrderData.vendor = !_.isEmpty(AccountsData) && (Math.random() < 0.92) ? AccountsData[_.random(0, AccountsData.length - 1)] : null;
        // Primary Contact
        FakePurchaseOrderData.primary_contact = !_.isEmpty(FakePurchaseOrderData.account) ? FakePurchaseOrderData.account.primary_contact : null;
        // Primary Property
        FakePurchaseOrderData.primary_property = !_.isEmpty(FakePurchaseOrderData.account) ? FakePurchaseOrderData.account.primary_property : null;
        // Billing Contact
        FakePurchaseOrderData.billing_contact = !_.isEmpty(FakePurchaseOrderData.account) ? FakePurchaseOrderData.account.billing_contact : null;
        // Billing Property
        FakePurchaseOrderData.billing_property = !_.isEmpty(FakePurchaseOrderData.account) ? FakePurchaseOrderData.account.billing_property : null;
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(purchaseOrderTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = purchaseOrderTagsList[_.random(0, (purchaseOrderTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakePurchaseOrderData.tags = Object.values(tagsObjectMapping);
        }
        // Invoice ID
        FakePurchaseOrderData.purchase_order_id = uniqid(`po_`);
        // Invoice Number
        FakePurchaseOrderData.purchase_order_number = Index;
        // Invoicer
        FakePurchaseOrderData.purchase_orderer = (!_.isEmpty(UsersData) && (Math.random() < 0.78)) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Request
        FakePurchaseOrderData.request = (Math.random() < 0.84) ? RequestsData[_.random(0, RequestsData.length - 1)] : null;
        // Estimate
        FakePurchaseOrderData.estimate = (Math.random() < 0.84) ? EstimatesData[_.random(0, EstimatesData.length - 1)] : null;
        // Invoice
        FakePurchaseOrderData.invoice = (Math.random() < 0.84) ? InvoicesData[_.random(0, InvoicesData.length - 1)] : null;
        // Jobs
        FakePurchaseOrderData.job = (Math.random() < 0.84) ? JobsData[_.random(0, JobsData.length - 1)] : null;
        // Expense Ledger
        FakePurchaseOrderData.expense_ledger = !_.isEmpty(LedgersMap.billable_expense_income) ? LedgersMap.billable_expense_income : null;
        // Billing Reason
        FakePurchaseOrderData.billing_reason = (Math.random() < 0.75) ? purchaseOrderBillingReasonsList[_.random(0, purchaseOrderBillingReasonsList.length - 1)] : null;
        // Payment Method
        FakePurchaseOrderData.payment_method = (Math.random() < 0.9) ? paymentMethodsList[_.random(0, paymentMethodsList.length - 1)] : null;
        // Delivery Method
        FakePurchaseOrderData.delivery_method = (Math.random() < 0.75) ? purchaseOrderDeliveryMethodsList[_.random(0, purchaseOrderDeliveryMethodsList.length - 1)] : null;
        // Period Start
        FakePurchaseOrderData.period_start = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate(), new Date());
        // Period End
        FakePurchaseOrderData.period_end = moment(FakePurchaseOrderData.period_start).add(moment.duration(Math.floor(Math.random() * 30), 'days')).toDate();

        // Handle Line Items Generation
        FakePurchaseOrderData.line_items = [];
        const randomNumOfLineItems = _.random(1, 6);

        for (let i = 0; i < randomNumOfLineItems; i++) {
            const currLineItemRecord = this._CreateFakeLineItemData(i, Auth, PermissionsData, CustomizationsData, 'purchase_orders', FakePurchaseOrderData, UsersData, ItemsData, AccountsData);
            FakePurchaseOrderData.line_items.push(currLineItemRecord);
        }


        // Deposit
        FakePurchaseOrderData.deposit = bigInt(_.random(-10000, 99999)).toString();
        // Sub Total
        FakePurchaseOrderData.sub_total = bigInt(_.random(-10000, 99999)).toString();
        // Sub Total Excluding Tax
        FakePurchaseOrderData.sub_total_excluding_tax = bigInt(_.random(-10000, 99999)).toString();
        // Discounts
        FakePurchaseOrderData.discounts = null;
        // Discount Amount
        FakePurchaseOrderData.discount_amount = 0;
        // Is Taxable
        FakePurchaseOrderData.is_taxable = Math.random < 0.9;
        // Is Tax applied after Discount
        FakePurchaseOrderData.is_tax_applied_after_discounts = Math.random < 0.9 && FakePurchaseOrderData.is_taxable;
        // Tax Rates
        FakePurchaseOrderData.tax_rates = null;
        // Tax Amount   
        FakePurchaseOrderData.tax_amount = bigInt(_.random(-10000, 99999)).toString();
        // Total Excluding Tax
        FakePurchaseOrderData.total_excluding_tax = bigInt(_.random(-10000, 99999)).toString();
        // Total
        FakePurchaseOrderData.total = bigInt(_.random(-10000, 99999)).toString();
        // Amount Due
        FakePurchaseOrderData.amount_due = bigInt(_.random(-10000, 99999)).toString();
        // Amount Paid
        FakePurchaseOrderData.amount_paid = bigInt(_.random(-10000, 99999)).toString();
        // Amount Remaining
        FakePurchaseOrderData.amount_remaining = bigInt(_.random(-10000, 99999)).toString();
        // Starting Balance
        FakePurchaseOrderData.starting_balance = bigInt(_.random(-10000, 99999)).toString();
        // Ending Balance
        FakePurchaseOrderData.ending_balance = bigInt(_.random(-10000, 99999)).toString();
        // Statement Descriptor

        // Subscription Proration Date

        // Primary Contact's Name
        FakePurchaseOrderData.primary_contact_name = !_.isEmpty(FakePurchaseOrderData.primary_contact) ? FakePurchaseOrderData.primary_contact.name : null;
        // Primary Contact's Phone
        FakePurchaseOrderData.primary_contact_phone = !_.isEmpty(FakePurchaseOrderData.primary_contact) ? FakePurchaseOrderData.primary_contact.phone : null;
        // Primary Contact's Email
        FakePurchaseOrderData.primary_contact_email = !_.isEmpty(FakePurchaseOrderData.primary_contact) ? FakePurchaseOrderData.primary_contact.email : null;
        // Primary Property's Address
        FakePurchaseOrderData.primary_property_address = !_.isEmpty(FakePurchaseOrderData.primary_contact) ? FakePurchaseOrderData.primary_contact.email : null;
        // Billing Contact's Name
        FakePurchaseOrderData.billing_contact_name = !_.isEmpty(FakePurchaseOrderData.billing_contact) ? FakePurchaseOrderData.billing_contact.name : null;
        // Billing Contact's Phone
        FakePurchaseOrderData.billing_contact_phone = !_.isEmpty(FakePurchaseOrderData.billing_contact) ? FakePurchaseOrderData.billing_contact.phone : null;
        // Billing Contact's Email
        FakePurchaseOrderData.billing_contact_phone = !_.isEmpty(FakePurchaseOrderData.billing_contact) ? FakePurchaseOrderData.billing_contact.phone : null;
        // Billing Property's Address
        FakePurchaseOrderData.billing_property_address = !_.isEmpty(FakePurchaseOrderData.billing_property) ? FakePurchaseOrderData.billing_property.address : null;
        // Out of Band
        FakePurchaseOrderData.out_of_band = (Math.random() < 0.13);
        // Is Active
        FakePurchaseOrderData.is_active = (Math.random() < 0.95);
        // Is Paid
        FakePurchaseOrderData.is_attempted = (FakePurchaseOrderData.is_active && FakePurchaseOrderData.is_sent && FakePurchaseOrderData.is_attempted && Math.random() < 0.85);
        // Is On Hold
        FakePurchaseOrderData.is_on_hold = !FakePurchaseOrderData.is_active && (Math.random() < 0.6);
        // Is Closed
        FakePurchaseOrderData.is_closed = false;
        // Is Completed
        FakePurchaseOrderData.is_completed = false;
        // Is Archived
        FakePurchaseOrderData.is_archived = false;
        // Is Deleted
        FakePurchaseOrderData.is_deleted = false;
        // Is Locked
        FakePurchaseOrderData.is_locked = false;
        // Is Live
        FakePurchaseOrderData.is_live = true;
        // Is Draft
        FakePurchaseOrderData.is_draft = false;
        // Is Template
        FakePurchaseOrderData.is_template = false;
        // Is Sub Purchase Order
        FakePurchaseOrderData.is_sub_purchase_order = Math.random < 0.12;
        // Level
        FakePurchaseOrderData.level = FakePurchaseOrderData.is_sub_estimate ? 2 : 1;
        // Parent Purchase Order
        FakePurchaseOrderData.parent = FakePurchaseOrderData.is_sub_estimate ? PurchaseOrdersData[_.random(0, PurchaseOrdersData.length - 1)] : null;
        // Bill with Parent
        FakePurchaseOrderData.bill_with_parent = FakePurchaseOrderData.is_sub_purchase_order && Math.random < 0.4;
        // Currency
        FakePurchaseOrderData.currency = currenciesObjMap.usd;
        // Language
        FakePurchaseOrderData.language = languagesObjMap.en;
        // Time Zone
        FakePurchaseOrderData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Country
        FakePurchaseOrderData.country = countriesObjMap.us;
        // Hosted Purchase Order URL
        FakePurchaseOrderData.hosted_purchase_order_url = null;
        // Domain
        FakePurchaseOrderData.domain = 'pro';
        // Popup Alert
        FakePurchaseOrderData.popup_alert = (Math.random() < 0.63 ) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Created At
        FakePurchaseOrderData.created_at = this._GetRandomDate(moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate(), new Date());
        // Created By
        FakePurchaseOrderData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakePurchaseOrderData.last_updated_at = this._GetRandomDate(FakePurchaseOrderData.created_at, new Date());
        // Last Updated By
        FakePurchaseOrderData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Viewed At
        FakePurchaseOrderData.last_viewed_at = this._GetRandomDate(FakePurchaseOrderData.created_at, new Date());
        // Last Viewed By
        FakePurchaseOrderData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Activity At
        FakePurchaseOrderData.last_activity_at = this._GetRandomDate(FakePurchaseOrderData.created_at, new Date());
        // Last Activity By
        FakePurchaseOrderData.last_activity_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Completed Alert
        FakePurchaseOrderData.completed_alert = null;
        // Closed Alert
        FakePurchaseOrderData.closed_alert = null;
        // Locked Alert
        FakePurchaseOrderData.locked_alert = null;
        // On Hold Alert
        FakePurchaseOrderData.on_hold_alert = null;
        // Archived Alert
        FakePurchaseOrderData.archived_alert = null;
        // Completed At
        FakePurchaseOrderData.completed_at = FakePurchaseOrderData.is_completed ? this._GetRandomDate(FakePurchaseOrderData.created_at, new Date()) : null;
        // Completed By
        FakePurchaseOrderData.completed_by = FakePurchaseOrderData.is_completed ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Closed At
        FakePurchaseOrderData.closed_at = FakePurchaseOrderData.is_closed ? this._GetRandomDate(FakePurchaseOrderData.created_at, new Date()) : null;
        // Closed By
        FakePurchaseOrderData.closed_by = FakePurchaseOrderData.is_closed ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Archived At
        FakePurchaseOrderData.archived_at = null;
        // Archived By
        FakePurchaseOrderData.archived_by = null;
        // Sync Token
        FakePurchaseOrderData.sync_token = 1;
        // Suggested Action
        FakePurchaseOrderData.suggested_action = (Math.random() < 0.26) ? (purchaseOrderItemActionsList[_.random(1, (purchaseOrderItemActionsList.length - 1))]) : null;
        // Notifications
        FakePurchaseOrderData.notifications = {};
        // Metadata
        FakePurchaseOrderData.metadata = {};
        // Payment Settings
        FakePurchaseOrderData.payment_settings = {};
        // Settings
        FakePurchaseOrderData.settings = {};
        // Custom Fields
        FakePurchaseOrderData.custom_fields = {};


        return FakePurchaseOrderData;
    }

    // Audit

    /**
     * Creates a pre-created Ledger based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object} PreCreatedLedgerData A precreated ledger
     * @param {Object[]} UsersData The array of generated User records
     * 
     * @returns {Object} A single generated ProHelper Ledger object given the inputs provided.
     */
    _CreateFakeLedgerData(Index, Auth, PermissionsData, CustomizationsData, PreCreatedLedgerData, UsersData) {
        // Initalize local variables
        let FakeLedgerData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const ledgerObjectFieldsMap = CustomizationsData.object_fields.ledgers;
        const ledgerCategoriesObjMap = CustomizationsData.dropdown_sets[ledgerObjectFieldsMap.category.dropdown_set_options.object_identifier][ledgerObjectFieldsMap.category.dropdown_set].dropdowns;
        const ledgerTypesMap = CustomizationsData.object_types.ledgers;
        const ledgerItemActionsObjMap = CustomizationsData.actions.ledgers.item_actions;
        const ledgerItemActionsList = Object.values(ledgerItemActionsObjMap);
        const timeZonesObjMap = CustomizationsData.dropdown_sets[ledgerObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][ledgerObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[ledgerObjectFieldsMap.language.dropdown_set_options.object_identifier][ledgerObjectFieldsMap.language.dropdown_set].dropdowns;


        // Database ID
        FakeLedgerData.id = uniqid(`db_`);
        // Company ID
        FakeLedgerData.company = Auth.company.id;
        // Subject
        FakeLedgerData.subject = PreCreatedLedgerData.subject;
        // Description
        FakeLedgerData.description = PreCreatedLedgerData.description;
        // Category
        FakeLedgerData.category = _.isString(PreCreatedLedgerData.category) ? ledgerCategoriesObjMap[PreCreatedLedgerData.category] : null;
        // Type
        FakeLedgerData.type = _.isString(PreCreatedLedgerData.type) ? ledgerTypesMap[PreCreatedLedgerData.type] : null;
        // Sub Type
        FakeLedgerData.sub_type = _.isString(PreCreatedLedgerData.sub_type) ? ledgerTypesMap[PreCreatedLedgerData.sub_type] : null;
        // Balance
        FakeLedgerData.balance = (Math.random() < 0.45) ? (bigInt(_.random(-10000, 99999)).toString()) : bigInt(0).toString();
        // Balance with Sub Ledgers
        FakeLedgerData.balance_with_sub_ledgers = (Math.random() < 0.45) ? (bigInt(_.random(-10000, 99999)).toString()) : bigInt(0).toString();
        // Accounting Balance
        FakeLedgerData.accounting_balance = (Math.random() < 0.45) ? (bigInt(_.random(-10000, 99999)).toString()) : bigInt(0).toString();
        // Bank Balance
        FakeLedgerData.bank_balance = (Math.random() < 0.45) ? (bigInt(_.random(-10000, 99999)).toString()) : bigInt(0).toString();
        // Ledger ID
        FakeLedgerData.ledger_id = uniqid(`ledger_`);
        // Ledger Number
        FakeLedgerData.ledger_number = Index;
        // Fully Qualified Name
        FakeLedgerData.fully_qualifed_name = PreCreatedLedgerData.subject;
        // Is Monetary
        FakeLedgerData.is_monetary = true;
        // Is Bank Synced
        FakeLedgerData.is_bank_synced = false;
        // Is Integration Synced
        FakeLedgerData.is_integration_synced = false;
        // Is Active
        FakeLedgerData.is_active = true;
        // Is Archived
        FakeLedgerData.is_archived = false;
        // Is Deleted
        FakeLedgerData.is_deleted = false;
        // Is Draft
        FakeLedgerData.is_draft = false;
        // Is Template
        FakeLedgerData.is_template = false;
        // Is Live
        FakeLedgerData.is_live = true;
        // Is Sub Ledger
        FakeLedgerData.is_sub_ledger = PreCreatedLedgerData.is_sub_ledger;
        // Level
        FakeLedgerData.level = PreCreatedLedgerData.is_sub_ledger ? 2 : 1;
        // Parent Ledger
        FakeLedgerData.parent = PreCreatedLedgerData.parent;
        // Popup Alert
        FakeLedgerData.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Created At
        FakeLedgerData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeLedgerData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeLedgerData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeLedgerData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Archived Alert
        FakeLedgerData.archived_alert = null;
        // Archived At
        FakeLedgerData.archived_at = null;
        // Archived By
        FakeLedgerData.archived_by = null;
        // Last Transaction At
        FakeLedgerData.last_transaction_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Currency

        // Language
        FakeLedgerData.language = languagesObjMap.en;
        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeLedgerData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Country

        // Domain

        // Sync Token
        FakeLedgerData.sync_token = 1;
        // Suggested Action
        FakeLedgerData.suggested_action = (Math.random() < 0.26) ? (ledgerItemActionsList[_.random(1, (ledgerItemActionsList.length - 1))]) : null;
        // Notifications
        FakeLedgerData.notifications = {};
        // Metadata
        FakeLedgerData.metadata = {};
        // Payment Processor Metadata
        FakeLedgerData.payment_processor_metadata = {};
        // Accounting Processor Metadata
        FakeLedgerData.accounting_processor_metadata = {};
        // Settings
        FakeLedgerData.settings = {};
        // Custom Fields
        FakeLedgerData.custom_fields = {};

        FakeLedgerData.transactions = this._generateFakeTransactions(FakeLedgerData, UsersData);
        return FakeLedgerData;
    }

    _generateFakeTransactions(ledger, UsersData) {
        const transactions = [];
        const numTransactions = _.random(5, 20); // Generate between 5 and 20 transactions

        let currentBalance = 0;

        for (let i = 0; i < numTransactions; i++) {
            const amount = _.random(-1000, 1000, true); // Generate a random amount between -1000 and 1000
            currentBalance += amount;

            const transaction = {
                id: uniqid(`txn_`),
                company_id: ledger.company,
                ledger_id: ledger.id,
                amount: amount,
                current_balance: currentBalance,
                label: this._generateTransactionLabel(ledger),
                type: this._getTransactionType(amount),
                date: moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate(),
                transaction_id: uniqid(`txn_`),
                created_by: !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null,
            };

            transactions.push(transaction);
        }

        return transactions;
    }

    _generateTransactionLabel(ledger) {
        const labels = [
            'Payment received',
            'Invoice paid',
            'Refund issued',
            'Service fee',
            'Purchase',
            'Salary payment',
            'Utility bill',
            'Office supplies',
            'Equipment rental',
            'Insurance premium'
        ];

        return `${ledger.subject} - ${labels[_.random(0, labels.length - 1)]}`;
    }

    _getTransactionType(amount) {
        return amount >= 0 ? 'credit' : 'debit';
    }

    /**
     * Creates a pre-created Report based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {Object} PreCreatedReportData A pre-created report snippet to base the pre-created record off of
     * @param {Object[]} UsersData The array of generated User records
     * 
     * @returns {Object} A single generated ProHelper Report object given the inputs provided.
     */
    _CreateFakeReportData(Index, Auth, PermissionsData, CustomizationsData, PreCreatedReportData, LedgersData, UsersData) {
        // Initalize local variables
        let FakeReportData = {};

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const reportObjectFieldsMap = CustomizationsData.object_fields.reports;
        const reportCategoriesObjMap = CustomizationsData.dropdown_sets[reportObjectFieldsMap.category.dropdown_set_options.object_identifier][reportObjectFieldsMap.category.dropdown_set].dropdowns;
        const reportTypesMap = CustomizationsData.object_types.reports;
        const reportItemActionsObjMap = CustomizationsData.actions.reports.item_actions;
        const reportItemActionsList = Object.values(reportItemActionsObjMap);
        const timeZonesObjMap = CustomizationsData.dropdown_sets[reportObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][reportObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        const languagesObjMap = CustomizationsData.dropdown_sets[reportObjectFieldsMap.language.dropdown_set_options.object_identifier][reportObjectFieldsMap.language.dropdown_set].dropdowns;


        // Database ID
        FakeReportData.id = uniqid(`db_`);
        // Company ID
        FakeReportData.company = Auth.company.id;
        // Subject
        FakeReportData.subject = PreCreatedReportData.subject;
        // Description
        FakeReportData.description = PreCreatedReportData.description;
        // Category
        FakeReportData.category = _.isString(PreCreatedReportData.category) ? reportCategoriesObjMap[PreCreatedReportData.category] : null;
        // Type
        FakeReportData.type = _.isString(PreCreatedReportData.type) ? reportTypesMap[PreCreatedReportData.type] : null;
        // Sub Type
        FakeReportData.sub_type = _.isString(PreCreatedReportData.sub_type) ? reportTypesMap[PreCreatedReportData.sub_type] : null;

        // Columns

        // Filters

        // Time Period

        // Compare Time Period

        // Object Identifiers

        // Group By

        // Sort By

        // Sort Join

        // Sort Direction

        // Sort Nulls Last

        // Chart

        // Report ID
        FakeReportData.report_id = uniqid(`report_`);
        // Is Active
        FakeReportData.is_active = true;
        // Is Archived
        FakeReportData.is_archived = false;
        // Is Deleted
        FakeReportData.is_deleted = false;
        // Is Draft
        FakeReportData.is_draft = false;
        // Is Template
        FakeReportData.is_template = false;
        // Is Live
        FakeReportData.is_live = true;
        // Created At
        FakeReportData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        FakeReportData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeReportData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        FakeReportData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Used At
        FakeReportData.last_used_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 30), 'days')).toDate();
        // Archived Alert
        FakeReportData.archived_alert = null;
        // Archived At
        FakeReportData.archived_at = null;
        // Archived By
        FakeReportData.archived_by = null;

        // Time Zone
        const guessed_time_zone_identifier = moment.tz.guess().toLowerCase();
        FakeReportData.time_zone = !_.isEmpty(timeZonesObjMap[guessed_time_zone_identifier]) ? timeZonesObjMap[guessed_time_zone_identifier] : null; // Need to get full static dropdown
        // Language
        FakeReportData.language = languagesObjMap.en;
        // Country

        // Sync Token
        FakeReportData.sync_token = 1;
        // Suggested Action
        FakeReportData.suggested_action = (Math.random() < 0.26) ? (reportItemActionsList[_.random(1, (reportItemActionsList.length - 1))]) : null;
        // Notifications
        FakeReportData.notifications = {};
        // Metadata
        FakeReportData.metadata = {};
        // Settings
        FakeReportData.settings = {};
        // Custom Fields
        FakeReportData.custom_fields = {};


        return FakeReportData;
    }


    // Other
    /**
     * Creates a pre-created Line Item for a parent billing record based on the inputs given.
     * 
     * @param {Integer} Index A integer number of the index in creation array
     * @param {Object} Auth A valid ProHelper authentication object
     * @param {Object} PermissionsData A valid ProHelper permissions object
     * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
     * @param {String} ObjectIdentifier The object identifier for the parent record data
     * @param {Object} ParentRecordData The JSON full record for the parent that the line item is connected to
     * @param {Object[]} UsersData The array of generated User records
     * 
     * @returns {Object} A single generated ProHelper Line Item record given the inputs provided.
     */
    _CreateFakeLineItemData(Index, Auth, PermissionsData, CustomizationsData, ObjectIdentifier, ParentRecordData, UsersData, ItemsData, AccountData) {
        // Initalize local variables
        let FakeLineItemData = {};
        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const lineItemsObjectFieldsMap = CustomizationsData.object_fields.line_items;
        const languagesObjMap = CustomizationsData.dropdown_sets[lineItemsObjectFieldsMap.language.dropdown_set_options.object_identifier][lineItemsObjectFieldsMap.language.dropdown_set].dropdowns;
        const lineItemObjectTypesMap = CustomizationsData.object_types.line_items;
        const lineItemsTypesList = Object.values(lineItemObjectTypesMap);

        const currenciesObjMap = CustomizationsData.dropdown_sets[lineItemsObjectFieldsMap.currency.dropdown_set_options.object_identifier][lineItemsObjectFieldsMap.currency.dropdown_set].dropdowns;


        // Database ID
        FakeLineItemData.id = uniqid(`db_`);
        // Company ID
        FakeLineItemData.company = Auth.company.id;
        // Item
        FakeLineItemData.item = _.isArray(ItemsData) ? ItemsData[_.random(0, ItemsData.length - 1)] : null;
        // Description
        FakeLineItemData.description = FakeSecondaryData.lineItemDescriptionList[_.random(0, (FakeSecondaryData.lineItemDescriptionList.length - 1))];
        // Type
        FakeLineItemData.type = (lineItemsTypesList[_.random(0, (lineItemsTypesList.length - 1))]);
        // Quantity 
        FakeLineItemData.quantity = _.random(1, 10);
        // Price 
        FakeLineItemData.price = bigInt(_.random(-10000, 99999)).toString();
        // Total  
        FakeLineItemData.total = FakeLineItemData.price * FakeLineItemData.quantity;

        // Discounts 
        FakeLineItemData.discounts = null;
        // Tax Rates 
        FakeLineItemData.tax_rates = null;
        // Related To  
        FakeLineItemData.related_to = ParentRecordData;
        // Account
        FakeLineItemData.account = AccountData;
        // Is Prorated  
        FakeLineItemData.is_prorated = null;
        // Is Taxable 
        FakeLineItemData.is_taxable = null;
        // Is Discounted
        FakeLineItemData.is_discounted = null;
        // Is Active
        FakeLineItemData.is_active = true;
        // Is Archived
        FakeLineItemData.is_archived = false;
        // Is Deleted
        FakeLineItemData.is_deleted = false;
        // Is Live
        FakeLineItemData.is_live = true;
        // Is Draft
        FakeLineItemData.is_draft = false;
        // Is Template
        FakeLineItemData.is_template = false;
        // Sort Order
        FakeLineItemData.sort_order = null;
        // Line Item Id
        FakeLineItemData.line_item_id = uniqid(`db_`);
        // Currency
        FakeLineItemData.currency = currenciesObjMap.usd;
        // Language
        FakeLineItemData.language = languagesObjMap.en;
        // Domain
        FakeLineItemData.domain = null;
        // Sync Token
        FakeLineItemData.sync_token = 1;
        // Created By
        FakeLineItemData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Created At
        FakeLineItemData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Last Updated By
        FakeLineItemData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeLineItemData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Finalized At
        FakeLineItemData.finalized_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();;

        return FakeLineItemData;
    }

    // Other
    /**
         * Creates a fake Visit based on the inputs given.
         * 
         * @param {Integer} Index A integer number of the index in creation array
         * @param {Object} Auth A valid ProHelper authentication object
         * @param {Object} PermissionsData A valid ProHelper permissions object
         * @param {Object} CustomizationsData The customizations redux object storing all customizations that the user has
         * @param {Object[]} UsersData The array of generated User records
         * @param {Object[]} AccountsData The array of generated Account records
         * 
         * @returns {Object} A single randomly generated ProHelper Visit object given the inputs provided.
         */
    _CreateFakeNoteData(Index, Auth, PermissionsData, CustomizationsData, UsersData, AccountsData) {
        // Initalize local variables
        let FakeNoteData = {};
        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const noteObjectFieldsMap = CustomizationsData.object_fields.notes;
        const noteTypesMap = CustomizationsData.object_types.notes;
        const noteTypesList = Object.values(noteTypesMap);
        const noteStatusesObjMap = CustomizationsData.dropdown_sets[noteObjectFieldsMap.status.dropdown_set_options.object_identifier][noteObjectFieldsMap.status.dropdown_set].dropdowns;
        const noteStatusesList = Object.values(noteStatusesObjMap);
        const noteTagsObjMap = CustomizationsData.dropdown_sets[noteObjectFieldsMap.tags.dropdown_set_options.object_identifier][noteObjectFieldsMap.tags.dropdown_set].dropdowns;
        const noteTagsList = Object.values(noteTagsObjMap);
        const noteItemActionsObjMap = CustomizationsData.actions.notes.item_actions;
        const noteItemActionsList = Object.values(noteItemActionsObjMap);
        // const timeZonesObjMap = CustomizationsData.dropdown_sets[noteObjectFieldsMap.time_zone.dropdown_set_options.object_identifier][noteObjectFieldsMap.time_zone.dropdown_set].dropdowns;
        //const languagesObjMap = CustomizationsData.dropdown_sets[noteObjectFieldsMap.language.dropdown_set_options.object_identifier][noteObjectFieldsMap.language.dropdown_set].dropdowns;
        // Database ID
        FakeNoteData.id = uniqid(`db_`);
        // Company ID
        FakeNoteData.company = Auth.company.id;
        // Title
        FakeNoteData.title = FakeSecondaryData.eventLabelList[_.random(0, (FakeSecondaryData.eventLabelList.length - 1))];
        // Description
        FakeNoteData.body = (Math.random() < 0.65) ? FakeSecondaryData.scheduleDescriptionList[_.random(0, (FakeSecondaryData.scheduleDescriptionList.length - 1))] : null;
        // Type
        FakeNoteData.type = (Math.random() < 0.94) ? (noteTypesList[_.random(0, (noteTypesList.length - 1))]) : null;
        // Sub Type
        FakeNoteData.sub_type = null;//(Math.random() < 0.94) ? (noteTypesList[_.random(0, (noteTypesList.length - 1))]) : null;
        // Status
        FakeNoteData.status = noteStatusesList[_.random(0, (noteStatusesList.length - 1))];
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = noteTagsList[_.random(0, (noteTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            FakeNoteData.tags = Object.values(tagsObjectMapping);
        }
        // Number of Attachments
        FakeNoteData.num_of_attachments = (_.random(1, 8));
        // Related To
        FakeNoteData.related_to = null;
        // Account
        FakeNoteData.account = !_.isEmpty(AccountsData) && (Math.random() < 0.98 ? AccountsData[_.random(0, AccountsData.length - 1)] : null);
        // Owner
        FakeNoteData.owner = !_.isEmpty(UsersData) ? (Math.random() < 0.78) ? UsersData[_.random(0, UsersData.length - 1)] : null : null;
        // Note ID
        FakeNoteData.note_id = uniqid(`note_`);
        // Popup Alert
        FakeNoteData.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;
        // Language
        FakeNoteData.language = null;//languagesObjMap.en;
        // Created By
        FakeNoteData.created_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Created At
        FakeNoteData.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Last Updated By
        FakeNoteData.last_updated_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Last Updated At
        FakeNoteData.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Viewed At
        FakeNoteData.last_viewed_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Viewed By
        FakeNoteData.last_viewed_by = !_.isEmpty(UsersData) ? UsersData[_.random(0, UsersData.length - 1)] : null;
        // Is Customer Visible
        FakeNoteData.is_customer_visible = true;
        // Is Private
        FakeNoteData.is_private = true;
        // Is Active
        FakeNoteData.is_active = true;
        // Is Locked
        FakeNoteData.is_locked = false;
        // Locked Reason
        FakeNoteData.locked_reason = "";
        // Is Live
        FakeNoteData.is_live = true;
        // Is Draft
        FakeNoteData.is_draft = false;
        // Is Template
        FakeNoteData.is_template = false;
        // Is Archived
        FakeNoteData.is_archived = false;
        // Archived Alert
        FakeNoteData.archived_alert = null;
        // Archived At
        FakeNoteData.archived_at = null;
        // Archived By
        FakeNoteData.archived_by = null;
        // Is Deleted
        FakeNoteData.is_deleted = false;
        // Deleted At
        FakeNoteData.deleted_at = null;
        // Sync Token
        FakeNoteData.sync_token = 1;
        // Suggested Action
        FakeNoteData.suggested_action = (Math.random() < 0.26) ? (noteItemActionsList[_.random(1, (noteItemActionsList.length - 1))]) : null;
        // Metadata
        FakeNoteData.metadata = {};
        // Settings
        FakeNoteData.settings = {};
        // Custom Fields
        FakeNoteData.custom_fields = {};
        return FakeNoteData;
    }
}
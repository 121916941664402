// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/customizations/ViewsActionTypes';

const ViewsReducer = (state = { app: {}, object: {} }, action) => {
    // Create a copy of the current redux state
    let newViewsState = {
        app: { ...state.app },
        object: { ...state.object }
    };
    switch (action.type) {
        case actionTypes.SET_ALL_VIEWS:


            if (_.isArray(action.payload.views)) {
                console.log("action ==> ", action);

                // Process all views and handle duplicates
                action.payload.views.forEach((view) => {
                    if (_.isString(view.object_identifier)) {
                        // Initialize object container if needed
                        if (!_.isObject(newViewsState.object[view.object_identifier])) {
                            newViewsState.object[view.object_identifier] = {};
                        }

                        // Check for existing view with same identifier
                        const existingViewEntry = Object.entries(newViewsState.object[view.object_identifier])
                            .find(([_, existingView]) => existingView.identifier === view.identifier);

                        if (existingViewEntry) {
                            // Update existing view if it's newer
                            const [existingId, existingView] = existingViewEntry;
                            const existingDate = new Date(existingView.last_updated_at);
                            const newDate = new Date(view.last_updated_at);

                            if (newDate > existingDate) {
                                // Remove old entry
                                delete newViewsState.object[view.object_identifier][existingId];
                                // Add new entry
                                newViewsState.object[view.object_identifier][view.id] = {
                                    ...view,
                                    last_updated_at: new Date()
                                };
                            }
                        } else {
                            // Add new view
                            newViewsState.object[view.object_identifier][view.id] = {
                                ...view,
                                last_updated_at: new Date()
                            };
                        }
                    } else {
                        // Handle app views
                        const viewCategory = view.category || 'default';
                        if (!_.isObject(newViewsState.app[viewCategory])) {
                            newViewsState.app[viewCategory] = {
                                default: {}
                            };
                        }
                        newViewsState.app[viewCategory].default[view.identifier] = {
                            ...view,
                            last_updated_at: new Date()
                        };
                    }
                });
            }
            console.log("newViewsState: ", newViewsState);
            return newViewsState;

        case actionTypes.UPDATE_VIEW:
            newViewsState = {
                app: { ...state.app },
                object: { ...state.object }
            };

            if (_.isObject(action.payload.views)) {
                const view = action.payload.views;
                if (_.isString(view.object_identifier)) {
                    if (!_.isObject(newViewsState.object[view.object_identifier])) {
                        newViewsState.object[view.object_identifier] = {};
                    }

                    // Handle three cases:
                    // 1. New view being added
                    // 2. Existing view being updated (same id)
                    // 3. View being edited and saved as update to another view (different id, same identifier)
                    Object.entries(newViewsState.object[view.object_identifier])
                        .forEach(([id, existingView]) => {
                            if (id === view.id) {
                                // Case 2: Direct update to existing view
                                delete newViewsState.object[existingView.object_identifier][id];
                                newViewsState.object[view.object_identifier][id] = {
                                    ...view
                                };
                            } 
                        });

                    // Case 1: If no existing view was updated, add as new
                    const viewExists = Object.keys(newViewsState.object[view.object_identifier])
                        .some(id => id === view.id ||
                            newViewsState.object[view.object_identifier][id].identifier === view.identifier);

                    if (!viewExists) {
                        newViewsState.object[view.object_identifier][view.id] = {
                            ...view
                        };
                    }
                }
            }
            return newViewsState;

        case actionTypes.REMOVE_ALL_VIEWS:
            return {
                app: {},
                object: {}
            };

        default:
            return state;
    }
};

export default ViewsReducer;


export const SET_ALL_DIALOGS_DATA = 'SET_ALL_DIALOGS_DATA';
export const SET_DIALOG_DATA = 'SET_DIALOG_DATA';
export const SET_EDIT_RECORD_DIALOG_DATA = 'SET_EDIT_RECORD_DIALOG_DATA';
export const REMOVE_EDIT_RECORD_DIALOG_DATA = 'REMOVE_EDIT_RECORD_DIALOG_DATA';
export const SET_EDIT_FIELD_DIALOG_DATA = 'SET_EDIT_FIELD_DIALOG_DATA';
export const REMOVE_EDIT_FIELD_DIALOG_DATA = 'REMOVE_EDIT_FIELD_DIALOG_DATA';
export const SET_ARCHIVE_DIALOG_DATA = 'SET_ARCHIVE_DIALOG_DATA';
export const REMOVE_ARCHIVE_DIALOG_DATA = 'REMOVE_ARCHIVE_DIALOG_DATA';
export const SET_NEW_RECORD_DIALOG_DATA = 'SET_NEW_RECORD_DIALOG_DATA';
export const REMOVE_NEW_RECORD_DIALOG_DATA = 'REMOVE_NEW_RECORD_DIALOG_DATA';
export const SET_CUSTOMIZE_FIELD_DIALOG_DATA = 'SET_CUSTOMIZE_FIELD_DIALOG_DATA';
export const REMOVE_CUSTOMIZE_FIELD_DIALOG_DATA = 'REMOVE_CUSTOMIZE_FIELD_DIALOG_DATA';
export const REMOVE_FORM_DIALOG_DATA = 'REMOVE_FORM_DIALOG_DATA';
export const SET_FORM_DIALOG_DATA = 'SET_FORM_DIALOG_DATA';
// This constants file is a representation of how data will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../types/FieldTypes';

const ObjectsData = [
    // Accounts
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Account",
        label_plural: "Accounts",
        starts_with_vowel: true,
        identifier: 'accounts',
        description: "Accounts store all the information about customers, vendors, and other people or companies.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "sitemap",
        route: '/accounts',
        display_field: 'name',
        public_id_field: 'account_id',
        public_id_prefix: 'acct',
        order: 1,
        group: {
            label: "Clients",
            identifier: "clients",
            order: 3
        },
        sub_group: {
            label: "Accounts",
            identifier: "accounts",
            order: 1
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Name',
                identifier: 'accounts.name',
                field_type: FieldTypes.NAME,
                is_required: true
            },
            {
                label: 'Type',
                identifier: 'accounts.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            },
            {
                label: 'Balance',
                identifier: 'accounts.balance',
                field_type: FieldTypes.BALANCE,
                is_required: true
            },
            {
                label: 'Primary Contact',
                identifier: 'accounts.primary_contact',
                field_type: FieldTypes.LOOKUP_RELATIONSHIP,
                is_required: false
            },
            {
                label: 'Primary Property',
                identifier: 'accounts.primary_property',
                field_type: FieldTypes.LOOKUP_RELATIONSHIP,
                is_required: false
            },
        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Contacts
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Contact",
        label_plural: "Contacts",
        starts_with_vowel: false,
        identifier: 'contacts',
        description: "",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "address-book",
        route: '/contacts',
        display_field: 'name',
        public_id_field: 'contact_id',
        public_id_prefix: 'cont',
        order: 2,
        group: {
            label: "Clients",
            identifier: "clients",
            order: 3
        },
        sub_group: {
            label: "Contacts",
            identifier: "contacts",
            order: 2
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Name',
                identifier: 'accounts.name',
                field_type: FieldTypes.NAME,
                is_required: true
            },
            {
                label: 'Phone',
                identifier: 'contacts.phone',
                field_type: FieldTypes.PHONE,
                is_required: false
            },
            {
                label: 'Email',
                identifier: 'contacts.email',
                field_type: FieldTypes.EMAIL,
                is_required: false
            },
        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Properties
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Property",
        label_plural: "Properties",
        starts_with_vowel: false,
        identifier: 'properties',
        description: "",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "house-building",
        route: '/properties',
        display_field: 'address',
        public_id_field: 'property_id',
        public_id_prefix: 'prop',
        order: 3,
        group: {
            label: "Clients",
            identifier: "clients",
            order: 3
        },
        sub_group: {
            label: "Properties",
            identifier: "properties",
            order: 3
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Address',
                identifier: 'properties.address',
                field_type: FieldTypes.ADDRESS,
                is_required: false
            },
            {
                label: 'Type',
                identifier: 'properties.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            },
            {
                label: 'Access Notes',
                identifier: 'properties.access_notes',
                field_type: FieldTypes.LONG_TEXT,
                is_required: false
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Users
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "User",
        label_plural: "Users",
        starts_with_vowel: false,
        identifier: 'users',
        description: "",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "user",
        route: '/users',
        display_field: 'name',
        public_id_field: 'user_id',
        public_id_prefix: 'user',
        order: 4,
        group: {
            label: "Team",
            identifier: "team",
            order: 5
        },
        sub_group: {
            label: "Users",
            identifier: "users",
            order: 1
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Name',
                identifier: 'users.name',
                field_type: FieldTypes.NAME,
                is_required: true
            },
            {
                label: 'Email',
                identifier: 'users.email',
                field_type: FieldTypes.EMAIL,
                is_required: true
            },
            {
                label: 'Phone',
                identifier: 'users.phone',
                field_type: FieldTypes.PHONE,
                is_required: false
            },
            {
                label: 'Type',
                identifier: 'users.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Roles
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Role",
        label_plural: "Roles",
        starts_with_vowel: false,
        identifier: 'roles',
        description: "",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "id-card",
        route: '/roles',
        display_field: 'label',
        public_id_field: 'role_id',
        public_id_prefix: 'role',
        order: 5,
        group: {
            label: "Security",
            identifier: "security",
            order: 6
        },
        sub_group: {
            label: "Roles",
            identifier: "roles",
            order: 1
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Label',
                identifier: 'roles.label',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },
            {
                label: 'Description',
                identifier: 'roles.description',
                field_type: FieldTypes.LONG_TEXT,
                is_required: false
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Permission Sets
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Permission Set",
        label_plural: "Permission Sets",
        starts_with_vowel: false,
        identifier: 'permission_sets',
        description: "",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "user-lock",
        route: '/permission_sets',
        display_field: 'label',
        public_id_field: 'permission_set_id',
        public_id_prefix: 'pmset',
        order: 6,
        group: {
            label: "Security",
            identifier: "security",
            order: 6
        },
        sub_group: {
            label: "Permission Sets",
            identifier: "permission_sets",
            order: 2
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Label',
                identifier: 'permission_sets.label',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },
            {
                label: 'Description',
                identifier: 'permission_sets.description',
                field_type: FieldTypes.LONG_TEXT,
                is_required: false
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Requests
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Request",
        label_plural: "Requests",
        starts_with_vowel: false,
        identifier: 'requests',
        description: "An incoming call/email/text for potential future work.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "inbox",
        route: '/requests',
        display_field: 'subject',
        public_id_field: 'request_id',
        public_id_prefix: 'req',
        order: 7,
        group: {
            label: "Work",
            identifier: "work",
            order: 1
        },
        sub_group: {
            label: "Requests",
            identifier: "requests",
            order: 1
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'requests.subject',
                field_type: FieldTypes.LONG_TEXT,
                is_required: true
            },
            {
                label: 'Type',
                identifier: 'requests.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            },
            {
                label: 'Account',
                identifier: 'requests.account',
                field_type: FieldTypes.LOOKUP_RELATIONSHIP,
                is_required: false
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Estimates
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Estimate",
        label_plural: "Estimates",
        starts_with_vowel: false,
        identifier: 'estimates',
        description: "Estimates are a potential jobs that can be sent to the client for review and approval beforehand.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "envelope-open-dollar",
        route: '/estimates',
        display_field: 'subject',
        public_id_field: 'estimate_id',
        public_id_prefix: 'estimate',
        order: 11,
        group: {
            label: "Work",
            identifier: "work",
            order: 1
        },
        sub_group: {
            label: "Estimates",
            identifier: "estimates",
            order: 2
        },
        viewing_options: {
            required_containers: ["header", "details", "line_items"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details", "line_items"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'estimates.subject',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Visits
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Visit",
        label_plural: "Visits",
        starts_with_vowel: false,
        identifier: 'visits',
        description: "Visits are schedulable events on a calendar, for Work related records.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "person-dolly",
        route: '/visits',
        display_field: 'subject',
        public_id_field: 'visit_id',
        public_id_prefix: 'visit',
        order: 11,
        group: {
            label: "Work",
            identifier: "work",
            order: 1
        },
        sub_group: {
            label: "Visits",
            identifier: "visits",
            order: 3
        },
        viewing_options: {
            required_containers: ["header", "details", "schedule_item"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details", "schedule_item"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Title',
                identifier: 'visits.title',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tasks
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Task",
        label_plural: "Tasks",
        starts_with_vowel: false,
        identifier: 'tasks',
        description: "Tasks are small work items to be handled by Team members.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "list-check",
        route: '/tasks',
        display_field: 'subject',
        public_id_field: 'task_id',
        public_id_prefix: 'task',
        order: 8,
        group: {
            label: "Work",
            identifier: "work",
            order: 1
        },
        sub_group: {
            label: "Tasks",
            identifier: "tasks",
            order: 4
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'tasks.subject',
                field_type: FieldTypes.LONG_TEXT,
                is_required: true
            },
            {
                label: 'Type',
                identifier: 'tasks.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            },
            {
                label: 'Description',
                identifier: 'tasks.description',
                field_type: FieldTypes.LONG_TEXT,
                is_required: false
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Jobs
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Job",
        label_plural: "Jobs",
        starts_with_vowel: false,
        identifier: 'jobs',
        description: "Jobs are one-time or recurring work items that contains products or services as line items set at the transaction price.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "briefcase",
        route: '/jobs',
        display_field: 'subject',
        public_id_field: 'job_id',
        public_id_prefix: 'job',
        order: 8,
        group: {
            label: "Work",
            identifier: "work",
            order: 1
        },
        sub_group: {
            label: "Jobs",
            identifier: "jobs",
            order: 5
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'jobs.subject',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },
            {
                label: 'Type',
                identifier: 'jobs.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            },
            {
                label: 'Account',
                identifier: 'jobs.account',
                field_type: FieldTypes.LOOKUP_RELATIONSHIP,
                is_required: false
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Routes
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Route",
        label_plural: "Routes",
        starts_with_vowel: false,
        identifier: 'routes',
        description: "Routes are a collection of one-time or recurring jobs that can be assigned to a driver.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "route",
        route: '/routes',
        display_field: 'title',
        public_id_field: 'route_id',
        public_id_prefix: 'route',
        order: 9,
        group: {
            label: "Work",
            identifier: "work",
            order: 1
        },
        sub_group: {
            label: "Routes",
            identifier: "routes",
            order: 6
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Title',
                identifier: 'routes.title',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },
            {
                label: 'Type',
                identifier: 'routes.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            }

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Invoices
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Invoice",
        label_plural: "Invoices",
        starts_with_vowel: false,
        identifier: 'invoices',
        description: "Invoices are a a list of goods sent or services provided, with a statement of the sum due for these; a bill to the customer.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "file-invoice-dollar",
        route: '/invoices',
        display_field: 'subject',
        public_id_field: 'invoice_id',
        public_id_prefix: 'invoice',
        order: 11,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Invoices",
            identifier: "invoices",
            order: 3
        },
        viewing_options: {
            required_containers: ["header", "details", "line_items"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details", "line_items"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'invoices.subject',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subscriptions
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Subscription",
        label_plural: "Subscriptions",
        starts_with_vowel: false,
        identifier: 'subscriptions',
        description: "Subscriptions create recurring invoices based on a schedule that are sent to Customers.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "money-bill-transfer",
        route: '/subscriptions',
        display_field: 'subject',
        public_id_field: 'subscription_id',
        public_id_prefix: 'subscription',
        order: 11,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Subscriptions",
            identifier: "subscriptions",
            order: 4
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'subscriptions.subject',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Payment
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Payment",
        label_plural: "Payments",
        starts_with_vowel: false,
        identifier: 'payments',
        description: "Payments are records of collected money transactions from clients relating to previous invoices or their balance.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "money-check-dollar",
        route: '/payments',
        display_field: 'subject',
        public_id_field: 'payment_id',
        public_id_prefix: 'payment',
        order: 11,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Payments",
            identifier: "payments",
            order: 5
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'payments.subject',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Items
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Item",
        label_plural: "Items",
        starts_with_vowel: false,
        identifier: 'items',
        description: "Products & Services that the business offers clients. Prices are independant of Items.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "store",
        route: '/items',
        display_field: 'label',
        public_id_field: 'item_id',
        public_id_prefix: 'item',
        order: 12,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Items",
            identifier: "items",
            order: 6
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Label',
                identifier: 'items.label',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Ledgers
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Ledger",
        label_plural: "Ledgers",
        starts_with_vowel: false,
        identifier: 'ledgers',
        description: "A Ledger is a record-keeping tool that tracks transactions for a specific reason, to provide a clear overview of financial activities and balances.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "memo-pad",
        route: '/ledgers',
        display_field: 'subject',
        public_id_field: 'ledger_id',
        public_id_prefix: 'ledger',
        order: 13,
        group: {
            label: "Audit",
            identifier: "audit",
            order: 6
        },
        sub_group: {
            label: "Ledgers",
            identifier: "ledgers",
            order: 2
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'ledgers.subject',
                field_type: FieldTypes.LONG_TEXT,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Reports
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Report",
        label_plural: "Reports",
        starts_with_vowel: false,
        identifier: 'reports',
        description: "A report is a document or visual that conveys information, findings, analysis, or summaries of events to provide insights, recommendations, or updates on a particular subject or situation.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "chart-pie",
        route: '/reports',
        display_field: 'subject',
        public_id_field: 'report_id',
        public_id_prefix: 'report',
        order: 14,
        group: {
            label: "Audit",
            identifier: "audit",
            order: 6
        },
        sub_group: {
            label: "Reports",
            identifier: "reports",
            order: 3
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'reports.subject',
                field_type: FieldTypes.LONG_TEXT,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Notes
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Note",
        label_plural: "Notes",
        starts_with_vowel: false,
        identifier: 'notes',
        description: "A note object is a digital record where users can jot down thoughts, ideas, or information, easily editable and savable for future reference.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "note-sticky",
        route: '/notes',
        display_field: 'title',
        public_id_field: 'note_id',
        public_id_prefix: 'note',
        order: 15,
        group: {
            label: "Other",
            identifier: "other",
            order: 7
        },
        sub_group: {
            label: "Notes",
            identifier: "notes",
            order: 1
        },
        viewing_options: {
            required_containers: ["header"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Title',
                identifier: 'notes.title',
                field_type: FieldTypes.LONG_TEXT,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Line Items
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Line Item",
        label_plural: "Line Items",
        starts_with_vowel: false,
        identifier: 'line_items',
        description: "A Line Item is a single item that is in a line on a billing record and can have an item, quantity, and price.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "file-lines",
        route: '/line_items',
        display_field: 'title',
        public_id_field: 'line_items_id',
        public_id_prefix: 'line_item',
        order: 16,
        group: {
            label: "Other",
            identifier: "other",
            order: 7
        },
        sub_group: {
            label: "Line Items",
            identifier: "line_items",
            order: 2
        },
        viewing_options: {
            required_containers: ["line_items"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["line_items"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Label',
                identifier: 'line_items.label',
                field_type: FieldTypes.LONG_TEXT,
                is_required: true
            },
            {
                label: 'Quantity',
                identifier: 'line_items.quantity',
                field_type: FieldTypes.DECIMAL,
                is_required: true
            },

        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Form Data
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Form Data",
        label_plural: "Forms Data",
        starts_with_vowel: false,
        identifier: 'form_data',
        description: "A form data object allows users to collect, organize, and manage structured data through customizable fields and templates.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "faFormTextarea", // or "clipboard-list" or "file-text" depending on your icon library
        route: '/form_data',
        display_field: 'display_text',
        public_id_field: 'form_data_id',
        public_id_prefix: 'form_data',
        order: 16,
        group: {
            label: "Data Collection",
            identifier: "data_collection",
            order: 4
        },
        sub_group: {
            label: "Forms",
            identifier: "forms",
            order: 1
        },
        viewing_options: {
            required_containers: ["header"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Display Text',
                identifier: 'forms.display_text',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },
        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Vehicles
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Vehicle",
        label_plural: "Vehicles",
        starts_with_vowel: false,
        identifier: 'vehicles',
        description: "A Vehicle is an asset used by the company that are drivable to work items and can store items onboard.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "car",
        route: '/vehicles',
        display_field: 'title',
        public_id_field: 'vehicle_id',
        public_id_prefix: 'veh',
        order: 17,
        group: {
            label: "Team",
            identifier: "team",
            order: 5
        },
        sub_group: {
            label: "Vehicles",
            identifier: "vehicles",
            order: 2
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Title',
                identifier: 'vehicles.title',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },
            {
                label: 'Type',
                identifier: 'vehicles.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            },
            {
                label: 'Fleet Number',
                identifier: 'vehicles.fleet_number',
                field_type: FieldTypes.INTEGER,
                is_required: false
            },
            {
                label: 'License Plate',
                identifier: 'vehicles.license_plate',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: false
            },
            
        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Purchase Orders
    {
        public_id: uniqid('object_'),
        parent_id: null,
        sync_token: 1,
        label_singular: "Purchase Order",
        label_plural: "Purchase",
        starts_with_vowel: false,
        identifier: 'purchase_orders',
        description: "A Purchase Order is statement of items that is purchased by the company from vendors.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        icon: "receipt",
        route: '/purchase_orders',
        display_field: 'subject',
        public_id_field: 'purchase_order_id',
        public_id_prefix: 'po',
        order: 18,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Purchase Orders",
            identifier: "purchase_orders",
            order: 4
        },
        viewing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        editing_options: {
            required_containers: ["header", "details"],
            are_required_containers_locked: true
        },
        important_fields: [
            {
                label: 'Subject',
                identifier: 'purchase_orders.subject',
                field_type: FieldTypes.SHORT_TEXT,
                is_required: true
            },
            {
                label: 'Type',
                identifier: 'purchase_orders.type',
                field_type: FieldTypes.TYPE_DROPDOWN,
                is_required: false
            },
            
        ],
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    }
];

export default ObjectsData;
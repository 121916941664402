
// Import Modules
// import uniqid from 'uniqid';
// import { identifier } from 'safe-identifier';
// import * as ROUTES from '../../../../navigation/routes';
// import * as ScreenTypes from '../../../../types/ScreenTypes';
// import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';


const ScheduleViewData = [
    {
        // Visits
        name: "All Visits",
        description: "Schedule View description", 
        identifier: 'all_visits',
        object_identifier: 'visits',
        display_field: 'visits.subject',
        parents: ['schedule.default.component_1'],
        type: "schedule_view",
        sub_type: "week",
        group_by: {
            field: "assigned_to",
            data: []
        },
        filters: [
            {
                fields: ["visits.is_active"],
                field_types: ["checkbox"],
                operator: "equal",
                values: [
                    {
                        type: "value",
                        label: 'True',
                        input: true,
                        data: true,
                        data_type: 'boolean',
                        status: 'valid'
                    }
                ]
            }
        ],
        sort_field: "visits.start_date",
        sort_by: ["visits.start_date"],
        sort_method: "chronological",
        sort_direction: "ASC",
        sort_nulls_last: true,
        sort_join: [],
        search_value: '',
        read_access: "all_users",
        edit_access: "all_users",
        filter_by_viewer: false, 
        item_actions: [
            "visits.edit",
            "visits.mark_completed",
            "visits.mark_closed",
            "visits.convert_to_invoice",
            "visits.archive",
            "visits.delete"
        ],
        fields: [
            {
                label: 'Subject',
                identifier: 'visits.subject',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.subject"],
                display_format: null
            },
            {
                label: 'Description',
                identifier: 'visits.description',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.description"],
                display_format: null
            },
            {
                label: 'Type',
                identifier: 'visits.type',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.type", "object_types.label"],
                display_format: null
            },
            {
                label: 'Status',
                identifier: 'visits.status',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.status", "dropdowns.label"],
                display_format: null
            },
            {
                label: 'Start Date',
                identifier: 'visits.start_date',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.start"],
                display_format: "MMMM Do YYYY, h:mm a"
            },
            {
                label: 'End Date',
                identifier: 'visits.end_date',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.end"],
                display_format: "MMMM Do YYYY, h:mm a"
            },
            {
                label: 'Duration',
                identifier: 'visits.duration',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.duration"],
                display_format: null
            },
            {
                label: 'Assigned To',
                identifier: 'visits.assigned_to',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.assigned_to", "users.name", "name.display"],
                display_format: null
            },
            {
                label: 'Address',
                identifier: 'visits.address',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.address", "properties.address", "address.display"],
                display_format: null
            },
            {
                label: 'Account',
                identifier: 'visits.account',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.account", "accounts.name", "name.display"],
                display_format: null
            },
            {
                label: 'All Day',
                identifier: 'visits.is_all_day',
                is_read_only: false,
                is_visible: true,
                display_fields: ["visits.is_all_day"],
                display_format: null
            }
        ]
    }
];

export default ScheduleViewData;